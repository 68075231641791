import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { sofortStatus } from '@/constants/base/onboarding/kyc';
import { parseQuery } from '@shared/utils/urlUtils.mjs';

export default class SofortController {
  #scope;
  #site;
  #lang;
  #services;
  #router;
  #callback;
  #modelInfo;
  #status;
  #url;
  #queries;
  #result;
  #tryCount;

  constructor(scope, { services, router, site, lang, callback, modelInfo }) {
    this.#scope = scope;
    this.#modelInfo = modelInfo;
    this.#services = services;
    this.#router = router;
    this.#site = site;
    this.#lang = lang;
    this.#callback = callback;
    this.#restart();
  }

  /**
   * onboarding에서 활용하는 token을 3rd party 진행 시 자동 한시간 연장 되도록 요청
   * @returns {Promise<ResponseAuthInfo>}
   */
  async #postAutoRenewToken() {
    const r = await this.#services.token.autoRenewToken();
    r?.error && this.#errorHandler(r);
    return r;
  }

  /**
   * Sofort URL 조회
   * @returns {Promise<*>}
   */
  async #getUrl() {
    const r = await this.#services.kyc.getSofortUrl({ RequestHost: location.host });
    r?.error && this.#errorHandler(r);
    return r;
  }
  /**
   * Sofort 결과 업데이트
   * @param QueryString
   * @returns {Promise<*>}
   */
  async #verify(QueryString) {
    const r = await this.#services.kyc.verifySofort({ QueryString });
    r?.error && this.#errorHandler(r);
    return r;
  }
  /**
   * error 발생 시 에러 코드 별 처리 분기
   * @param {object} r - error 정보
   */
  #errorHandler(r) {
    const { code, desc, key, CustomerErrorParameters, errorTemplate } = r;
    const replace = { path: 'VerificationFailed', param: {desc, errorTemplate}, query: { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined }};
    switch (key) {
      case apiErrorCode.FAILED_SELF_EXCLUSION_CHECK:
      case apiErrorCode.OASIS_BAN_EXIST:
        this.#status = sofortStatus.OasisFailed;
        if(replace) this.#router.replace({ name: replace.path, params: replace.param, query: replace.query});
        break;
      case apiErrorCode.KYC_UPLOAD_REQUIRED:
        this.#status = sofortStatus.PepFailed;
        if(replace) this.#router.replace({ name: replace.path, params: replace.param, query: replace.query});
        break;
      default:
        this.#status = sofortStatus.Failed;
        break;
    }
    this.#callbackInfo();
  }

  /**
   * 진행중인 sofort 현황 정보 반환
   * @returns {{lang, status, url}}
   */
  #getInfo() {
    return { lang: this.#lang, status: this.#status, url: this.#url, queries: this.#queries, result: this.#result, tryCount: this.#tryCount };
  }
  /**
   * sofort 상태 값에 따른 화면 전환 처리
   * @param step
   */
  async #callbackStatusInfo(status) {
    console.log('%csofort controller #callbackStatusInfo', 'color:yellow', status);
    const { agecheck_result, TryCount } = parseQuery(this.#queries);
    if (status !== this.#status) this.#status = status;

    let r;
    switch (status) {
      case sofortStatus.Ready:
      case sofortStatus.UrlFailed:
        r = await this.#getUrl();
        if (r?.error) return;
        this.#status = (!r.Url || r?.error) ? sofortStatus.UrlFailed : sofortStatus.Processing;
        this.#url = r.Url;
        break;
      case sofortStatus.Finished:
        r = await this.#verify({ QueryString: this.#queries});
        if (r?.error) return;

        this.#result = agecheck_result;
        this.#tryCount = TryCount;

        switch (agecheck_result) {
          case 'user_abort':
            this.#status = sofortStatus.UserAbort;
            break;
          case 'invalid':
            this.#status = sofortStatus.Invalid;
            break;
          case 'valid':
            break;
          default:
            this.#status = sofortStatus.Failed;
            break;
        }
        break;
      case sofortStatus.OasisFailed: // Oasis Error
      case sofortStatus.PepFailed: // PEP Match Error
      case sofortStatus.Failed: // Other Error
      case sofortStatus.Processing:
      default:
        break;
    }

    this.#callbackInfo();
  }
  /**
   * sofort 현재 정보를 기준으로 화면 전환 처리
   */
  #callbackInfo() {
    this.#callback(this.#getInfo());
  }
  /**
   * 초기화
   * @returns {Promise<void>}
   */
  async #initialize() {
    this.#status = null;
    this.#url = null;
    this.#queries = null;
    this.#result = null;
    this.#tryCount = null;
  }
  /**
   * sofort 단계 초기화
   */
  async #restart() {
    await this.#initialize();
  }
  /**
   * 화면에서 버튼이나 특정 이벤트 시 Status 갱신
   * @param {string} status - 전환 step
   */
  async updateStatus(status) {
    if (!status) return;
    await this.#callbackStatusInfo(status);
  }
  /**
   * querystring 정보 저장
   * @param queries
   * @returns {Promise<void>}
   */
  async setQueriesInfo(queries) {
    this.#queries = location.search;
    await this.#callbackStatusInfo(sofortStatus.Finished);
  }
  /**
   * 재시도
   */
  async tryAgain() {
    await this.updateStatus(sofortStatus.UrlFailed);
  }

  async redirectSofort() {
    if (typeof window === 'undefined') return;
    await this.#postAutoRenewToken();
    location.href = this.#url;
  }
}