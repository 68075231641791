import { siteIds } from '@/constants/base/siteMap.mjs';
import axios from 'axios';
import { appendQuery } from '@shared/utils/urlUtils.mjs';

export default class BaseService {
  #services;
  #store;
  #countrySignupList;
  #countryOnboardingList;
  #stateList;
  #userLocation;
  #gpToken;
  #proxyNpPublicApi;
  #proxyNpUserApi;

  constructor(services) {
    this.#services = services;
    this.#store = services.store;
    this.#proxyNpPublicApi = services.proxyNpPublicApi;
    this.#proxyNpUserApi = services.proxyNpUserApi;
  }

  async getUserCountry() {
    if (!this.#userLocation) {
      this.#userLocation = await this.#proxyNpPublicApi.proxyGet('/currentcountry');
      if (this.#userLocation.CountryCode === 'XX' && this.#store.state.user.userInfo) {
        const { Country, CountryName } = this.#store.state.user.userInfo;
        this.#userLocation.CountryCode = Country;
        this.#userLocation.CountryName = CountryName;
      }
    }
    return this.#userLocation;
  }

  /**
   * @description GET 회원가입 가능 국가
   * @id GetSignupCountriesRequest
   * @param {string} SiteId
   * @return {Promise<SupportedCountries>} GetSupportedCountriesResponse
   * */
  async getCountryList(SiteId) {
    try {
      if (!this.#countrySignupList) this.#countrySignupList = (await this.#proxyNpPublicApi.proxyGet('/countries/signup', { SiteId })).List;
      return this.#countrySignupList;
    } catch (e) {
      console.log('error : getCountryList', e);
      throw e;
    }
  }

  /**
   * @id GetStateListByCountryCodeRequest
   * @param {string} SiteId
   * @param {string} CountryCode
   * @return {Promise<SupportedStates>}
   * */
  async getStateList({ CountryCode = this.#services.store.state.user.userInfo.Country, SiteId }) {
    try {
      if (!this.#stateList) this.#stateList = (await this.#proxyNpPublicApi.proxyGet('/country/statelistbycountrycode', { SiteId, CountryCode })).StateList;
      return this.#stateList;
    } catch (e) {
      console.log('error : getStateList', e);
      throw e;
    }
  }

  /**
   * @description 선택한 국가가 가입 가능한지 체크
   * @id CheckAvailableLocationRequest
   * @param {string} CountryCode
   * @return {boolean} IsAllowed
   */
  getAvailableLocation(CountryCode) {
    try {
      return this.#proxyNpPublicApi.proxyGet('/CheckAvailableLocationRequest', { CountryCode });
    } catch (e) {
      console.log('error : getAvailableLocation');
      throw e;
    }
  }

  /**
   * @description 230개국에 관한 CountryCode, CountryName, PhoneCountryCode 가져오는 함수 (Sign-up 과정에서 호출이 불필요)
   * @id GetSupportedPhoneCodeCountriesRequest
   * @return {SupportedPhoneCodeCountries} GetSupportedPhoneCodeCountriesResponse
   */
  async getMobileCountries() {
    try {
      if (!this.#countryOnboardingList) this.#countryOnboardingList = await this.#proxyNpPublicApi.proxyGet(`/phonecode/countries`);
      return this.#countryOnboardingList;
    } catch (e) {
      console.log('error : getMobileCountries');
      throw e;
    }
  }

  /**
   * @description GET 현재 국가코드
   * @id GetCurrentCountryCodeRequest
   * @return {string} CountryCode, {string} CountryName - GetCurrentCountryCodeResponse
   * @refer 없으면(local 인 경우) "XX"
   */
  async getCurrentCountryCode() {
    try {
      return this.#proxyNpPublicApi.proxyGet('/GetCurrentCountryCodeRequest');
    } catch (e) {
      console.log('error : getCurrentCountryCode');
      throw e;
    }
  }

  /**
   * @description 용도 불명
   * @id GetDownloadUrlRequest
   * @param {number} SiteId
   * @return  {Promise<DownloadUrl>} GetDownloadUrlResponse
   */
  getDownloadUrl(SiteId) {
    try {
      return this.#proxyNpPublicApi.proxyGet('/client/url', { SiteId });
    } catch (e) {
      console.log('error : getDownloadUrl');
      throw e;
    }
  }

  /**
   * @description 3rd party - Google reCaptcha
   * @id GetGoogleReCaptchaSiteKeyRequest
   * @return {string} SiteKey - GetGoogleReCaptchaSiteKeyResponse
   */
  getGoogleReCaptcha() {
    try {
      return this.#proxyNpPublicApi.proxyGet('/GetGoogleReCaptchaSiteKeyRequest');
    } catch (e) {
      console.log('error : getGoogleReCaptcha');
      throw e;
    }
  }

  /**
   * @description 3rd party - GeeTestCaptcha (Use EVPuke, GGPuke)
   * @id GetGeeTestCaptchaInitialRequest
   * @return {Promise<GeeTestInitialData>} GetGeeTestCaptchaInitialResponse
   */
  getGeeTestCaptcha() {
    try {
      return this.#proxyNpPublicApi.proxyGet('/GetGeeTestCaptchaInitialRequest');
    } catch (e) {
      console.log('error : getGeeTestCaptcha');
      throw e;
    }
  }

  /**
   * @id GetResponsibleGamingConfigReadyRequest
   * */
  getGamingConfigReady() {
    try {
      return this.#proxyNpUserApi.proxyGet('/player/rg/configuration/ready', null, { silent: true });
    } catch (e) {
      console.log('error : getGamingConfigReady');
      throw e;
    }
  }

  getAllCountries() {
    try {
      return this.#proxyNpPublicApi.proxyGet('/countries/all', null, { silent: true });
    } catch (e) {
      console.log('error : getAllCountries', e);
      throw e;
    }
  }

  /**
   * @id GetAnnualEarningGradesRequest
   * @returns {AnnualEarningGrades}
   */
  getAnnualEarningGrades() {
    try {
      return this.#proxyNpUserApi.proxyGet('/codes/annual-earning-grades');
    } catch (e) {
      console.log('error : getAnnualEarningGrades', e);
      throw e;
    }
  }

  /**
   * @id GetExpectedMonthlyDepositGradesRequest
   * @returns {ExpectedMonthlyDepositGrades}
   */
  getExpectedMonthlyDepositGrades() {
    try {
      return this.#proxyNpUserApi.proxyGet('/codes/expected-monthly-deposit-grades');
    } catch (e) {
      console.log('error : getExpectedMonthlyDepositGrades', e);
      throw e;
    }
  }

  /**
   * @description direct Tnc
   * */
  async validateRefKey(ReferenceKey) {
    try {
      return await this.#proxyNpPublicApi.proxyGet('/v1/auth/tokens/states', { ReferenceKey });
    } catch (e) {
      return e;
    }
  }

  /**
   * @description ReferenceKey 기반 가입 가능 국가 목록 조회
   * @id GetSignupCountriesByReferenceKeyRequest
   * @param {string} ReferenceKey
   * @returns {Promise<ArrayLike<T>|Array<AddressInfo>|SupportedCountry[]|PhoneCodeCountry[]|CityItem[]>}
   */
  async getRefCountryList(ReferenceKey) {
    try {
      const r = await this.#proxyNpPublicApi.proxyGet(`/v1/region/countries/sign-up/${ReferenceKey}`, null, { silent: true });
      return r.List;
    } catch (e) {
      console.log('error : getRefCountryList', e);
      throw (e);
    }
  }

  /**
   * @description direct Tnc
   * */
  async getOpTokenByNpToken(reset) {
    try {
      if (!this.#gpToken || reset) {
        this.#gpToken = await this.#proxyNpUserApi.proxyGet('/v1/auth/tokens/onepass', {}, { silent: true });
        this.#services.store.commit('user/setGpToken', this.#gpToken.AccessToken);
        this.#services.cookie.setGpToken(this.#gpToken.AccessToken);
      }
      return true;
    } catch (e) {
      console.log('error : getOpTokenByNpToken', e);
      throw e;
    }
  }

  /**
   * @description 통화 정보 리스트
   * @id GetCurrenciesRequest
   * @returns {Promise<CurrencyItem[]>}
   */
  async getCurrencyList() {
    try {
      return await this.#proxyNpPublicApi.proxyGet(`/currencies`, null, { silent: true });
    } catch (e) {
      console.log('error : getCurrencyList', e);
      throw e;
    }
  }

  reset() {
    this.#countrySignupList = null;
    this.#countryOnboardingList = null;
    this.#stateList = null;
  }
}
