<template>
  <div gp-text-button>
    <button @click="click">
      <slot />
    </button>
  </div>
</template>

<script>
export default {
  name: 'TextButton',
  methods: {
    click() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-text-button] {.flex(); .justify-end(); .min-h(14);
  > button { .c(#3ea6ff); text-edge: cap; .fs(12); text-decoration-line: underline; font-family: inherit; }
}
</style>
