<template>
  <div re-check-de>
    <p class="guide1" v-html="$t('kyc.deGiude.reCheckDescription')" />
    <PersonalNames :model-info="modelInfo" :structure="structure" :item="item" />
    <PersonalBirth :model-info="modelInfo" :structure="structure" :item="item" />
    <ValidationErrors :error-msg="birthErrorMsg" />
    <RecheckAddress :error="specificError" :model-info="modelInfo" @searching="searching" />
    <ValidationErrors :error-msg="submitErrorMsg" />
    <ProgressButton :button-name="buttonName" :name="buttonTag" :class="currentItem.buttonClass" :disabled="structure.error || invalid || !validConfirm || buttonDisabled" @click="confirm" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import recheckError from '@/constants/error/onboarding/recheckError';
import {sleep} from "@shared/utils/commonUtils.mjs";
import Specific from '@shared/types/Specific';
import TinkController from "@/plugins/tinkController";
import baseTemplate from '@/mixins/baseTemplate';
import PersonalNames from '@/views/components/pages/on-boarding/template/PersonalNames.vue';
import PersonalBirth from '@/views/components/pages/on-boarding/template/birth/PersonalBirth.vue';
import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import RecheckAddress from '@/views/components/pages/on-boarding/template/personal-details-recheck/de/RecheckAddress.vue';
import {tinkStatus} from "@/constants/base/onboarding/kyc";

export default {
  name: 'ReCheckDe',
  components: { RecheckAddress, ProgressButton, PersonalBirth, PersonalNames },
  mixins: [baseTemplate],
  props: {
    invalid: { type: Specific, default: null },
  },
  data() {
    return {
      specificError: null,
      validConfirm: true,
      tink: null,
      status: tinkStatus.None,
      TrimTextInput,
    };
  },
  watch: {
    // isSearch(v) {
    //   if (!v) this.initData();
    //   else this.isReady = false;
    // }
    'structure.error': 'errorInit'
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
    csEmail: state('env', 'csEmail'),
    step() {
      return this.structure.step;
    },
    lastStep() {
      return this.step >= this.structure.list.length - 1;
    },
    currentItem() {
      return this.structure?.list[this.step];
    },
    buttonTag() {
      const t = this.currentItem?.buttonTag;
      if (!t) return '';
      return t?.[this.site] || t.default || t;
    },
    buttonName() {
      return this.currentItem.buttonName || (this.lastStep ? 'submit' : 'next');
    },
    buttonDisabled() {
      const { MaidenName, PlaceOfBirth, Gender, ContactMobileNumber, Nationality } = this.modelInfo;
      return !MaidenName || !PlaceOfBirth || !Gender || !ContactMobileNumber || !Nationality;
    },
    birthErrorMsg() {
      return this.specificError?.birthErrorMsg;
    },
    submitErrorMsg() {
      return this.$t(this.specificError?.submitErrorMsg, this.specificError?.params);
    }
  },
  methods: {
    /**
     * 컨트롤러에서 결과 콜백
     * @param info
     */
    callback(info) {
      console.log('%ctink callback', 'color:yellow', info);
      this.status = info.status;
      if (this.status === tinkStatus.Ready) this.tink.redirectTink();
    },
    searching(v) {
      this.structure.error = false;
      this.structure.errorMsg = null;
      this.validConfirm = !v;
    },
    async confirm() {
      await this.apiRequest();
    },
    async apiRequest() {
      const r = await this.getApiResponse();
      if (r.error) {
        await this.apiErrorHandler(r);
        if (process.env.VUE_APP_ENV !== 'production') this.$toast(r.desc, { type: 'fail', translate: false });
      } else {
        const { $services: services, $router: router, site, lang, callback } = this;
        this.tink = new TinkController(this, { services, router, site, lang, callback });

        await sleep(60);
        await this.tink.tryAgain();
      }
    },
    /**
     *
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse() {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding.updatePersonalReCheck(this.modelInfo); } catch (e) { return e; }
    },
    async apiErrorHandler(r) {
      const { key } = r;
      if (key === apiErrorCode.USER_INFO_ALREADY_EXIST) {
        const proxyResult = this.$onboarding.errorHandler(r, true);
        return;
      }
      this.structure.error = true;
      const translateFunction = (v) => this.$t(v);
      this.specificError = recheckError(key, translateFunction);
      this.structure.errorMsg = key;
    },
    errorInit() {
      if (!this.structure.error) this.specificError = null;
    }
  },
  mounted() {
    this.structure.customSteps = [routeNames.personalDetailsRecheck];
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[re-check-de] {.flex();.flex-dc(); .gap(15);
  .guide1 {.c(white); }
  //> p { .c(@c-text-desc); .mb(24); }
  > div {
    > input { .wh(0); .abs();}
  }
  > *:nth-of-type(2) { .mt(8); }
  .address-input-group {.pt(20);}

  .field-holder { .flex(); .space-between();
    > * {.w(calc(50% - 5px)); }
  }
  .btn-update-field {.c(@c-link); border: none; .flex(); .gap(3); .items-center();
    > span {border-bottom: 1px solid @c-link;}
  }
  .view-address-group {.flex(); .flex-dc(); .gap(10); .pt(30);
    [validation-errors] { .hide(); }
    .between {.flex(); .gap(10); .w(100%);
      > *:nth-of-type(1) { flex: 1; }
      > *:nth-of-type(2) { flex: 2; }
    }

    @media (@mm-down) {
      .between {.flex(); .gap(10); .w(100%); .flex-dc();
        > *:nth-of-type(1) { flex: 1; }
        > *:nth-of-type(2) { flex: 1; }
      }
    }
  }
}
</style>