<template>
  <div daily-freebie v-if="isReady">
    <div class="notification" v-if="showNotification">
      <div class="message">
        <p>
          <FontIcon :name="notificationIcon" />
          <span>{{ $t(notificationMessage) }}</span>
        </p>
        <button class="close-btn" @click="() => showNotification = false">
          <FontIcon name="close-thin" />
        </button>
      </div>
    </div>
    <div class="container">
      <div class="unable-promotion-message" v-if="unavailablePromotion">
        <p v-html="$t(errorMessage.text || errorMessage, errorMessage.options)" />
      </div>
      <h3>{{ $t('_.title') }}</h3>
      <PromotionTncLink v-if="!hideTcn" />
      <div class="black-container">
        <h4>{{ $t(isEnablePromotion ? 'promotion.type.dailyFreebie' : '_.nextReward') }}</h4>
        <template>
          <div class="main">
            <template v-if="isEnablePromotion">
              <p>{{ $t(`promotion.${getWeekdayText(mainWeekday)}`) }}</p>
              <BonusItem v-for="item in mainPrizeItems" :info="item" active />
            </template>
            <PromotionTimer v-else :time="remainTime" @update="updateInfo" />
          </div>
        </template>
        <Slider :info="prizeList" slides-per-view="auto">
          <template v-slot:slide="{info}">
            <div class="slide">
              <p class="slide-title">{{ $t(`promotion.${getWeekdayText(info.startedAtWeekday)}`) }}</p>
              <BonusItem v-for="(item, i) in info.items" :info="item" :key="i" />
            </div>
          </template>
        </Slider>
      </div>
      <div class="term-and-conditions" v-if="requiredTnC">
        <CheckboxItem theme="dark" v-model="agreedNLTnC" @change="confirmTnC">
          <span v-html="$t('_.acceptPromotionTnC', { url: termsConditionsUrl  })" />
        </CheckboxItem>
        <p v-if='showTnCError'>{{ $t('_.requiredPromotionTnC') }}</p>
      </div>
      <div class="button-holder">
        <PromotionButton v-if="isEnablePromotion" button-name="promotion.dailyFreebie.claimYourFreeReward" @click="claim" :disabled="claimDisabled" />
        <PromotionButton v-else button-name="promotion.dailyFreebie.freeRewardReleased" type="gray" :disabled="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import ContentsContainer from '@/views/components/pages/promotion/ContentsContainer.vue';
import { isEmpty } from 'lodash';
import PromotionTimer from '@/views/components/pages/promotion/PromotionTimer.vue';
import BonusItem from '@/views/components/pages/promotion/BonusItem.vue';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import PromotionTncLink from '@/views/components/pages/promotion/PromotionTncLink.vue';
import Slider from '@shared/components/general/Slider.vue';
import PromotionButton from '@/views/components/pages/promotion/PromotionButton.vue';
import { toMilliseconds } from '@/utils/dateTimeUtil';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { siteIds } from '@/constants/base/siteMap';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'DailyFreebie',
  lexicon: 'promotion.dailyFreebie',
  components: { FontIcon, PromotionButton, Slider, PromotionTncLink, CheckboxItem, BonusItem, PromotionTimer, ContentsContainer },
  data() {
    return {
      dailyInfo: /** @type {DailyFreebie} */ null,
      agreedTnC: false,
      showTnCError: false,
      isReady: false,
      mainPrize: null,
      unavailablePromotion: false,
      showNotification: false,
      notificationMessage: '',
      notificationIcon: '',
      errorMessage: null,
    };
  },
  computed: {
    env: state('env', 'env'),
    site: state('env', 'site'),
    isGGPNL() { return this.site === siteIds.GGPNL; },
    hideTcn() {
      if (!this.site) return true;
      return [siteIds.GGPUKE, siteIds.EVPUKE, siteIds.DAVAOPOKER, siteIds.SEVENXL, siteIds.NATURAL8, siteIds.N8IN, siteIds.TWOACE].includes(this.site);
    },
    status() {
      this.dailyInfo.attendeeStatus;
    },
    prizeList() {
      const list = this.dailyInfo?.prizes;
      return list ? this.filterItems(list) : [];
    },
    isEnablePromotion() {
      if (isEmpty(this.dailyInfo)) return false;
      const { isCurrentWeekdayPrizePaid: isPaid, isCurrentWeekdayPrizePayable: isPayable } = this.dailyInfo;
      return !isPaid && isPayable;
    },
    requiredTnC() {
      return this.isGGPNL && !this.dailyInfo?.isTermsAndConditionsConsent;
    },
    termsConditionsUrl() {
      return this.dailyInfo?.termsConditionsUrl;
    },
    mainWeekday() {
      return this.mainPrize?.startedAtWeekday;
    },
    mainPrizeItems() {
      return this.mainPrize?.items || [];
    },
    claimDisabled() {
      const unavailablePromotion = !this.isEnablePromotion;
      const unConfirmedTnC = this.requiredTnC && !this.agreedTnC;
      const notOptIn = this.dailyInfo?.attendeeStatus !== 'OPTED_IN';

      return unavailablePromotion || unConfirmedTnC || notOptIn;
    },
    remainTime() {
      const t = this.dailyInfo?.timeUntilNextWeekdayPrizeStart;
      return !t ? 0 : toMilliseconds(this.dailyInfo.timeUntilNextWeekdayPrizeStart);
    },
  },
  methods: {
    confirmTnC(v) {
      this.agreedTnC = v;
    },
    filterItems(list) {
      const now = this.dailyInfo?.currentPrizeId;
      const i = list.findIndex(o => o.prizeId === now);
      this.mainPrize = list[i];
      list.splice(i, 1);
      return [...list.splice(i), ...list];
    },
    getWeekdayText(v) {
      return v?.slice(0, 3).toLowerCase();
    },
    async claim() {
      const id = this.mainPrize.prizeId;
      if (!id) return;
      const r = await this.$services.promotion.payPromotionPrize(id);
      if (r && !r.error) {
        if (this.requiredTnC) await this.$services.promotion.tncConsent('DAILY_FREEBIE', this.agreedTnC);
        this.notificationMessage = 'promotion.notification.rewardReleased';
        this.notificationIcon = 'check-thin';
        this.showNotification = true;
        await this.updateInfo();
      } else {
        if (r?.key === apiErrorCode.DAILY_FREEBIE_RESTRICTED) {
          this.errorMessage = 'promotion.errorMessage.winterPromotion2024';
          this.unavailablePromotion = true;
        }
        else {
          switch (r?.key) {
            case apiErrorCode.ACCOUNT_CONDITION_UNAVAILABLE:
              this.notificationMessage = 'promotion.notification.unableToClaim';
              break;
            case apiErrorCode.PROMOTION_CONDITION_UNAVAILABLE:
              this.notificationMessage = 'promotion.notification.alreadyClaimedSameDeviceAndIP';
              break;
            case apiErrorCode.PRIZE_CONDITION_UNAVAILABLE:
              this.notificationMessage = 'promotion.notification.rewardCannotBeClaimed';
              break;
            case apiErrorCode.PRIZE_EXPIRED:
              this.notificationMessage = 'promotion.notification.rewardExpired';
              break;
            case apiErrorCode.INVALID_PROMOTION_STATUS:
              this.notificationMessage = 'promotion.notification.promotionExpired';
              break;
            default:
              this.notificationMessage = 'promotion.notification.rewardClaimFailed';
              break;
          }
          this.notificationIcon = 'info-circle-line';
          this.showNotification = true;
        }
      }
    },
    async optIn() {
      const r = await this.$services.promotion.optInDailyFreebie();
      if (r && !r.error) {
        await this.updateInfo();
      } else {
        switch (r?.key) {
          case apiErrorCode.DAILY_FREEBIE_RESTRICTED:
            this.errorMessage = 'promotion.errorMessage.winterPromotion2024';
            this.unavailablePromotion = true;
            break;
          case apiErrorCode.ATTENDEE_ALREADY_EXISTS:
            await this.updateInfo();
            break;
          case apiErrorCode.PROMOTION_CONDITION_UNAVAILABLE:
            this.errorMessage = { text: 'promotion.errorMessage.notEligiblePromotion', options: { tncLink: r?.desc2 } };
            this.unavailablePromotion = true;
            break;
          case apiErrorCode.ACCOUNT_CONDITION_UNAVAILABLE:
            this.errorMessage = 'promotion.errorMessage.restrictedParticipationInPromotion';
            this.unavailablePromotion = true;
            break;
          case apiErrorCode.ATTENDEE_ALREADY_OPTED_OUT:
            this.errorMessage = 'promotion.errorMessage.optedOutPromotion';
            this.unavailablePromotion = true;
            break;
          case apiErrorCode.INVALID_PROMOTION_STATUS:
            this.errorMessage = 'promotion.errorMessage.promotionDoesNotExist';
            this.unavailablePromotion = true;
            break;
          default:
            this.errorMessage = 'promotion.errorMessage.unableOptIntoPromotion';
            this.unavailablePromotion = true;
            break;
        }
      }
    },
    async updateInfo(isFirstTry = false) {
      const r = await this.$services.promotion.getDailyFreebie();
      if (r && !r.error) {
        this.dailyInfo = r;
        switch (r?.attendeeStatus) {
          case 'OPTED_IN_PAUSED':
            this.errorMessage = 'promotion.errorMessage.restrictedPromotion';
            this.unavailablePromotion = true;
            break;
          case 'OPTED_OUT':
            this.errorMessage = 'promotion.errorMessage.optedOutPromotion';
            this.unavailablePromotion = true;
            break;
          default:
            break;
        }
      } else {
        switch (r?.key) {
          case apiErrorCode.DAILY_FREEBIE_RESTRICTED:
            this.errorMessage = 'promotion.errorMessage.winterPromotion2024';
            this.unavailablePromotion = true;
            break;
          case apiErrorCode.ATTENDEE_NOT_FOUND:
            if (isFirstTry) await this.optIn();
            break;
          case apiErrorCode.PROMOTION_NOT_FOUND:
            this.errorMessage = 'promotion.errorMessage.notOptedPromotion';
            this.unavailablePromotion = true;
            break;
          case apiErrorCode.INVALID_PROMOTION_STATUS:
            this.errorMessage = 'promotion.errorMessage.promotionExpired';
            this.unavailablePromotion = true;
            break;
          default:
            this.errorMessage = 'promotion.errorMessage.promotionInformationError';
            this.unavailablePromotion = true;
            break;
        }
      }
    },
    async initialize() {
      this.$services.modal.block();
      await this.updateInfo(true);
      this.$services.modal.unblock();
      this.isReady = true;
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>

<style lang="less">
@import "@/less/tpm";
[daily-freebie] {
  .term-and-conditions, .unable-promotion-message {
    a { .c(#5ab4ff); }
  }

  [slider] { .-t(#262626);
    .swiper-slide { .w(calc(100% / 6)); .min-w(130);
      + .swiper-slide { .-start(#262626); }
      .slide { .pv(20); }
    }
  }
}
</style>
<style scoped lang="less">
@import "@/less/tpm";
[daily-freebie] { .tc();
  .notification { .rel(); .mb(20); .bgc(white); .fs(16); .medium();
    &::before { .cnt(); .abs(); .t(0); .l(0); .w(7); .hf(); .bgc(@c-blurRed); }
    .message { .flex(); .space-between(); .p(1em, 1.25em, 1em, 1.875em);
      p { .flex(); .items-center(); .column-gap(0.625em); .fs(1em); .c(#040404); .medium();
        [font-icon] { flex: 0; .fs(1.25em); line-height: 1.2; }
      }
      .close-btn { .bgc(); .fs(1em);
        [font-icon] {.fs(1.25em); .c(#aaa); }
      }
    }

    @media (@tp-up) {
      .message { .pl(2.5em); }
    }
  }
  .container { .rel(); .bgc(@c-bgGray); .mt(20); .p(32, 16); }
  .unable-promotion-message { .z(10); .flex(); .flex-dc(); .justify-center(); .items-center(); .abs(); .lt(0, 0); .f(); .bgc(rgba(0, 0, 0, 0.8)); }
  [promotion-button] { .wf(); .p(12, 20); .br(2em); .bgc(#c64040); .c(@c-white); .fs(18);
    //&:disabled { .c(#e03939); }
    &:hover { .bgc(#b13232); }
    &:disabled { .o(0.35);
    }
  }
  .black-container { .mt(12); .bgc(@c-bgBlack); }
  .slide-title { .c(@c-gray3); .mb(20); }
  // [celina] 위쪽으로는 정리가 필요한 코드
  h3 { .mb(28); .fs(24); .medium(); }
  .term-and-conditions { .flex(); .justify-center(); .items-center(); .mt(16); .tc(); }
  h4 { .p(8); .bgc(#414141); .fs(16); .regular(); }
  .main { .pv(24);
    > p { .mb(20);}
    [promotion-timer] { .fs(44); }
    [bonus-item] { .fs(16); }
  }
  .button-holder { .wf(); .max-w(420); .mt(28); .mh-c(); }
  .test { .grid(); .grid-template-columns(repeat(7, 1fr)); .gap(8); .justify-center(); .items-center(); .mt(12);
    button { .w(); .ph(4); .br(4); }
  }

  @media (@mm-up) {
    .container { .ph(32); }
  }
  @media (@tp-up) {
    .container { .ph(70); }
  }
}
</style>
