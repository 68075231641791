<template>
  <div kyc-iframe>
    <iframe :src="url" allow="camera;fullscreen;accelerometer;gyroscope;magnetometer" />
    <em>
      <FontIcon name="rotate-arrows" />
    </em>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
export default {
  name: 'KycIframe',
  components: { FontIcon },
  props: {
    url: { type: String, required: true },
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[kyc-iframe] { .bgc(white); .wh(100%); .min-h(600); .rel();
  > em { .abs(); .lt(50%, 50%); .t-xyc(); .z(1);
    [font-icon] { .block(); .fs(48); .c(@c-text-gray); animation: rotate-circle 2s linear infinite;}
  }
  > iframe { .wh(100%, 100%); .abs(); .z(2); .bgc(white); }
}
</style>