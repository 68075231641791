<template>
  <div reaffirm-de-kyc-de>
    <template v-if="template">
      <ProgressTitle :default-label="title" is-final />
      <component :is="template" :model-info="modelInfo" :item="item" :structure="structure" @step="stepTrigger" />
    </template>
  </div>
</template>

<script>
import {state} from "@shared/utils/storeUtils.mjs";
import {checkClientPermission} from "@/utils/clientUtil";
import ProgressTitle from '@/views/components/pages/on-boarding/ProgressTitle.vue';
import Jumio from '@/views/components/pages/on-boarding/template/kyc/jumio/Jumio.vue';
import KycReadyDe from "@/views/components/pages/on-boarding/template/kyc/GGPDE/KycReadyDe.vue";
import TinkFailed from "@/views/components/pages/on-boarding/template/kyc/tink/TinkFailed.vue";
import SofortFailed from "@/views/components/pages/on-boarding/template/kyc/sofort/SofortFailed.vue";
import baseTemplate from '@/mixins/baseTemplate';
export default {
  name: 'ReaffirmDeKycDe',
  components: { ProgressTitle },
  mixins: [baseTemplate],
  data() {
    return {
      step: 'ready',
      components: {
        ready: KycReadyDe,
        jumio: Jumio,
        sofortFailed: SofortFailed, // TODO : [ean] 이거 안쓰는 것 같은데..
        tinkFailed: TinkFailed, // TODO : [ean] 이거 안쓰는 것 같은데..
      },
    };
  },
  computed: {
    env: state('env', 'env'),
    title() {
      switch (this.step) {
        case 'jumio':
          return 'onBoarding.title.residenceDocumentUpload';
        case 'tinkFailed':
          return 'onBoarding.title.tinkFailed';
        case 'sofortFailed':
          return 'onBoarding.title.sofortFailed';
        default:
          return 'onBoarding.reaffirm.de.title';
      }
    },
    template() {
      return this.components[this.step];
    }
  },
  methods: {
    stepTrigger(step) {
      this.step = step;
    },
  },
  mounted() {
    checkClientPermission(this);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[reaffirm-de-kyc-de] {
  > p { .fs(16, 24); .mb(8); .medium(); }
  > a { .-a(@c-w01, 2px); .br(8); .block(); .rel(); .p(28, 24); .flex(); .items-center();
    > img { .h(40); }
    > div { .pl(24); .ml(24); .rel();
      &:before { .cnt(); .wh(1, 110%); .bgc(@c-w01); .abs(); .lt(0, 50%); .t-yc(); }
      h4 { .fs(20); .c(#FFC64D); .mb(8); }
      p { .fs(14, 20); }
    }
    &:nth-of-type(2) { .mt(24); }
  }
}
</style>