import { fastIdentStatus, tinkStatus } from '@/constants/base/onboarding/kyc';

export default class TinkController {
  #scope;
  #site;
  #lang;
  #services;
  #router;
  #callback;
  #modelInfo;
  #status;
  #failedStatus;
  #result;
  #url;
  #tryCount;
  #intervalId;
  #intervalMilliSecond;
  #currentMilliSeconds;
  #limitMilliSecond;

  constructor(scope, { services, router, site, lang, callback, modelInfo }) {
    this.#scope = scope;
    this.#modelInfo = modelInfo;
    this.#services = services;
    this.#router = router;
    this.#site = site;
    this.#lang = lang;
    this.#callback = callback;
    this.#limitMilliSecond = 3 * 60 * 1000; // 3분
    this.#intervalMilliSecond = 5 * 1000; // 5초
    this.#restart();
  }

  /**
   * onboarding에서 활용하는 token을 3rd party 진행 시 자동 한시간 연장 되도록 요청
   * @returns {Promise<ResponseAuthInfo>}
   */
  async #postAutoRenewToken() {
    const r = await this.#services.token.autoRenewToken();
    r?.error && this.#errorHandler(r);
    return r;
  }

  /**
   * Tink URL 조회
   * @returns {Promise<*>}
   */
  async #getUrl() {
    const path = this.#services.store.state.env.env === 'local' ? '/onboarding' : '/app';
    const redirectUrl = `${location.origin}${path}/receiver-tink`;
    // const redirectUrl = `https://onboarding-ggpcom-test.ggcore.net${path}/receiver-tink`;
    const r = await this.#services.kyc.getTinkUrl(redirectUrl);
    r?.error && this.#errorHandler(r);
    return r;
  }

  async #getTinkResult() {
    const r = await this.#services.kyc.getTinkResult();
    r?.error && this.#errorHandler(r);
    return r;
  }

  /**
   * error 발생 시 에러 코드 별 처리 분기
   * @param {object} r - error 정보
   */
  #errorHandler(r) {
    this.#clearPollingInfo();
    const { code, desc, key, CustomerErrorParameters, errorTemplate } = r;
    const replace = { path: 'VerificationFailed', param: {desc, errorTemplate}, query: { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined }};
    if(replace) this.#router.replace({ name: replace.path, params: replace.param, query: replace.query});

    this.#callbackStatusInfo(tinkStatus.Failed);
  }

  /**
   * 진행중인 sofort 현황 정보 반환
   * @returns {{lang, status, url}}
   */
  #getInfo() {
    return { lang: this.#lang, status: this.#status, failedStatus: this.#failedStatus, result: this.#result, url: this.#url, tryCount: this.#tryCount, limitMilliSecond: this.#limitMilliSecond };
  }

  /**
   * Polling 관련 정보 초기화
   */
  #clearPollingInfo() {
    this.#currentMilliSeconds = 0;
    this.#intervalId && clearInterval(this.#intervalId);
    this.#intervalId = null;
  }
  /**
   * tink 진행 상태 반복 조회
   * 5초 마다 반복 호출을 하여 상태 Polling
   * 최대 3분을 넘어갈 경우 UnMatch error 화면 노출
   * @returns {Promise<void>}
   */
  #pollingStatus() {
    this.#intervalId = setInterval(async () => {
      // currentMilliSecond이 최대 시간보다 같거나 넘어갈 경우 Inverval을 초기화 시키고 ServiceAbort로 이동
      if (this.#limitMilliSecond < this.#currentMilliSeconds) {
        this.#failedStatus = fastIdentStatus.InitialError;
        this.#callbackStatusInfo(tinkStatus.Failed);
      } else {
        // currentMilliSecond에 intervalMilliSecond를 추가
        this.#currentMilliSeconds += this.#intervalMilliSecond;

        const r = await this.#getTinkResult();
        if (r?.error) this.#clearPollingInfo();

        this.#failedStatus = r.FastIdentStatus;
        this.#result = r.MatchResult;
        this.#tryCount = r.TryCount;

        this.#callbackStatusInfo(r.Status);
      }
    }, this.#intervalMilliSecond);
  }
  /**
   * tink 상태 값에 따른 화면 전환 처리
   * @param step
   */
  async #callbackStatusInfo(status) {
    console.log('%ctink controller #callbackStatusInfo', 'color:yellow', status);

    if (status !== this.#status) {
      this.#status = status;
      this.#clearPollingInfo();
    }

    let r;
    switch (status) {
      case tinkStatus.None:
      case tinkStatus.UrlFailed:
      case tinkStatus.SessionExpired:
        r = await this.#getUrl();
        if (r?.error) return;
        this.#status = (!r.Url || r?.error) ? tinkStatus.UrlFailed : tinkStatus.Ready;
        this.#url = r.Url;
        break;
      case tinkStatus.Pending:
        if (!this.#intervalId) this.#pollingStatus();
        break;
      case tinkStatus.Failed:
      case tinkStatus.Verified:
      case tinkStatus.Aborted:
      default:
        break;
    }

    this.#callbackInfo();
  }
  /**
   * tink 현재 정보를 기준으로 화면 전환 처리
   */
  #callbackInfo() {
    this.#callback(this.#getInfo());
  }
  /**
   * 초기화
   * @returns {Promise<void>}
   */
  async #initialize() {
    this.#clearPollingInfo();
    this.#url = null;
    this.#status = null;
    this.#failedStatus = null;
    this.#result = null;
    this.#tryCount = null;
  }
  /**
   * tink 단계 초기화
   */
  async #restart() {
    await this.#initialize();
  }
  /**
   * 화면에서 버튼이나 특정 이벤트 시 Status 갱신
   * @param {string} status - 전환 step
   */
  async updateStatus(status) {
    if (!status) return;
    await this.#callbackStatusInfo(status);
  }
  /**
   * 재시도
   */
  async tryAgain() {
    await this.updateStatus(tinkStatus.UrlFailed);
  }
  async redirectTink() {
    if (typeof window === 'undefined') return;
    await this.#postAutoRenewToken();
    location.href = this.#url;
  }
}