<template>
  <BaseModal my-device-remove-result :show-title="false">
    <template #content>
      <div class="content-container">
        <template v-if="!isError">
          <FontIcon name="check-over-circle-regular" />
          <h4>{{ $t('_.successMessage') }}</h4>
        </template>
        <template v-else>
          <FontIcon name="close-circle-regular" class="error" />
          <h4 v-html="errorMessage" />
        </template>
      </div>
    </template>
    <template #footer>
      <GpButton type="submit-green" @click="close">{{ $t('ok') }}</GpButton>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/views/components/gg-pass/modal/BaseModal.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'MyDeviceRemoveResult',
  lexicon: 'myInfo.myDevices',
  props: {
    options: Specific,
  },
  components: { FontIcon, GpButton, BaseModal },
  computed: {
    csEmail: state('env', 'csEmail'),
    isError() {
      return this.options?.error;
    },
    errorMessage() {
      return this.$t('INVALID_REQUEST', { csEmail: this.csEmail });
    }
  },
  methods: {
    close() {
      this.$modalCloseAll();
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[my-device-remove-result] {
  .content-container { .tc(); .mt(24); .mb(36);
    h4 { .medium(); .c(#000); .fs(14); }
    a[href^="mailto:"] { .c(#1570EF); .underline(); }
  }
  [font-icon] { .fs(30); .c(@gp-green);
    &.error { .c(#ED4646); }
  }
}
</style>
