<template>
  <div address-uk>
    <SearchLoqateAddress v-if="isSearch" :useGuide1="false" :useGuide2="false" @reset="reset" @selected="selected" />
    <template v-else>
      <div class="fields-holder" v-if="isReady">
        <ValidationComponent :label="$t('_.buildingAddress')" name="_country" :component="TrimTextInput" :rules="buildingRules" :preset="buildingPreset" v-model="modelInfo.Building" :error-msg="buildingErrorMsg" />
        <ValidationComponent :label="$t('_.street')" name="_street" :component="TrimTextInput" :rules="addressRules" :preset="addressPreset" v-model="modelInfo.Address" :error-msg="addressErrorMsg" />
        <ValidationComponent :label="$t('_.city')" name="_city" :component="TrimTextInput" :rules="cityRules" :preset="cityPreset" v-model="modelInfo.City" :error-msg="cityErrorMsg" />
        <ValidationComponent :label="$t('_.postalCode')" name="_postalCode" :component="TrimTextInput" :rules="postalRules" :preset="postalPreset" v-model="modelInfo.PostalCode" :error-msg="postalCodeErrorMsg" />
        <a class="btn-update-field" @click.stop="reset(true)">{{ $t('_.searchAddress') }}</a>
      </div>
      <ProgressButton v-if="isReady" :button-name="buttonName" :name="buttonTag" :class="currentItem.buttonClass" :disabled="!!structure.limitTime || structure.error || invalid" @click="confirm" />
    </template>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import baseTemplate from '@/mixins/baseTemplate';
import FontIcon from '@shared/components/common/FontIcon.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import SearchLoqateAddress from '@/views/components/pages/on-boarding/template/address/SearchLoqateAddress.vue';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'AddressUk',
  lexicon: 'personalDetails',
  components: { ProgressButton, ValidationComponent, SearchLoqateAddress },
  mixins: [baseTemplate],
  props: {
    invalid: { type: Specific, default: null },
  },
  data() {
    return {
      isSearch: true,
      isReady: false,
      selectedInfo: null,
      buildingPreset: Specific,
      addressPreset: Specific,
      cityPreset: Specific,
      postalPreset: Specific,
      buildingRules: Specific,
      addressRules: Specific,
      cityRules: Specific,
      postalRules: Specific,
      districtErrorMsg: null,
      streetErrorMsg: null,
      houseNumberErrorMsg: null,
      addressErrorMsg: null,
      stateErrorMsg: null,
      buildingErrorMsg: null,
      cityErrorMsg: null,
      postalCodeErrorMsg: null,
      TrimTextInput,
    };
  },
  watch: {
    isSearch(v) {
      if (!v) this.initData();
      else this.isReady = false;
    }
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
    csEmail: state('env', 'csEmail'),
    step() {
      return this.structure.step;
    },
    lastStep() {
      return this.step >= this.structure.list.length - 1;
    },
    currentItem() {
      return this.structure?.list[this.step];
    },
    buttonTag() {
      const t = this.currentItem?.buttonTag;
      if(!t) return '';
      return t?.[this.site] || t.default || t;
    },
    buttonName() {
      return this.currentItem.buttonName || (this.lastStep ? 'submit' : 'next');
    },
  },
  methods: {
    /**
     * SearchLoqateAddress 에서 선택 결과 반환
     * @param {object} v - 선택 결과
     */
    selected(v) {
      this.selectedInfo = v;
    },
    /**
     * 검색 초기화
     * @param {Boolean} v - 검색 초기화 여부
     */
    reset(v) {
      this.isSearch = v;
    },

    /**
     * modelInfo.PostalCode 유무에 따른 검색이나 결과 노출 처리
     */
    initialize() {
      this.isSearch = !this.modelInfo.PostalCode;
    },
    /**
     * 결과 노출 시 초기 설정
     * @returns {Promise<void>}
     */
    initData() {
      this.setDetailAddress().then(() => {
        this.initRules();
        this.initPreset();
        this.initErrorMsg();
        this.isReady = true;
      });
    },
    /**
     * Rules 설정
     */
    initRules() {
      this.buildingRules = `requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`;
      this.addressRules = `requiredAddress:0,120,${this.modelInfo.CountryWritingSystem}`;
      this.cityRules = `requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`;
      this.postalRules = `requiredAddress:0,20,${this.modelInfo.CountryWritingSystem}`;
    },
    /**
     * Preset 설정
     */
    initPreset() {
      this.buildingPreset = {maxLength: 50};
      this.addressPreset = {maxLength: 120};
      this.cityPreset = {maxLength: 50};
      this.postalPreset = {maxLength: 20};
    },
    /**
     * 에러 메세지 초기화
     */
    initErrorMsg() {
      this.districtErrorMsg = null;
      this.streetErrorMsg = null;
      this.houseNumberErrorMsg = null;
      this.addressErrorMsg = null;
      this.stateErrorMsg = null;
      this.buildingErrorMsg = null;
      this.cityErrorMsg = null;
      this.postalCodeErrorMsg = null;
    },
    /**
     * Building property 에 저장 할 BuildingAddress 정보 설정
     * @param buildingName
     * @param buildingNumber
     * @returns {*}
     */
    setBuildingAddress(buildingName, buildingNumber) {
      const arrBuildingAddress = [buildingName, buildingNumber];
      return arrBuildingAddress.reduce((a, b) => (a && b ? `${a} ${b}` : a || b));
    },

    /**
     * selectedInfo 값 유무에 따라 상세 주소 조회 또는 ModelInfo를 기반으로 정보 설정
     * @returns {Promise<void>}
     */
    async setDetailAddress() {
      if (this.selectedInfo) {
        const { selectedItem, sessionToken } = this.selectedInfo;
        const params = { SiteId: this.site, Country: this.userInfo.Country, AddressId: selectedItem.Id, SessionToken: sessionToken };
        const r = await this.apiRequest('searchDetailAddress', params);

        const buildingAddress = this.setBuildingAddress(r.BuildingName, r.BuildingNumber);
        const setProperties = [{name: 'Building', value: buildingAddress}, {name: 'Address', value: r.Street}, {name: 'HouseNumber', value: r.BuildingNumber}];

        Object.keys(r).forEach(o => { this.modelInfo[o] = r[o]; });
        setProperties.forEach(p => { this.modelInfo[p.name] = p.value; });
      }
    },
    /**
     * 입력 정보 저장
     * @returns {Promise<void>}
     */
    async confirm() {
      await this.apiRequest(this.currentItem?.api, this.structure.model);
      if (!this.invalid && !this.structure.error) this.modelInfo.step = 1;
    },

    /**
     * 컴포넌트 내에서의 api request 처리
     * @param {Specific} params - 호출 파라미터
     * @param {string} api - api 함수 명
     * @param {object} params - params
     * @param {object} config - config
     * @returns {Promise<*|null>}
     */
    async apiRequest(api, params, config = null) {
      const r = await this.getApiResponse(api, params, config);
      if (r?.error) {
        if(!(this.$onboarding.addressErrorHandler(r))) return;

        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : onboarding.${api}`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }

        return;
      }

      return r;
    },
    /**
     * api 결과 반환 처리
     * @param {string} api - api 호출 함수 명
     * @param {object} params - 호출 함수 전달 정보
     * @param {object} config - 설정
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse(api, params, config = null) {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding[api]({ ...params }, { ...config }); }
      catch (e) { return e; }
    },
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[address-uk] {
  .fields-holder {
    > p { .mv(24); }
    > .btn-update-field { .mt(16); .inline-flex(); .items-center(); .fs(16); .c(#aeaeae); .-b(#aeaeae);
      &:hover { .c(#aeaeae); .-b(@black-bg); .tr-d(.3); }
      > [font-icon] { .fs(8); .bold(); .mr(4); }
    }
    .between > div:nth-of-type(2) { .mt(8); }
  }
  @media (@tp-up) {
    .fields-holder {
      .between { .flex(); .space-between();
        > div:nth-of-type(1) {.w(calc(50% - 4px)); }
        > div:nth-of-type(2) { .mt(0); .w(calc(50% - 4px)); }
      }
    }
  }
}
</style>
