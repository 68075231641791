<template>
  <div np-display-device-list>
    <MyDeviceItem v-for="(info, idx) in list" :key="idx" :info="info" @remove="v => $emit('remove', v)" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import MyDeviceItem from '@/views/components/pages/my-page/template/my-device/MyDeviceItem.vue';

export default {
  name: 'DisplayMyDeviceList',
  components: { MyDeviceItem },
  props: {
    // list: /** @type {TrustedDeviceItem[]} */Specific
    list: Specific,
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-display-device-list] {.flex(); .flex-dc(); .gap(8); }
</style>
