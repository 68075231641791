import { siteIds } from '@/constants/base/siteMap.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { zeroToNull2 } from '@/utils/valueUtil';

export default class OnboardingService {
  #services;
  #proxyNpUserApi;
  #myInfo;
  #czechStateList;
  #occupationList;

  constructor(services) {
    this.#services = services;
    this.#proxyNpUserApi = services.proxyNpUserApi;
  }

  /**
   * @id GetPlayerMyInfoRequest
   * @description 플레이어의 개인정보를 반환
   * @param {boolean} reset
   * * @returns {Promise<UserInfo>}
   * */
  async getMyInfo(reset, config) {
    try {
      if (reset || !this.#myInfo) {
        const user = await this.getPlayerUserInfo(config);
        if(user?.error) return user;

        const my = await this.getPlayerMyInfo({ ...config, silent: true });
        if(my?.error) return my;

        const personalInfo = { ...user.PersonalInfo, ...my.PersonalInfo };
        this.#myInfo = Object.assign(user, my);
        this.#myInfo.PersonalInfo = personalInfo;
      }
      return this.#myInfo;
    } catch (e) {
      console.log('error : getMyInfo', e);
      throw e;
    }
  }

  /**
   * @id InfoRequest
   * @param config
   * @returns {Promise<UserInfo>}
   */
  async getPlayerUserInfo(config = null) {
    try {
      return await this.#proxyNpUserApi.proxyGet('/player/info', null, config);
    } catch (e) {
      console.log('error : getPlayerUserInfo', e);
      throw e;
    }
  }

  /**
   * @id GetPlayerMyInfoRequest
   * @param config
   * @returns {Promise<UserInfo>}
   */
  async getPlayerMyInfo(config = null) {
    try {
      return await this.#proxyNpUserApi.proxyGet('/player/myinfo', null, config);
    } catch (e) {
      console.log('error : getPlayerMyInfo', e);
      throw e;
    }
  }

  /**
   * @id GetAccountVerificationTypeOrderListRequest
   * @param {string|number} Restrict - None, Login, Deposit, Withdrawal, MoneyTransfer, GamePlay, FreeGame
   * @returns {Promise<AccountVerificationTypeOrderList>}
   */
  async getOrder(Restrict = 'None') {
    try {
      return (await this.#proxyNpUserApi.proxyGet('/player/login/verification/targetorder/without-token', { Restrict }, { silent: true }));
    } catch (e) {
      console.log('error : getOrder');
      throw e;
    }
  }

  /**
   * @description 이메일 인증 코드 전송
   * @id SendEmailVerificationRequest
   * @param VerificationType
   * @param Email
   * @param UserId
   * @param Language
   * @param Kind
   * @param RecaptchaToken
   * @param RecaptchaSiteKey
   * @param RecaptchaAction
   * @param Challenge
   * @param SecCode
   * @returns {Promise<*|{value: *, key: string}>}
   */
  async sendEmailVerification({ VerificationType, Email, UserId, Language = 'en', Kind, RecaptchaToken, RecaptchaSiteKey, RecaptchaAction, Challenge, SecCode, }) {
    try {
      const r = await this.#proxyNpUserApi.proxyGet('/player/email/verification', { VerificationType, Email: (Email ? Email.trim() : undefined), UserId, Language, Kind, RecaptchaToken, RecaptchaSiteKey, RecaptchaAction, Challenge, SecCode });
      const verificationCode = r?.VerificationCode;
      if (verificationCode && ['local', 'test'].includes(this.#services.store.state.env.env)) alert(verificationCode);
      if (r.error && r.key === apiErrorCode.EMAIL_RESEND_TERM_LIMITED) return { ...r, key: 'limit', value: r.desc.replace(/[^0-9]/g, '') };
      return r;
    } catch (e) {
      console.log('error : sendEmailVerification', e);
      if (e?.code === apiErrorCode.PHONE_RESEND_LIMITED) return { key: 'limit', value: e.body.Description };
      throw e;
    }
  }

  /**
   * @id SendMobileVerificationCodeRequest
   * @param {string} VerificationType
   * @param {number} MessageType
   * @param {string} CountryCode
   * @param {string} MobileNumber
   * @param {string} UserId
   * @param {string} UserName
   * @param {string} RecaptchaToken
   * @param {string} RecaptchaSiteKey
   * @param {string} RecaptchaAction
   * @param {boolean} IsOffline
   * @param {string} SecCode
   * @param {string} Challenge
   * @param {string} Validate
   * @param {string} VerificationType
   * @param {string} LoginMethod
   * @param {string} IsUpdateContactInfo
   * @return {string} VerificationCode
   * */
  async sendMobileVerification({ CountryCode, IsOffline = false, MessageType = 0, MobileNumber, UserId, UserName, RecaptchaToken, RecaptchaSiteKey, RecaptchaAction, Challenge, SecCode, Validate, VerificationType = 'AgeVerification', LoginMethod = 'Mobile', IsUpdateContactInfo = false }) {
    try {
      const r = await this.#proxyNpUserApi.proxyPost('/verification/mobile/signup', { CountryCode, IsOffline, MessageType, MobileNumber, UserId, UserName, RecaptchaToken, RecaptchaSiteKey, RecaptchaAction, Challenge, SecCode, Validate, VerificationType, LoginMethod, IsUpdateContactInfo });
      if (r.error && r.key === apiErrorCode.PHONE_RESEND_LIMITED) return { ...r, key: 'limit', value: r.desc.replace(/[^0-9]/g, '') };
      return r;
    } catch (e) {
      if (e?.code === apiErrorCode.PHONE_RESEND_LIMITED) return { key: 'limit', value: e.body.Description };
      throw e;
    }
  }

  /**
   * @id EditPersonalNameRequest
   * @param {string} FirstName
   * @param {string} LastName
   * @param {string?} MaidenName
   * @param {string?} MiddleName
   * @param {string?} Alias
   * */
  editPersonalName({ FirstName, LastName, MaidenName, MiddleName, Alias }) {
    try {
      if (LastName && !MaidenName) MaidenName = LastName;
      return this.#proxyNpUserApi.proxyPut('/player/login/personal-info/name', { FirstName, LastName, MaidenName, MiddleName, Alias });
    } catch (e) {
      console.log('error : editPersonalName');
      throw e;
    }
  }

  /**
   * @id EditPersonalBirthRequest
   * @param {string?} Gender
   * @param {string?} DateOfBirth
   * @param {string?} CountryOfBirth
   * @param {string?} StateOfBirth
   * @param {string?} CityOfBirth
   * @param {string?} Nationality
   * @param {string?} NationalId
   * @param {string?} MobileCountryCode
   * @param {string?} MobileNumber
   * @param {string?} ContactMobileCountryCode
   * @param {string?} ContactMobileNumber
   * @param {string?} PlaceOfBirth
   * */
  editPersonalBirth({ Gender, DateOfBirth, Nationality, NationalId, MobileCountryCode, MobileNumber, ContactMobileCountryCode, ContactMobileNumber, PlaceOfBirth, CountryOfBirth, CityOfBirth, StateOfBirth }) {
    try {
      if (this.#services.store.state.env.site === siteIds.GGPBE) NationalId = NationalId.replace(/\D/g, '');
      return this.#proxyNpUserApi.proxyPut(`/player/login/personal-info/birth`, { Gender, DateOfBirth, Nationality, NationalId, MobileCountryCode, MobileNumber, ContactMobileCountryCode, ContactMobileNumber, PlaceOfBirth, CountryOfBirth, CityOfBirth, StateOfBirth });
    } catch (e) {
      console.log('error : editPersonalBirth');
      throw e;
    }
  }
  /**
   * 플레이어 개인정보 주소 수정
   * @id EditPersonalAddressRequest
   * @param {string} Address
   * @param {string} Building
   * @param {string} City
   * @param {string} PostalCode
   * @param {string} State
   * @param {string} StreetName
   * @param {string} StreetNumber
   * @param {string} HouseNumber
   * @param {string} District
   * @param {boolean?} IsAgreeAccurateInfo
   * @param {boolean} IsPreRegister
   * @returns {*}
   */
  async editPersonalAddress({ Address, Building, City, PostalCode, State, StreetName, StreetNumber, HouseNumber, District, IsAgreeAccurateInfo = false, IsPreRegister }) {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/login/personal-info/address', { Address, Building, City, PostalCode, State, StreetName, StreetNumber, HouseNumber, District, IsAgreeAccurateInfo, IsPreRegister });
    } catch (e) {
      console.log('error : editPersonalAddress');
      throw e;
    }
  }

  /**
   * @id EditPersonalExtraAddressRequest
   * @param {string[]} ExtraAddresses
   * @param {string} DataBoxNumber
   * @returns {*}
   */
  async editPersonalExtraAddress({ ExtraAddresses, DataBoxNumber }) {
    let payload = {};
    if (Array.isArray(ExtraAddresses)) {
      payload = ExtraAddresses.map(addresses => Object.keys(addresses).reduce((acc, cur) => {
          acc[cur] = addresses[cur] || '';
          return acc;
        }, {}));
    } else payload = ExtraAddresses;
    try {
      return this.#proxyNpUserApi.proxyPut('/player/login/personal-info/extra-address', { ExtraAddresses: payload, DataBoxNumber });
    } catch (e) {
      console.log('error : editPersonalExtraAddress');
      throw e;
    }
  }

  /**
   * @id EditPersonalOccupationRequest
   * @param {string} SourceOfFunds
   * @param {string} EmploymentType
   * @param {string} OccupationType
   * @param {string} EmployerName
   * @param {string} EmployerAddress
   * @param {string} BusinessPhoneNumber
   * @param {boolean} IsPreRegister
   * */
  editPersonalOccupation({ BusinessPhoneNumber, EmployerAddress, EmployerName, SourceOfFunds, EmploymentType, OccupationType = 'None' }) {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/login/personal-info/occupation', { BusinessPhoneNumber, EmployerAddress, EmployerName, SourceOfFunds, EmploymentType, OccupationType });
    } catch (e) {
      console.log('error : editPersonalOccupation');
      throw e;
    }
  }

  /**
   * @id EditPlayerLoginMobileNumberRequest
   * @param {string} VerificationCode
   * @param {string} MobileCountryCode
   * @param {string} MobileNumber
   * @param {boolean} IsSkipMobileVerification
   * */
  editVerifyCode({ IsSkipMobileVerification = false, CountryCode, MobileNumber, VerificationCode }) {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/login/mobile', { IsSkipMobileVerification, MobileCountryCode: CountryCode, MobileNumber, VerificationCode });
    } catch (e) {
      console.log('error : editVerifyCode');
      throw e;
    }
  }

  /**
   * @id VerifyPlayerLoginEmailRequest
   * @param {string} VerificationCode
   * @param {string} MobileCountryCode
   * @param {string} MobileNumber
   * @param {boolean} IsSkipMobileVerification
   * */
  verifyEmailCode({ VerificationCode }) {
    try {
      return this.#proxyNpUserApi.proxyPatch('/player/login/email/verify', { VerificationCode });
    } catch (e) {
      console.log('error : verifyEmailCode');
      throw e;
    }
  }

  /**
   * @id VerifyAgeVerification
   * */
  async verifyAge(config = {}) {
    try {
      return await this.#proxyNpUserApi.proxyPut('/player/verify/age-verification', null, config);
    } catch (e) {
      console.log('error : verifyAge');
      throw e;
    }
  }

  /**
   * Loqate, GoogleMap Api 활용한 검색 주소 정보 목록 반환
   * @id GetAddressSearchRequest
   * @param {string} SiteId - SiteId
   * @param {string?} SearchText - 검색어
   * @param {string?} Country - Country
   * @param {string?} AddressId - 상세 결과를 조회 하기 위한 상세 주소 ID
   * @param {string?} SessionToken - Google Map 활용 시 생성 되는 Token
   * @param {object?} config - axios config
   * @returns {AddressResponse}
   */
  searchAddress({ SiteId, SearchText, Country, AddressId, SessionToken }, config) {
    try {
      return this.#proxyNpUserApi.proxyGet('/address/search', { SiteId, SearchText, Country, AddressId, SessionToken }, config);
    } catch (e) {
      console.log('error : searchAddress');
      throw e;
    }
  }

  /**
   * 선택 상세 정보 반환
   * @id GetAddressDetailRequest
   * @param {string} AddressId - 상세 결과를 조회 하기 위한 상세 주소 ID
   * @param {string} SiteId - SiteId
   * @param {string?} Country - Country
   * @param {string?} SessionToken - Google Map 활용 시 생성 되는 Token
   * @param {object?} config - axios config
   * @returns {AddressDetailResponse}
   */
  searchDetailAddress({ AddressId, SiteId, Country, SessionToken }, config) {
    try {
      return this.#proxyNpUserApi.proxyGet(`/address/detail/`, { AddressId, SiteId, Country, SessionToken }, config);
    } catch (e) {
      console.log('error : searchDetailAddress');
      throw e;
    }
  }

  /**
   * @id EditPersonalSelfDeclarationRequest
   * @param {boolean} IsSelfIdentificationDeclaration - 동의 여부
   * @returns {*}
   */
  updatePersonalSelfDeclaration({ IsSelfIdentificationDeclaration }) {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/login/personal-info/self-declaration', { IsSelfIdentificationDeclaration });
    } catch (e) {
      console.log('error : updatePersonalSelfDeclaration');
      throw e;
    }
  }

  /**
   * @id UpdateFundProtectionAcceptanceStatusRequest
   * @param {string} Status
   * @returns {*}
   */
  updateFundProtectionAcceptanceStatus({ Status }) {
    try {
      Status = 'Accepted'; // [kate] 체크박스에 체크를 해야만 submit 가능 한 구조로 AS-IS도 동일하게 string 값 고정하여 보내도록 되어있음
      return this.#proxyNpUserApi.proxyPatch('/player/fundprotectionacceptance/without-token', { Status });
    } catch (e) {
      console.log('error : UpdateFundProtectionAcceptanceStatus');
      throw e;
    }
  }

  /**
   * @id SetDepositLimitRequest
   * @param {boolean} IsUsedCustom
   * @param {number?} Day1Limit
   * @param {number?} Day7Limit
   * @param {number?} Day30Limit
   * @return {SetDepositLimitResponse}
   */
  updateDepositLimit({ IsUsedCustom, Day1Limit, Day7Limit, Day30Limit }) {
    try {
      return this.#proxyNpUserApi.proxyPost('/player/depositlimit', { IsUsedCustom, Day1Limit: !Day1Limit ? undefined : +Day1Limit, Day7Limit: !Day7Limit ? undefined : +Day7Limit, Day30Limit: !Day30Limit ? undefined : +Day30Limit });
    } catch (e) {
      console.log('error : updateDepositLimit');
      throw e;
    }
  }

  /**
   * @id GetCZStateListInfoRequest
   * */
  async getCzechState() {
    try {
      if (!this.#czechStateList) this.#czechStateList = await this.#proxyNpUserApi.proxyGet('/player/login/czstatelist');
      return this.#czechStateList;
    } catch (e) {
      console.log('error : getCzechState', e);
      throw e;
    }
  }

  /**
   * @id GetAllOccupationTypesRequest
   * @returns {AllOccupationTypesResponse}
   */
  async getAllOccupationType() {
    try {
      if (!this.#occupationList) this.#occupationList = await this.#proxyNpUserApi.proxyGet('/cra/occupation/all');
      return this.#occupationList;
    } catch (e) {
      console.log('error : getAllOccupationType');
      throw e;
    }
  }

  /**
   * @id EditPersonalPepRequest
   * @param DeclarationIsPep
   * @param IsAgreeAccurateInfo
   * @param IsOnBehalfOf3rdParty
   * @returns {*}
   */
  updatePersonalPep({ DeclarationIsPep, IsAgreeAccurateInfo, IsOnBehalfOf3rdParty }) {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/login/personal-info/pep', { DeclarationIsPep, IsAgreeAccurateInfo, IsOnBehalfOf3rdParty });
    } catch (e) {
      console.log('error : updatePersonalPep');
      throw e;
    }
  }

  /**
   * @id SetResponsibleGamingLossLimitConfigRequest
   * @param RequestDailyLossLimit
   * @param RequestWeeklyLossLimit
   * @param RequestMonthlyLossLimit
   * @returns {*}
   */
  updateLossLimit({ RequestDailyLossLimit, RequestWeeklyLossLimit, RequestMonthlyLossLimit }) {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/rg/configuration/losslimit', { RequestDailyLossLimit: zeroToNull2(RequestDailyLossLimit), RequestWeeklyLossLimit: zeroToNull2(RequestWeeklyLossLimit), RequestMonthlyLossLimit: zeroToNull2(RequestMonthlyLossLimit) });
    } catch (e) {
      console.log('error : updateLossLimit');
      throw e;
    }
  }

  /**
   * @id SetResponsibleGamingLoginTimeLimitConfigRequest
   * @param RequestDailyLoginTimeLimit
   * @param RequestWeeklyLoginTimeLimit
   * @param RequestMonthlyLoginTimeLimit
   * @param RequestType
   * @returns {*}
   */
  updateLoginTimeLimit({ RequestDailyLoginTimeLimit, RequestWeeklyLoginTimeLimit, RequestMonthlyLoginTimeLimit, RequestType = 0 }) {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/rg/configuration/logintimelimit', { RequestDailyLoginTimeLimit: zeroToNull2(RequestDailyLoginTimeLimit), RequestWeeklyLoginTimeLimit: zeroToNull2(RequestWeeklyLoginTimeLimit), RequestMonthlyLoginTimeLimit: zeroToNull2(RequestMonthlyLoginTimeLimit), RequestType });
    } catch (e) {
      console.log('error : updateLoginTimeLimit');
      throw e;
    }
  }

  /**
   * @id SetResponsibleGamingBalanceLimitConfigRequest
   * @param RequestBalanceLimit
   * @param RequestType
   * @returns {*}
   */
  updateBalanceLimit({ RequestBalanceLimit, RequestType = 0 }) {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/rg/configuration/balancelimit', { RequestBalanceLimit: zeroToNull2(RequestBalanceLimit), RequestType });
    } catch (e) {
      console.log('error : updateBalanceLimit');
      throw e;
    }
  }

  /**
   * @id SetResponsibleGamingTotalBetLimitConfigRequest
   * @param RequestDailyTotalBetLimit
   * @param RequestWeeklyTotalBetLimit
   * @param RequestMonthlyTotalBetLimit
   * @returns {*}
   */
  updateTotalBetLimit({ RequestDailyTotalBetLimit, RequestWeeklyTotalBetLimit, RequestMonthlyTotalBetLimit }) {
    try {
      if (this.#services.store.state.env.site === siteIds.GGPCZ) RequestWeeklyTotalBetLimit = RequestMonthlyTotalBetLimit;
      return this.#proxyNpUserApi.proxyPut('/player/rg/configuration/totalbetlimit', { RequestDailyTotalBetLimit: zeroToNull2(RequestDailyTotalBetLimit), RequestWeeklyTotalBetLimit: zeroToNull2(RequestWeeklyTotalBetLimit), RequestMonthlyTotalBetLimit: zeroToNull2(RequestMonthlyTotalBetLimit) });
    } catch (e) {
      console.log('error : updateTotalBetLimit');
      throw e;
    }
  }

  /**
   * SetResponsibleGamingReLoginTimeLimitConfigRequest
   * @param RequestReLoginTimeLimit
   * @returns {*}
   */
  updateReLoginTimeLimit({ RequestReLoginTimeLimit }) {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/rg/configuration/relogintimelimit', { RequestReLoginTimeLimit });
    } catch (e) {
      console.log('error : updateReLoginTimeLimit');
      throw e;
    }
  }

  /**
   * @id SetResponsibleGamingMonthlyLoginFrequencyConfigRequest
   * @param RequestMonthlyLoginFrequency
   * @returns {*}
   */
  updateRgMonthlyLoginFrequency({ RequestMonthlyLoginFrequency }) {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/rg/configuration/monthlyloginfrequency', { RequestMonthlyLoginFrequency });
    } catch (e) {
      console.log('error : updateRgMonthlyLoginFrequency',);
      throw e;
    }
  }

  /**
   * @id EditPersonalReaffirmRequest
   * @param Description - 수정사항 없을 경우 'SameAsBefore', 수정사항 있을 경우 진행한 스텝 + ', ' 을 join하여 전달
   * @returns {*}
   */
  updatePersonalReaffirm({ Description }) {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/login/personal-info/reaffirm', { Description });
    } catch (e) {
      console.log('error : updatePersonalReaffirm');
      throw e;
    }
  }

  /**
   * @description ReCheck Personal Detail 정보 저장
   * @id EditPersonalBeforeSofortRetryRequest
   * @param FirstName
   * @param LastName
   * @param DateOfBirth
   * @param City
   * @param PostalCode
   * @param State
   * @param StreetName
   * @param HouseNumber
   * @param Address
   * @returns {*}
   */
  updatePersonalReCheck({ FirstName, LastName, DateOfBirth, City, PostalCode, State, StreetName, HouseNumber, Address }) {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/login/personal-info/sofort', { FirstName, LastName, DateOfBirth, City, PostalCode, State, StreetName, HouseNumber, Address });
    } catch (e) {
      console.log('error : updatePersonalReaffirm');
      throw e;
    }
  }

  /**
   * @description DepositLimit 화면 접근 시 Limit 설정 없이 완료 처리 요청(GGPDE)
   * @id SetResponsibleGamingDepositLimitDisplayedAtRequest
   * @returns {*}
   */
  updateDepositLimitDisplayedAt() {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/rg/configuration/depositlimit/displayedat', null, { silent: true });
    } catch (e) {
      console.log('error : updateDepositLimitDisplayedAt');
      throw e;
    }
  }

  /**
   * @id UpdateHighRiskMandatoryRequest
   * @param Nationality
   * @param EmploymentType
   * @param OccupationType
   * @param ExpectedMonthlyDepositGrade
   * @param AnnualEarningGrade
   * @returns {*}
   */
  updateUpdateHighRiskMandatory({ Nationality, EmploymentType, OccupationType, ExpectedMonthlyDepositGrade, AnnualEarningGrade }) {
    try {
      return this.#proxyNpUserApi.proxyPut('/player/high-risk-mandatory', { Nationality, EmploymentType, OccupationType, ExpectedMonthlyDepositGrade, AnnualEarningGrade });
    } catch (e) {
      console.log('error : updateDepositLimitDisplayedAt');
      throw e;
    }
  }

  /**
   * @description Player Citizenship 업로드 정보 중 해당 Status 인 Data 조회
   * @id GetPlayerCitizenshipListRequest
   * @param Status
   * @returns {PlayerCitizenshipDocumentsResponse}
   */
  getCitizenshipDocumentList({ Status }) {
    try {
      return this.#proxyNpUserApi.proxyGet('/player/citizenship/list', { Status });
    } catch (e) {
      console.log('error : getCitizenshipDocumentList');
      throw e;
    }
  }

  /**
   * @description Citizenship 문서 업로드
   * @id AddPlayerCitizenshipDocumentRequest
   * @param Citizenship
   * @param FileData
   * @param FileName
   * @returns {*}
   */
  async setAddCitizenshipDocument({ Citizenship, FileData, FileName }) {
    try {
      return this.#proxyNpUserApi.proxyPost('/player/citizenship/documents', { Citizenship, FileData, FileName });
    } catch (e) {
      console.log('error : setAddCitizenshipDocument', e);
      return e;
    }
  }

  /**
   * kyc callback 시 정상 callback 되었다는 증빙을 위한 임시 api
   * @returns {Promise<*>}
   */
  async setCheckKycCallback() {
    try {
      return await this.#proxyNpUserApi.proxyGet('/notification/status', {}, { silent: true });
    } catch (e) {
      console.log('error : getNotification', e);
      throw e;
    }
  }

  /**
   * @description Timeout으로 인해 Lock Balance가 된 경우 Unlock Balance 처리
   * @id SetReleaseLockBalanceReturnFromTimeoutRequest
   * @returns {Promise<*>}
   */
  async setReleaseLockBalanceReturnFromTimeout() {
    try {
      return await this.#proxyNpUserApi.proxyPatch('/player/rg/release/lockbalance', {}, { silent: true });
    } catch (e) {
      console.log('error : setReleaseLockBalanceReturnFromTimeout', e);
      throw e;
    }
  }
}
