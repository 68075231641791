<template>
  <div contents-container v-if="info" class="promotion-gray-box">
    <div class="contents-header" v-if="!noHeader">
      <div class="info">
        <h3>{{ name }}</h3>
        <div>
          <StatusBadge v-if="showStatus" :status="status" />
          <PromotionButton v-if="showActiveButton" button-name="promotion.activeBonus" @click="active" />
        </div>
      </div>
      <div class="date">
        <p v-if="period" class="period">{{ period }}</p>
        <p v-if="leftDays" class="left-days">{{ $t('_.daysLeft', { days: leftDays }) }}</p>
        <p v-if="currentDay" class="time">
          <span>{{ $t('_.nextDayStart', { day: currentDay + 1 }) }}</span>
          <PromotionTimer :time="remainTime" @update="$emit('update')" />
        </p>
      </div>
    </div>
    <div class="contents-body">
      <slot />
    </div>
  </div>
</template>

<script>
import StatusBadge from '@/views/components/pages/promotion/StatusBadge.vue';
import PromotionTimer from '@/views/components/pages/promotion/PromotionTimer.vue';
import { toFullDate, toMilliseconds } from '@/utils/dateTimeUtil';
import Specific from '@shared/types/Specific';
import PromotionPagination from '@/views/components/pages/promotion/PromotionPagination.vue';
import PromotionButton from '@/views/components/pages/promotion/PromotionButton.vue';
import { isEmpty } from 'lodash';

export default {
  name: 'ContentsContainer',
  lexicon: 'promotion',
  components: { PromotionButton, PromotionPagination, PromotionTimer, StatusBadge },
  props: {
    info: Specific,
    noHeader: { type: Boolean, default: false },
    pagination: { type: Boolean, default: false },
    index: { type: Number | String, default: 0 },
  },
  computed: {
    promotionType() {
      return this.$route?.name;
    },
    name() {
      return this.info?.name || this.info?.promotion?.name;
    },
    status() {
      const statusList = {
        OPTED_IN_PAUSED: 'PAUSED',
        OPTED_OUT: 'PAUSED',
      };

      let status;
      switch (this.promotionType) {
        case 'DailyFreebie':
          status = this.info?.attendeeStatus;
          break;
        case 'FreeBonus':
          status = this.info?.attendee?.status;
          break;
        default:
          status = this.info?.status;
      }

      return (status && statusList[status]) || status;
    },
    currentDay() {
      return this.info?.currentDay;
    },
    challenges() {
      return this.info?.pkg?.prizes?.filter(o => o?.freeBonusPrizeType === 'CHALLENGE');
    },
    period() {
      switch (this.promotionType) {
        case 'FreeBonus':
          if (isEmpty(this.challenges)) return '';
          const startedDate = this.challenges[0]?.prizeAttendee?.startedAt;
          const lastIndex = this.challenges.length < 1 ? 0 : this.challenges.length - 1;
          const finishedDate = this.challenges[lastIndex]?.prizeAttendee?.finishedAt;
          if (!startedDate || !finishedDate) return '';
          return `${toFullDate(startedDate)} - ${toFullDate(finishedDate)}`;
        case 'MatchBonus':
          return `${toFullDate(this.info?.startedAt)} - ${toFullDate(this.info?.finishedAt)}`;
        default:
          return null;
      }
    },
    remainTime() {
      return toMilliseconds(this.info?.timeUntilNextDay);
    },
    page() {
      return Number(this.index) + 1;
    },
    leftDays() {
      return this.info?.daysUntilFinished;
    },
    showStatus() {
      return this.$route?.name === 'MatchBonus' || this.status === 'PAUSED';
    },
    showActiveButton() {
      return this.$route?.name === 'MatchBonus' && this.info.status === 'PENDING';
    },
  },
  methods: {
    async active() {
      const id = this.info?.dripBonusId;
      if (!id) return;
      const r = await this.$services.promotion.activateDripBonus(id);
      if (r && !r.error) this.$emit('update');
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="less">
@import "@/less/tpm";
[contents-container] {
  section + section {
    .mt(20); .pt(20); .-t(@c-gray6);
  }
  .contents-header { .flex(); .flex-dc(); .row-gap(16); .-b(@c-bd); .p(20, 20);
    > div { .flex(); .flex-dc(); .gap(4px 24px); .wf(); }
    .info { .space-between(); .lh(28);
      h3 { .fs(22); .lh(inherit); .c(@c-white); }
      > div { .flex(); .gap(8); .justify-end(); .items-center(); }
      [promotion-button] { .ib(); .fs(12); }

    }
    .period { .c(#b5b5b5); }
    .left-days { .c(@c-white); .nowrap(); }
    .time { flex: 1; .flex(); .justify-end(); .items-center(); .column-gap(8); .flex-wrap();
      span { .nowrap(); }
    }
  }
  .contents-body { .p(20, 24); }

  @media (@ml-up) {
    .contents-header {
      > div { .flex-dr(); }
    }
  }
  @media (@tp-up) {
    .contents-header { .ph(24); }
  }
}
</style>
