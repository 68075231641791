<template>
  <div verify-age-de>
    <div v-if="!retry">
      <p class="desc" v-html="`${$t('_.verifyAge.ggpde.description1')}<br/>${$t('_.verifyAge.ggpde.description2')}`" />
      <ol>
        <li v-html="$t('_.verifyAge.ggpde.safeRegistration')" />
        <li v-html="$t('_.verifyAge.ggpde.checkPersonalDetails')" />
        <li v-html="$t('_.verifyAge.ggpde.oasisCheck')" />
        <li v-html="$t('_.verifyAge.ggpde.politicallyExposed')" />
        <li v-html="$t('_.verifyAge.ggpde.completed')" />
      </ol>
      <FontIcon name="auto-refresh" />
    </div>
    <div v-else>
      <p class="desc" v-html="`${$t('_.personalDetailCheck.ggpde.description1')}<br/>${$t('_.personalDetailCheck.ggpde.description2')}`" />
      <ProgressButton button-name="onBoarding.reCheckPersonal" @click="replace" />
    </div>
  </div>
</template>

<script>
import { close } from '@/utils/clientUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import baseTemplate from '@/mixins/baseTemplate';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'VerifyAgeDe',
  lexicon: 'onBoarding',
  components: { FontIcon, ProgressButton },
  mixins: [baseTemplate],
  data() {
    return {
      error: false,
      retry: false,
    };
  },
  watch: {
    'item.title': function () { if (!this.retry) this.item.title = 'onBoarding.title.identifying'; },
  },
  computed: {
    env: state('env', 'env'),
    csEmail: state('env', 'csEmail'),
    isLocal() {
      return this.env === 'local';
    },
  },
  methods: {
    /**
     * 컴포넌트 내에서의 api request 처리
     * @returns {Promise<*|null>}
     */
    async apiRequest() {
      const r = await this.getApiResponse();
      if(!r) return false;
      if (r?.error) {
        if (!(await this.apiErrorHandler(r))) return;

        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, { csEmail: this.csEmail });
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : onboarding.verifyAge`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }
      }
      return true;
    },
    /**
     *
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse() {
      const config = { silent: true };
      return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding.verifyAge(config);
    },
    async apiErrorHandler(r) {
      switch (r?.key) {
        case apiErrorCode.OASIS_BAN_EXIST:
        case apiErrorCode.ACCOUNT_SUSPENDED:
        case apiErrorCode.GAMSTOP_SELF_EXCLUDED:
        case apiErrorCode.FAILED_SELF_EXCLUSION_CHECK:
          this.$onboarding.errorHandler(r, true);
          break;
        case apiErrorCode.KYC_UPLOAD_REQUIRED:
          this.replace();
          break;
        case apiErrorCode.AISG_FAILED_SELF_EXCLUSION_CHECK:
          close(this);
          break;
        case apiErrorCode.USER_INFO_NOT_VERIFIED_AND_RETRY:
          this.retry = true;
          if (this.item) this.item.title = 'onBoarding.title.personalDetailCheck';
          break;
        case apiErrorCode.INTERNAL_ERROR:
        case apiErrorCode.USER_INFO_NOT_VERIFIED:
          break;
        case apiErrorCode.USER_INFO_NOT_VERIFIED_AND_PASS:
        default:
          return false;
      }

      return true;
    },
    replace() {
      this.modelInfo.replace = true;
    }
  },
  async mounted() {
    if (this.item) this.item.title = 'onBoarding.title.identifying';
    await this.apiRequest();
    if (!this.structure.error) this.modelInfo.replace = true;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[verify-age-de] {
  p.desc { .c(white); .regular(); }
  ol {.mt(24); .flex-column(); .flex-ai(start); .gap(8); .fs(20);
    li { .rel(); .pl(40); .regular();
      &:before { .cnt(); .wh(6); .br(50%); .bgc(white); .abs(); .lt(24, 50%); .t-yc();}
    }
  }
  > div {
    [font-icon] { .block(); .mt(40); .tc(); .fs(48); animation: rotate-circle 2s linear infinite; }
  }
}
</style>
