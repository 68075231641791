const view = path => () => import(/* webpackChunkName: "myPage" */ `../views/pages/my-page/${path}.vue`);
import { routeNames } from '@/constants/model/my-page';
import siteModel from '@/constants/model/my-page';

export default ({ local }) => {
  const path = local ? 'myinfo/' : 'app/';

  const pages = [
    {
      path: 'account',
      name: 'MyAccount',
      component: view('MyInfoProgress'),
    },
    {
      path: 'np-mobile-number',
      name: routeNames.NpMobileNumber,
      component: view('MyInfoProgress'),
      display: true,
    },
    {
      path: 'np-email-address',
      name: routeNames.NpEmailAddress,
      component: view('MyInfoProgress'),
      display: true,
    },
    {
      path: 'np-verify-code',
      name: routeNames.NpVerifyCode,
      component: view('MyInfoProgress'),
      display: false,
    },
    {
      path: 'np-social-login',
      name: routeNames.NpSocialAccounts,
      component: view('MyInfoProgress'),
      display: true,
    },
    {
      path: 'np-personal-info',
      name: routeNames.NpPersonalInfo,
      component: view('MyInfoProgress'),
      display: true,
    },
    {
      path: 'np-change-password',
      name: routeNames.NpChangeLoginPassword,
      component: view('MyInfoProgress'),
      display: true,
    },
    {
      path: 'np-fund-password',
      name: routeNames.NpFundPassword,
      component: view('MyInfoProgress'),
      display: true,
    },
    {
      path: 'np-authentication',
      name: routeNames.NpLoginSecurity,
      component: view('MyInfoProgress'),
      display: true,
    },
    {
      path: 'np-my-documents',
      name: routeNames.NpMyDocuments,
      component: view('MyInfoProgress'),
      display: true,
    },
    {
      path: 'np-contact-mobile',
      name: routeNames.NpContactMobileNumber,
      component: view('MyInfoProgress'),
      display: false,
      isGGpassOnly: true,
    },
    {
      path: 'np-contact-email',
      name: routeNames.NpContactEmailAddress,
      component: view('MyInfoProgress'),
      display: false,
      isGGpassOnly: true,
    },
    {
      path: 'np-mfa-mobile',
      name: routeNames.NpMfaMobileNumber,
      component: view('MyInfoProgress'),
      display: false,
    },
    {
      path: 'np-mfa-email',
      name: routeNames.NpMfaEmailAddress,
      component: view('MyInfoProgress'),
      display: false,
    },

    {
      path: 'np-my-accounts',
      name: routeNames.NpMyAccounts,
      component: view('MyInfoProgress'),
      display: true,
      isGGpassOnly: true,
    },
    {
      path: 'np-my-device',
      name: routeNames.NpMyDevice,
      component: view('MyInfoProgress'),
      display: true,
    },
    /** 6월 제외 */
    // {
    //   path: 'np-balance-transfer',
    //   name: routeNames.NpBalanceTransfer,
    //   component: view('MyInfoProgress'),
    //   display: false,
    //   isGGpassOnly: true,
    // },
    // {
    //   path: 'np-transfer-history',
    //   name: routeNames.NpTransferHistory,
    //   component: view('MyInfoProgress'),
    //   display: false,
    //   isGGpassOnly: true,
    // },

    { path: '*', redirect: '/not-found' }
  ];

  const siteName = process.env.VUE_APP_SITE;
  const routeModel = siteModel();
  const enablePages = Object.keys(routeModel).filter(name => {
    const site = routeModel[name];
    // Component 가 있는 것만 체크
    if (!site.hasOwnProperty('component')) return false;
    if (site.hasOwnProperty('enableSite')) {
      return site.enableSite.includes(siteName);
    }

    if (site.hasOwnProperty('disableSite')) {
      return !site.disableSite.includes(siteName);
    }

    return true;
  });
  const list = [
    {
      path: `${path}`,
      name: 'MyPage',
      component: view('MyPage'),
      redirect: `${path}info`,
      children: [
        {
          path: 'info',
          name: 'MyInfo',
          component: view('MyInfo'),
        },
        ...pages.filter(page => enablePages.includes(page.name))
      ]
    },

    // {
    //   path: `${path}/temp/handler`,
    //   name: 'MyInfoHandler',
    //   component: view('MyInfoHandler'),
    // },
  ];

  // const list = [
  //     {
  //       path: `${path}`,
  //       name: 'MyPage',
  //       component: view('MyPage'),
  //       redirect: token ? `${path}info` : '/auth-error',
  //       children: [
  //         {
  //           path: 'info',
  //           name: 'MyInfo',
  //           component: view('MyInfo'),
  //         },
  //         ...pages.filter(page => enablePages.includes(page.name) && !page.isGGpassOnly)
  //       ]
  //     },
  //   ];
  return list;
}