<template>
  <div recheck-address>
    <SearchLoqateAddress v-if="isSearch" :useGuide2="false" @reset="resetSearchAddress" @selected="selectedAddress" />
    <template v-else>
      <div class="fields-holder">
        <a class="btn-update-field" @click="resetSearchAddress(true)">
          <FontIcon name="chevron-left" />
          <span>{{ $t('personalDetails.personalAddressAddressReset') }}</span>
        </a>
        <div class="view-address-group">
          <div class="between">
            <ValidationComponent :label="$t('personalDetails.postalCode')" name="_postalCode" :component="TrimTextInput" :rules="postalRules" :preset="postalPreset" :value="modelInfo.PostalCode" :error-msg="postalCodeErrorMsg" />
            <ValidationComponent :label="$t('personalDetails.stateProvince')" name="_stateProvince" :component="TrimTextInput" :rules="stateRules" :preset="statePreset" :value="modelInfo.State" :error-msg="stateErrorMsg" />
          </div>
          <ValidationComponent :label="$t('personalDetails.city')" name="_city" :component="TrimTextInput" :rules="cityRules" :preset="cityPreset" :value="modelInfo.City" :error-msg="cityErrorMsg" />
          <ValidationComponent :label="$t('personalDetails.loqateAddress')" name="_loqateAddress" :component="TrimTextInput" :rules="addressRules" :preset="addressPreset" :value="modelInfo.StreetName" :error-msg="addressErrorMsg" />
          <ValidationComponent :label="`${$t('personalDetails.additionalAddress')} ${$t('personalDetails.optional')}`" name="_additionalAddress" :component="TrimTextInput" :rules="additionalAddressRules" :preset="additionalAddressPreset" v-model="modelInfo.Address" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import SearchLoqateAddress from '@/views/components/pages/on-boarding/template/address/SearchLoqateAddress.vue';
import baseTemplate from '@/mixins/baseTemplate';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import Specific from '@shared/types/Specific';
export default {
  name: 'RecheckAddress',
  components: { SearchLoqateAddress, FontIcon, ValidationComponent },
  mixins: [baseTemplate],
  props: {
    error: Specific,
  },
  data() {
    return {
      TrimTextInput,
      isSearch: false,
      searchAddressValue: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
    postalRules() { return 'required'; },
    cityRules() { return 'required'; },
    stateRules() { return 'required'; },
    addressRules() { return 'required'; },
    additionalAddressRules() { return `address:0,50,${this.modelInfo.CountryWritingSystem}`; },

    postalPreset() { return { disable: true }; },
    cityPreset() { return { disable: true }; },
    statePreset() { return { disable: true }; },
    addressPreset() { return { disable: true }; },
    additionalAddressPreset() { return { maxLength: 50 }; },

    postalCodeErrorMsg() {
      return this.error?.postalCodeErrorMsg;
    },
    stateErrorMsg() {
      return this.error?.stateErrorMsg;
    },
    cityErrorMsg() {
      return this.error?.cityErrorMsg;
    },
    addressErrorMsg() {
      return this.error?.addressErrorMsg;
    }
  },
  watch: {
    'isSearch': async function (v) {
      if (!v) {
        await this.setDetailAddress();
      }
      this.$emit('searching', v);
    }
  },

  methods: {
    /**
     * 검색 초기화
     * @param {Boolean} v - 검색 초기화 여부
     */
    resetSearchAddress(v) {
      this.isSearch = v;
    },

    /**
     * SearchLoqateAddress 에서 선택 결과 반환
     * @param {object} v - 선택 결과
     */
    selectedAddress(v) {
      this.searchAddressValue = v;
    },
    /**
     * Address property 에 저장 할 loqateAddress 정보 설정
     * @param street
     * @param buildingName
     * @param buildingNumber
     * @returns {*}
     */
    setLoqateAddress(street, buildingName, buildingNumber) {
      const arrLoqateAddress = [street, buildingName, buildingNumber];
      return arrLoqateAddress.reduce((a, b) => (a && b ? `${a}, ${b}` : a || b));
    },

    /**
     * selectedInfo 값 유무에 따라 상세 주소 조회 또는 ModelInfo를 기반으로 정보 설정
     * @returns {Promise<void>}
     */
    async setDetailAddress() {
      /* WSOP 관련 기획 및 지라
       * https://ggnetwork.atlassian.net/browse/GGBB-3043
       * https://ggnetwork.atlassian.net/wiki/spaces/AG/pages/840826893/Personal+Details+Input
       * https://ggnetwork.atlassian.net/wiki/spaces/P2/pages/1682440636/NP+Frontend+-+User+API#1.4-EditPersonalAddressRequest
       */
      if (!this.searchAddressValue) {
        const loqateAddress = this.setLoqateAddress(this.modelInfo.StreetName, this.modelInfo.Building, this.modelInfo.HouseNumber);
        this.modelInfo.LoqateAddress = loqateAddress;
      } else {
        const { selectedItem, sessionToken } = this.searchAddressValue;
        const params = { SiteId: this.site, Country: this.userInfo?.Country, AddressId: selectedItem.Id, SessionToken: sessionToken };
        // const r = await this.apiRequest('searchDetailAddress', params);
        const r = await this.$services.onboarding.searchDetailAddress(params);

        const loqateAddress = this.setLoqateAddress(r.Street, r.BuildingName, r.BuildingNumber);
        const setProperties = [{ name: 'LoqateAddress', value: loqateAddress }, { name: 'StreetName', value: r.Street }, { name: 'Building', value: r.BuildingName }, { name: 'HouseNumber', value: r.BuildingNumber }];

        Object.keys(r).forEach(o => { this.modelInfo[o] = r[o]; });
        setProperties.forEach(p => { this.modelInfo[p.name] = p.value; });
      }
    },
  },
  mounted() {
    this.isSearch = !this.modelInfo.PostalCode;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
</style>