<template>
  <MfaModalTemplate display-qr-code>
    <template #title>
      {{ $t('_.googleAuthenticationStep2') }}
    </template>
    <template #content>
      <div class="img-group">
        <div class="img-area">
          <img :src="googleInfo.QRCodeImageUrl" alt="qr-code" />
        </div>
      </div>
      <CopyButton :copy-text="googleInfo.ManualEntryKey" />
    </template>
    <template #description>
      {{ $t('_.googleStep2Desc') }}
    </template>
    <!--    <template #footer>-->
    <!--      <GpButton type="line-green" @click="prev">{{ $t('_.prev') }}</GpButton>-->
    <!--      <GpButton type="submit-green" @click="next">{{ $t('_.next') }}</GpButton>-->
    <!--    </template>-->
  </MfaModalTemplate>
</template>

<script>
import MfaModalTemplate from '@/views/components/pages/my-page/modal/components/MfaModalTemplate.vue';
import Specific from '@shared/types/Specific';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import CopyButton from '@/views/components/common/CopyButton.vue';

export default {
  name: 'DisplayQRCode',
  lexicon: 'myInfo',
  components: { CopyButton, FontIcon, GpButton, MfaModalTemplate },
  props: {
    options: Specific,
    googleInfo: Specific,
  },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    prev() {
      this.$emit('prev');
    },
    next() {
      this.$emit('next');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[display-qr-code] { .flex(); .justify-center(); .flex-dc(); .gap(10);
  .img-group { .flex(); .flex-dr(); .justify-center(); .gap(20);
    .img-area { .wh(160); .mv(8); .bgc(#f8f8f8); .flex(); .flex-dc(); .justify-center(); .items-center();
      > img { .f(); }
      > p:nth-of-type(2) { .c(black); }
    }
  }
  @media(@ml-up){
    .img-group{
      .img-area{.wh(186);}
    }
  }
}
</style>
