<template>
  <div id="app" :class="[matchedMedia, touch ? 'touch' : 'no-touch', theme, { promotion: isPromotion }, {'RTL': isRtl}]">
    <div class="contents-wrapper">
      <div :class="['contents-holder',isTracking && 'tracking']">
        <router-view v-if="ready"/>
      </div>
    </div>
    <FloatingDebugButton v-if="isExcludedProduction" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils';
import { isExcludedProduction, setSiteInfo } from '@/utils/baseUtil';
import { sleep } from '@shared/utils/commonUtils';
import { getSiteId } from '@/constants/base/signup/signUpSiteMap';
import { parseQuery } from '@shared/utils/urlUtils.mjs';
import FloatingDebugButton from '@/views/components/common/FloatingDebugButton.vue';
import { siteIds } from '@/constants/base/siteMap';
import { onDimmed } from '@/utils/clientUtil';

export default {
  name: 'App',
  components: { FloatingDebugButton },
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    theme() { return this.$store.state.env.theme; },
    touch() { return this.$store.state.browser.touch; },
    matchedMedia() { return this.$store.state.browser.matchedMedia; },
    title: state('env', 'title'),
    logo: state('env', 'logo'),
    favicon: state('env', 'favicon'),
    isTracking: state('env', 'isTracking'),
    brandName: state('env', 'brandName'),
    queries: state('env', 'queries'),
    host: state('env', 'host'),
    npOnly: state('env', 'npOnly'),
    site: state('env', 'site'),
    gap: state('env', 'gap'),
    baseLang: state('env', 'baseLang'),
    app: state('query', 'app'),
    token: state('user', 'token'),
    isPromotion() {
      return this.app === 'promotion';
    },
    isExcludedProduction() {
      return isExcludedProduction();
    },
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { vmid: 'favicon', content: this.favicon },
        { vmid: 'title', content: this.title },
        { vmid: 'description', content: this.title },
        { vmid: 'keywords', content: `${this.app}, ${this.brandName}` },
        { vmid: 'image', content: this.logo },
      ],
    };
  },
  methods: {},
  async mounted() {
    console.log(`%c${this.app} ${process.env.NODE_ENV} version : `, 'font-weight:bold;', process.env.VUE_APP_VERSION);
    if (this.baseLang !== this.lang) this.$changeLocale(this.baseLang);

    if (['onboarding', 'myinfo', 'rg', 'promotion'].includes(this.app) && this.$route.name !== 'AuthError') {
      const query = parseQuery(window.location.search);
      if (await this.$services.token.validateNpToken()) {
        // 1. user 정보 조회
        const r = await this.$services.onboarding.getMyInfo(true, { timeout: 10000 });
        if (r?.error) {
          const { desc, CustomerErrorParameters, errorTemplate } = r;
          if (errorTemplate?.path) {
            const queries = errorTemplate.queries || {};
            const values = { path: r.errorTemplate.path, param: { desc, errorTemplate, ci: errorTemplate.ci, cci: errorTemplate.cci }, query: { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined, ...queries, desc } };

            await this.replaceRouteName(values.path, { locale: query.locale, ...values.param }, values.query);
          }
        } else this.$store.commit('user/setUserInfo', r);

        // 2. user의 할당 site 정보 조회
        await setSiteInfo(window.location.pathname, window.location.host, query, this.$store, getSiteId((r?.SiteId || '')));

        const q = {...this.$route.query };
        delete q.token;

        await this.replaceRouteName(this.$route.name, this.$route.params, q);
      } else await this.replaceRouteName('AuthError');
    }

    await sleep(100);
    this.ready = true;

    document.dispatchEvent(new Event('render-event'));
    if(location.host.includes('2ace') && this.$route.name !== 'RestrictionNotice') return await this.replaceRouteName('RestrictionNotice');

    // GGPNL의 경우 onboarding, responsible-gaming, my-info의 경우 Dimmed 처리
    const dimmedSitesInfo = {
      [siteIds.GGPNL]: ['onboarding', 'rg', 'myinfo'],
    };
    if (Array.isArray(dimmedSitesInfo[this.site]) && dimmedSitesInfo[this.site].includes(this.app)) await onDimmed(this);
  },
};
</script>

<style lang="less">
@import "~@/less/common.less";
#app { word-break: keep-all; word-wrap: break-word; -webkit-overflow-scrolling: touch; font-size: inherit; .bgc(#171717);
  .btn-holder { .rel() ; .pt(20); .mb(40);
    .error { .abs(); .lt(50%, -10); .t-xc(); .c(@c-red;) }
  }
  .contents-wrapper { .c(white); .crop(); .min-h(var(--innerHeight));
    .contents-holder { .p(40, 28, 0); .-box(); .tl(); .max-w(600); .mh-c(); }
  }

  @media (@tl-up) {
    .contents-wrapper {
      .contents-holder { .ph(0);
        &.tracking { .max-w(1000);}
      }
    }
  }
  &.white { .bgc(white);
    .contents-wrapper {
      .contents-holder { .max-w(none); .p(0); }
    }
  }
}
</style>
