<template>
  <ValidationProvider ref="refObserver" :mode="mode" validation-component :immediate="immediate" :name="name" :rules="rules" v-slot="{errors}" :slim="true" v-click-outside="focusOut">
    <div :class="['holder','input-field',{'error': (useEmpty || hasValue) && (hasErrorMsg || errors.length) },{'focus': isFocus, 'disabled': preset.disable }]">
      <p class="label">
        <s v-if="showCurrencySymbol">{{ currencySymbol }}</s>
        <label :class="{'mandatory': isMandatory, 'label-color': labelColorGray}">{{ label }}</label>
      </p>
      <div class="field" @click="click" @focus="setFocus" @blur="focusOut" :tabindex="tabindex">
        <component ref="component" class="component" :theme="theme" :is="component"
                   :props="() => preset" :list="preset.list" :telephone="preset.telephone" :phone="preset.phone" :digit="preset.digit" :is-trim="preset.isTrim" :max-length="preset.maxLength" :wordonly="preset.wordonly" :number="preset.number"
                  :type="preset.type" :inputMode="preset.inputMode" :pattern="preset.pattern" :disabled="preset.disable" :placeholder="preset.placeholder" :auto="preset.auto" :autoOpen="preset.autoOpen"
                  :size="preset.size" :select-only="preset.selectOnly" :input-placeholder="preset.inputPlaceholder" :search-value="preset.searchValue" :search-handler="preset.searchHandler" :show-no-result="preset.showNoResult"
                  v-model="model" @input="updateValue" @update="v => $emit('update',v)" @updateSearch="v => $emit('updateSearch',v)" @focus="setFocus" @blur="focusOut"  @delete="$emit('delete')"/>
      </div>
    </div>
    <ValidationErrors class="error-msg" v-if="(isValid || useEmptyValidation) && (errorMsg || (rules && !['requiredNone', 'minMaxNone', 'requiredSelectNone'].some(rule => rules.includes(rule))) && errors && errors.length)" :errors="errors" :errorMsg="errorMsg" />
  </ValidationProvider>
</template>

<script>
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils';
import { sleep } from '@shared/utils/commonUtils.mjs';

export default {
  name: 'ValidationComponent',
  props: {
    value: Specific,
    component: { type: Specific, required: true },
    name: { type: String, required: true },
    rules: { type: String, default: null },
    preset: { type: Specific, default: () => ({}) },
    label: { type: String, default: null },
    errorMsg: { type: String, default: null },
    labelColorGray: { type: Boolean, default: true },
    autoFocus: { type: Boolean, default: true },
    showCurrencySymbol: { type: Boolean, default: false },
    useEmptyValidation: { type: Boolean, default: false },
    theme: { type: String, default: 'dark transparent' },
    useEmpty: {type: Boolean, default: false},
    tabindex: { type: String, default: '-1'},
    immediate: {type: Boolean, default: false},
    mode: {type: String, default: 'aggressive'}
  },
  data() {
    return {
      model: null,
      isFocus: false,
    };
  },
  computed: {
    currencySymbol: state('user', 'currencySymbol'),
    isValid() {
      if (this.model === null || this.model === undefined) return false;
      if (Array.isArray(this.model) && this.model.length === 0) return false;
      if (this.model === false) return false;
      return !!String(this.model).trim().length;
    },
    isMandatory() {
      return this.label && this.rules && (this.rules.includes('required') || this.rules.includes('length:8,20'));
    },
    hasErrorMsg() {
      return Boolean(this.errorMsg?.length);
    },
    hasValue() {
      return this.model?.value || this.model?.length;
    },
  },
  watch: {
    value: 'updateModel',
    errorMsg() {
      if (this.autoFocus && this.hasErrorMsg) this.$el.querySelector('input')?.focus();
    },
  },
  methods: {
    click(e) {
      this.isFocus = true;
      if(e.target.children?.length)Array.prototype.forEach.call(e.target.children, o => {
        const name = o.attributes?.[0]?.name;
        if(name === 'search-drop-select') o.click();
        else if(name === 'text-input') o.querySelector('input').focus();
        // else if(o.className.includes('text-field')) o.focus();
      });
    },
    clear() {
      this.$refs.refObserver.reset();
    },
    updateModel() {
      this.model = this.value;
    },
    updateValue(v) {
      this.model = v;
      this.$emit('input', v);
    },
    setFocus() {
      if(this.isFocus) return;
      this.isFocus = true;
      this.$emit('focus');
    },
    focusOut() {
      if(!this.isFocus) return;
      this.isFocus = false;
      this.$emit('blur');
      this.$emit('input', this.model);
    },
  },
  mounted() {
    this.updateModel();
  }
};
</script>

<style lang="less">
@import '@/less/proj.less';
[validation-component] { .pointer();
  .holder { .wh(100%, 68); .rel();
    >.label { .abs(); .lt(0,4); .z(1); pointer-events: none;
      >s { .abs(); .lt(12,28); .fs(20);}
      > label { pointer-events: none; .pl(12); .fs(13); .pt(4); .tl(); .ib(); .rel(); .mb(2); .h(24); .vat();
        &.mandatory:after { content: '*'; .c(@c-red); .ml(4);}
        &.label-color { .c(#AFAFAF); }
      }
    }
    >.field { .h(100%); .rel(); // .z(2); [celina] z-index로 인해 온보딩 스텝 진행불가하여 주석 처리했습니다.
      >* { .h(100%); .pt(28); }
    }
    &.error { .-a(@c-red, 2);
      > label { .c(@c-red); }
    }
    &.focus, &:focus { .-a(@c-yellow, 2); .bgc(rgba(39, 39, 39, 0.2)) !important;}
    &.disabled { .bgc(@c-w01); box-shadow: none; cursor: not-allowed; pointer-events: none;
      input, [radio-item] { .c(#7c7576); }
      [search-drop-select] {
        label, [font-icon] { .c(#7c7576); }
      }
    }
  }
}
</style>
