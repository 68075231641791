<template>
  <div verify-rg-deposit-setting name="WSOPca_Step22_Deposit_Limit_View" class="responsible-deposit-limit-view">
    <LimitUnitField check-empty :label="monthlyLabel" :name="monthlyName" :rules="getRule(30)" :max-length="9" :limit="cashierLimit.Day30Limit" :value="monthlyValue" :custom-error="customError" :error-msg="day30ErrorMsg" @input="(v)=> update('Day30Limit',v)" :required="isGGPNL" />
    <LimitUnitField check-empty :label="weeklyLabel" :name="weeklyName" :rules="getRule(7)" :max-length="9" :limit="cashierLimit.Day7Limit" :value="weeklyValue" :error-msg="day7ErrorMsg" @input="(v) => update('Day7Limit',v)" />
    <LimitUnitField check-empty :label="dailyLabel" :name="dailyName" :rules="getRule(1)" :max-length="9" :limit="cashierLimit.Day1Limit" :value="dailyValue" :error-msg="day1ErrorMsg" @input="(v) => update('Day1Limit', v)" />
    <p class="list-star" v-html="$t(description)" />
    <p class="error-msg" v-if="otherErrorMsg">{{ otherErrorMsg }}</p>
  </div>
</template>

<script>
import LimitUnitField from '@/views/components/pages/on-boarding/template/extra/LimitUnitField.vue';
import baseTemplate from '@/mixins/baseTemplate';
import TextInput from '@shared/components/common/input/TextInput.vue';
import { siteIds } from '@/constants/base/siteMap.mjs';
import { makePrice } from '@shared/utils/currencyUtils.mjs';
import { state } from '@shared/utils/storeUtils.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'VerifyRgDepositSetting',
  lexicon: 'onBoarding.setting',
  components: { LimitUnitField },
  mixins: [baseTemplate],
  data() {
    return {
      initLimit: { Day1Limit: null, Day7Limit: null, Day30Limit: null },
      depositLimit: { Day1Limit: null, Day7Limit: null, Day30Limit: null },
      regulationDepositLimit: { Day1Limit: null, Day7Limit: null, Day30Limit: null },
      cashierLimit: { Day1Limit: null, Day7Limit: null, Day30Limit: null },
      depositLimitType: 'Rolling',
      isEnabledLugas: false,
      isLoading: true,
      TextInput,
    };
  },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    currencySymbol: state('user', 'currencySymbol'),
    dailyLabel() {
      return this.isRolling ? this.$t('_.24hours') : this.$t('_.dailyLimit');
    },
    dailyName() {
      return this.isRolling ? '24hours' : '_dayLimit';
    },
    dailyValue() {
      return this.depositLimit.Day1Limit;
    },
    weeklyLabel() {
      return this.isRolling ? this.$t('_.7Days') : this.$t('_.weeklyLimit');
    },
    weeklyName() {
      return this.isRolling ? '7Days' : '_weeklyLimit';
    },
    weeklyValue() {
      return this.depositLimit.Day7Limit;
    },
    monthlyLabel() {
      return this.isRolling ? this.$t('_.30Days') : this.$t('_.monthlyLimit');
    },
    monthlyName() {
      return this.isRolling ? '30Days' : '_monthlyLimit';
    },
    monthlyValue() {
      return this.depositLimit.Day30Limit;
    },
    isRolling() {
      return this.depositLimitType === 'Rolling';
    },
    isGGPDE() {
      return this.site === siteIds.GGPDE;
    },
    isGGPNL() {
      return this.site === siteIds.GGPNL;
    },
    isGGPUK() {
      return this.site === siteIds.GGPUK;
    },
    initEmpty() {
      return this.isGGPNL || this.isGGPUK || this.isGGPDE;
    },
    description() {
      return this.isGGPDE ? '_.deposit.de-description' : '_.deposit.description';
    },
    day1ErrorMsg() {
      let msg;
      if ([apiErrorCode.DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY1, apiErrorCode.DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY7, apiErrorCode.DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY30].includes(this.errorMsg)) {
        const params = this.structure.errorParams;
        msg = params?.[0] === 'Daily' ? this.$t(this.errorMsg, { currencyAmount: `${this.currencySymbol}${makePrice(params?.[1])}` }) : '';
      }
      return msg;
    },
    day7ErrorMsg() {
      let msg;
      if ([apiErrorCode.DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY1, apiErrorCode.DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY7, apiErrorCode.DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY30].includes(this.errorMsg)) {
        const params = this.structure.errorParams;
        msg = params?.[0] === 'Weekly' ? this.$t(this.errorMsg, { currencyAmount: `${this.currencySymbol}${makePrice(params?.[1])}` }) : '';
      }
      return msg;
    },
    day30ErrorMsg() {
      let msg;
      if ([apiErrorCode.DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY1, apiErrorCode.DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY7, apiErrorCode.DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY30].includes(this.errorMsg)) {
        const params = this.structure.errorParams;
        msg = params?.[0] === 'Monthly' ? this.$t(this.errorMsg, { currencyAmount: `${this.currencySymbol}${makePrice(params?.[1])}` }) : '';
      }
      return msg;
    },
    otherErrorMsg() {
      return ![apiErrorCode.DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY1, apiErrorCode.DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY7, apiErrorCode.DEPOSIT_LIMIT_BIGGER_THAN_CURRENT_DAY30].includes(this.errorMsg) ? this.$t(this.errorMsg) : '';
    },
    buttonName() {
      return this.structure.list.length === 1 ? 'submit' : 'next';
    },
    disabledButton() {
      return false;
    },
    customError() {
      return this.isGGPNL ? (this.weeklyValue || this.dailyValue) && !this.monthlyValue : false;
    },
    nlWeeklyMax() {
      const nlRegulationMax = this.regulationDepositLimit.Day30Limit;
      if (this.monthlyValue) return this.monthlyValue > nlRegulationMax ? nlRegulationMax : this.monthlyValue;
      return nlRegulationMax;
    },
    nlDailyMax() {
      const nlRegulationMax = this.regulationDepositLimit.Day30Limit;
      if (this.weeklyValue) return this.weeklyValue > nlRegulationMax ? nlRegulationMax : this.monthlyValue ? Math.min(this.weeklyValue, this.monthlyValue, nlRegulationMax) : this.weeklyValue;
      if (this.monthlyValue) return this.monthlyValue > nlRegulationMax ? nlRegulationMax : this.monthlyValue;
      return nlRegulationMax;
    },
  },
  methods: {
    makePrice,
    getNLRule(day) {
      const nlRegulationMin = 1;
      const nlRegulationMax = this.regulationDepositLimit[`Day${day}Limit`];
      const rule = `minimumDepositLimit:${this.currencySymbol},${nlRegulationMin}`;
      const getNLExceedRule = () => {
        switch (day) {
          case 30:
            return `|maximumDepositLimit:${this.currencySymbol},${nlRegulationMin},${nlRegulationMax}`;
          case 7:
            if (this.nlWeeklyMax) return `|maximumDepositLimit:${this.currencySymbol},${nlRegulationMin},${this.nlWeeklyMax}`;
          case 1:
            if (this.nlDailyMax) return `|maximumDepositLimit:${this.currencySymbol},${nlRegulationMin},${this.nlDailyMax}`;
          default:
            return '';
        }
      };
      const nlExceedRule = getNLExceedRule();
      return (day === 30 ? 'requiredNone|' : '') + rule + nlExceedRule;
    },
    getRule(day) {
      const limit = this.cashierLimit[`Day${day}Limit`];
      const isEmptyRequestLimit = !this.modelInfo.Day30Limit && !this.modelInfo.Day7Limit && !this.modelInfo.Day1Limit;
      const requiredRule = isEmptyRequestLimit ? 'requiredNone|' : '';
      const publicRule = limit ? `${requiredRule}depositLimit:${this.currencySymbol},${limit},${makePrice(limit)}` : '';
      const deRule = limit ? `depositLimitDE:${this.currencySymbol},${this.csEmail},${limit},${makePrice(limit)}` : '';

      const ruleList = {
        [siteIds.GGPNL]: this.getNLRule(day), // GGPNL의 경우 cashierLimit을 무시하고  regulationLimit 으로만 최대값을 제어
        [siteIds.GGPDE]: deRule,
        default: publicRule,
      };

      return ruleList[this.site] || ruleList.default;
    },
    async initialize() {
      this.isEnabledLugas = [siteIds.GGPDE].includes(this.site);
      if (this.isEnabledLugas) { // 해당 페이지에 접근했는지 여부를 저장하여 이후 온보딩 재시작시에 해당 단계를 Skip 할 수 있도록 처리
        if (this.item) this.item.title = 'onBoarding.title.localDepositLimit';
        await this.$services.onboarding.updateDepositLimitDisplayedAt();
      }

      this.depositLimitType = (/** @type {{DepositLimitPeriodType : string}} */ await this.$services.account.getRgConfig()).DepositLimitPeriodType;
      const { Day1DefaultDepositLimit: Day1Limit, Day7DefaultDepositLimit: Day7Limit, Day30DefaultDepositLimit: Day30Limit } = await this.$services.account.getDefaultDepositLimit();

      // cashier limit 값이 있을 경우는 특정 사이트를 제외하고 값을 할당
      if (Day1Limit && Day7Limit && Day30Limit) {
        this.initLimit = { Day1Limit, Day7Limit, Day30Limit };
        this.cashierLimit = { Day1Limit, Day7Limit, Day30Limit };
        if (!this.initEmpty) this.depositLimit = { Day1Limit, Day7Limit, Day30Limit };
      }

      if (this.site === siteIds.GGPNL) {
        const r = await this.$services.account.getDepositLimit();
        if (!r && r.error) return;
        const { RegulationMonthlyDepositLimit, RegulationWeeklyDepositLimit, RegulationDailyDepositLimit } = r;
        this.regulationDepositLimit.Day30Limit = RegulationMonthlyDepositLimit;
        this.regulationDepositLimit.Day7Limit = RegulationWeeklyDepositLimit;
        this.regulationDepositLimit.Day1Limit = RegulationDailyDepositLimit;
        this.modelInfo.IsUsedCustom = true;
      } else {
        this.modelInfo.IsUsedCustom = false;
      }

      // lugas 를 사용하는 사이트(GGPDE)일 경우는 lugas의 depositLimit 값을 저장하며 isUserdCustom 값을 초기값과 같더라도 무조건 true로 설정
      if (this.isEnabledLugas) {
        const { CurrentMonthlyDepositLimit, CurrentWeeklyDepositLimit, CurrentDailyDepositLimit } = await this.$services.account.getDepositLimit();
        this.depositLimit.Day30Limit = CurrentMonthlyDepositLimit;
        this.depositLimit.Day7Limit = CurrentWeeklyDepositLimit;
        this.depositLimit.Day1Limit = CurrentDailyDepositLimit;
        this.modelInfo.IsUsedCustom = true;
      } else {
        this.modelInfo.IsUsedCustom = false;
      }

      this.isLoading = false;
    },
    update(p, v) {
      if (p) this.depositLimit[p] = v;
      this.modelInfo.IsUsedCustom = this.initLimit.Day1Limit !== this.depositLimit.Day1Limit || this.initLimit.Day7Limit !== this.depositLimit.Day7Limit || this.initLimit.Day30Limit !== this.depositLimit.Day30Limit;
      this.modelInfo[p] = v;
    },
  },
  async mounted() {
    this.$services?.modal.block(); // 다단 api 호출 딤드 처리 방지를 위해 보정
    await this.initialize();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-rg-deposit-setting] {
  > p.error-msg { .mt(15); }
  [limit-unit-field] {
    label { flex-basis: 30%; .min-w(98); white-space: normal; }
    label + div { flex-basis: 70%; }
  }
}
</style>
