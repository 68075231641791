<template>
  <div request-mandatory>
    <div class="header-holder">
      <p class="desc" v-html="$t('_.description')" />
    </div>
    <div class="content-holder">
      <p v-html="`1. ${$t('_.nationality')}`" />
      <div class="content">
        <ValidationComponent :label="$t('onBoarding.nationality')" name="_nationality" :component="SearchDropSelect" :rules="nationalityRules" :preset="nationalityPreset" :value="nationality" @input="setNationality" :useEmptyValidation="!!nationalityErrorMsg" :errorMsg="nationalityErrorMsg" />
      </div>
    </div>
    <div class="content-holder">
      <p v-html="`2. ${$t('_.currentEmploymentStatus')}`" />
      <div class="content">
        <ValidationComponent v-if="employmentStatusList" :label="$t('onBoarding.employmentStatus')" name="_employmentStatus" :component="SearchDropSelect" :rules="employmentStatusRules" :preset="employmentStatusPreset" :value="employmentStatus" @input="setEmploymentStatus" :useEmptyValidation="!!employmentStatusErrorMsg" :errorMsg="employmentStatusErrorMsg" />
        <ValidationComponent v-if="showOccupation" :label="$t('onBoarding.occupation')" name="_occupation" :component="SearchDropSelect" :rules="occupationRules" :preset="occupationPreset" :value="occupation" @input="setOccupation" :useEmptyValidation="!!occupationErrorMsg" :error-msg="occupationErrorMsg" />
      </div>
    </div>
    <div class="content-holder">
      <p v-html="`3. ${$t('_.annualEarning')}`" />
      <div class="content">
        <div class="flex">
          <label>{{ balance.LocalCurrency }}</label>
          <ValidationComponent name="_annualEarning" :component="SearchDropSelect" :rules="annualEarningRules" :preset="annualEarningPreset" :value="annualEarning" @input="setAnnualEarning" :useEmptyValidation="!!annualEarningErrorMsg" :errorMsg="annualEarningErrorMsg" />
        </div>
      </div>
    </div>
    <div class="content-holder">
      <p v-html="`4. ${$t('_.monthlyDeposit')}`" />
      <div class="content">
        <div class="flex">
          <label>{{ balance.LocalCurrency }}</label>
          <ValidationComponent name="_monthlyDeposit" :component="SearchDropSelect" :rules="monthlyDepositRules" :preset="monthlyDepositPreset" :value="monthlyDeposit" @input="setMonthlyDeposit" :useEmptyValidation="!!monthlyDepositErrorMsg" :errorMsg="monthlyDepositErrorMsg" />
        </div>
      </div>
    </div>
    <div class="content-holder">
      <div class="content">
        <ValidationProvider rules="requiredReaffirm">
          <CheckboxItem theme="dark" v-model="reaffirm" @change="changeReaffirm">{{ $t('_.reaffirm') }}</CheckboxItem>
        </ValidationProvider>
        <p v-if="isShowReaffirm" v-html="$t('_.invalidReaffirm')" />
      </div>
    </div>
    <ProgressButton button-name="submit" :disabled="!reaffirm || !isValid" @click="submit" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils';
import { sleep } from '@shared/utils/commonUtils.mjs';
import {redirectCompleteUrl} from "@/utils/baseUtil";
import {autoLogin, close, isClient} from "@/utils/clientUtil";
import baseTemplate from '@/mixins/baseTemplate';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import { kycEmploymentDocuments } from '@/constants/base/onboarding/kyc';

export default {
  name: 'RequestMandatory',
  lexicon: 'onBoarding.requestMandatory',
  components: {ProgressButton, CheckboxItem, ValidationComponent },
  mixins: [baseTemplate],
  data() {
    return {
      TrimTextInput,
      SearchDropSelect,
      nationality: null,
      nationalityList: null,
      nationalityErrorMsg: null,
      employmentStatus: null,
      employmentStatusList: null,
      employmentStatusErrorMsg: null,
      occupation: null,
      occupationList: null,
      occupationErrorMsg: null,
      annualEarning: null,
      annualEarningList: null,
      annualEarningErrorMsg: null,
      monthlyDeposit: null,
      monthlyDepositList: null,
      monthlyDepositErrorMsg: null,
      reaffirm: true,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
    balance: state('user', 'balance'),
    nationalityPreset() {
      return { list: this.nationalityList, placeholder: this.$t('select') };
    },
    employmentStatusPreset() {
      return { list: this.employmentStatusList, selectOnly: true, placeholder: this.$t('_.employmentStatusPlaceHolder') };
    },
    occupationPreset() {
      return { list: this.occupationList, placeholder: this.$t('_.occupationPlaceHolder'), disable: !this.showOccupation };
    },
    annualEarningPreset() {
      return { list: this.annualEarningList, selectOnly: true, placeholder: this.$t('select') };
    },
    monthlyDepositPreset() {
      return { list: this.monthlyDepositList, selectOnly: true, placeholder: this.$t('select') };
    },
    nationalityRules() {
      return 'requiredNationality';
    },
    employmentStatusRules() {
      return 'requiredEmploymentStatus';
    },
    occupationRules() {
      return 'requiredOccupation';
    },
    annualEarningRules() {
      return 'requiredAnnualEarning';
    },
    monthlyDepositRules() {
      return 'requiredMonthlyDeposit';
    },
    isValid() {
      return !this.nationalityErrorMsg && !this.employmentStatusErrorMsg && !this.occupationErrorMsg && !this.annualEarningErrorMsg && !this.monthlyDepositErrorMsg;
    },
    showOccupation() {
      return ['Employed', 'SelfEmployed'].includes(this.employmentStatus?.value);
    },
    isShowReaffirm() {
      return this.reaffirm === false ? true : this.reaffirm ? false : false;
    }
  },
  methods: {
    setNationality(item) {
      this.nationality = item;
      this.modelInfo.Nationality = item?.value;
      this.setNationalityErrorMsg(this.modelInfo.Nationality);
    },
    setNationalityErrorMsg(value) {
      if (!value) this.nationalityErrorMsg = this.$tv({ _rule_: 'requiredNationality' });
      else this.nationalityErrorMsg = null;
    },
    setEmploymentStatus(item) {
      this.employmentStatus = item;
      this.modelInfo.EmploymentType = item?.value;
      this.modelInfo.EmployerName = null;

      if (!this.showOccupation) {
        const type = 'NotEmployed';
        this.occupation = this.occupationList.find(occupation => occupation.value === type);
        this.modelInfo.OccupationType = type;
      } else {
        if (this.modelInfo.OccupationType === 'NotEmployed') {
          this.occupation = null;
          this.modelInfo.OccupationType = null;
        }
      }

      this.setEmploymentStatusErrorMsg(this.modelInfo.EmploymentType);
      this.setOccupationErrorMsg(this.modelInfo.OccupationType);
    },
    setEmploymentStatusErrorMsg(value) {
      if (!value) this.employmentStatusErrorMsg = this.$tv({ _rule_: 'requiredEmploymentStatus' });
      else this.employmentStatusErrorMsg = null;
    },
    setOccupation(item) {
      this.occupation = item;
      this.modelInfo.OccupationType = item?.value;
      this.setOccupationErrorMsg(this.modelInfo.OccupationType);
    },
    setOccupationErrorMsg(value) {
      if (!value) this.occupationErrorMsg = this.$tv({ _rule_: 'requiredOccupation' });
      else this.occupationErrorMsg = null;
    },
    setAnnualEarning(item) {
      this.annualEarning = item;
      this.modelInfo.AnnualEarningGrade = item?.value === 'None' ? null : item?.value;
      this.setAnnualEarningErrorMsg(this.modelInfo.AnnualEarningGrade);
    },
    setAnnualEarningErrorMsg(value) {
      if (!value) this.annualEarningErrorMsg = this.$tv({ _rule_: 'requiredAnnualEarning' });
      else this.annualEarningErrorMsg = null;
    },
    setMonthlyDeposit(item) {
      if (!item) return;
      this.monthlyDeposit = item;
      this.modelInfo.ExpectedMonthlyDepositGrade = item?.value === 'None' ? null : item?.value;
      this.setMonthlyDepositErrorMsg(this.modelInfo.ExpectedMonthlyDepositGrade);
    },
    setMonthlyDepositErrorMsg(value) {
      if (!value) this.monthlyDepositErrorMsg = this.$tv({ _rule_: 'requiredMonthlyDeposit' });
      else this.monthlyDepositErrorMsg = null;
    },
    async getNationality() {
      const r = await this.$services.base.getAllCountries();
      this.nationalityList = r.List.map(o => ({ label: this.$t(`country.${o.CountryCode}`), value: o.CountryCode, ...o }));
    },
    async getOccupationType() {
      let r;
      try {
        r = await this.$services.onboarding.getAllOccupationType();
      } catch (e) {
        r = e;
      }

      if (r.error) {
        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, { csEmail: this.csEmail });
        this.structure.errorDesc = r.desc;
      }

      return r;
    },
    async getAnnualEarningGrades() {
      const r = await this.$services.base.getAnnualEarningGrades();
      this.annualEarningList = r.KeyNames.map(o => ({ label: o.Name, value: o.Key }));
    },
    async getExpectedMonthlyDepositGrades() {
      const r = await this.$services.base.getExpectedMonthlyDepositGrades();
      this.monthlyDepositList = r.KeyNames.map(o => ({ label: o.Name, value: o.Key }));
    },
    changeReaffirm(value) {
      this.reaffirm = value;
    },
    async submit() {
      this.setNationalityErrorMsg(this.modelInfo.Nationality);
      this.setEmploymentStatusErrorMsg(this.modelInfo.EmploymentType);
      this.setOccupationErrorMsg(this.modelInfo.OccupationType);
      this.setAnnualEarningErrorMsg(this.modelInfo.AnnualEarningGrade === 'None' ? null : this.modelInfo.AnnualEarningGrade);
      this.setMonthlyDepositErrorMsg(this.modelInfo.ExpectedMonthlyDepositGrade === 'None' ? null : this.modelInfo.ExpectedMonthlyDepositGrade);

      if (this.isValid) {
        const r = await this.$services.onboarding.updateUpdateHighRiskMandatory(this.modelInfo);
        if (!r.error) {
          if (!await redirectCompleteUrl(this)) {
            if (isClient()) await autoLogin(this);
            else await close(this);
          }
        } else {
          this.$onboarding.errorHandler(r, true);
        }
      }
    },
    async initialize() {
      await this.getNationality();

      const r = await this.getOccupationType();
      const allOccupation = r.OccupationTypes;

      this.employmentStatusList = kycEmploymentDocuments.map(doc => ({ label: this.$t(doc.label), value: doc.value }));
      this.occupationList = allOccupation?.filter(type => type !== 'None').map(type => ({ label: this.$t(`occupation.${type}`), value: type }));

      await this.getAnnualEarningGrades();
      await this.getExpectedMonthlyDepositGrades();

      await sleep(10);

      if (this.modelInfo?.Nationality) this.nationality = this.nationalityList.find(v => v.value === this.modelInfo.Nationality);
      if (this.modelInfo?.EmploymentType) this.employmentStatus = this.employmentStatusList.find(v => v.value === this.modelInfo.EmploymentType);
      if (this.modelInfo?.OccupationType) this.occupation = this.occupationList.find(v => v.value === this.modelInfo.OccupationType);
      if (this.modelInfo?.AnnualEarningGrade) this.annualEarning = this.annualEarningList.find(v => v.value === this.modelInfo.AnnualEarningGrade);
      if (this.modelInfo?.ExpectedMonthlyDepositGrade) this.monthlyDeposit = this.monthlyDepositList.find(v => v.value === this.modelInfo.ExpectedMonthlyDepositGrade);
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[request-mandatory] {
  .header-holder {
    .desc {
      > a { .block(); .c(@c-link); }
    }
  }
  .content-holder { .pt(20); .pb(16);
    .disabled { .c(@c-w03); }
    .content { .pt(12);
      .flex { .flex(); .flex-ai(baseline); .space-between();
        > [validation-component] { .w(90%); }
      }
      > p { .fs(14); .mt(20); box-shadow: 0 6px 12px rgba(0,0,0,0.175); .p(6); .br(4); .bgc(#262626); .-a(#333); .c(#d8d8d8); }
      [checkbox-item] em { white-space: normal; }
    }
  }
  .content-holder + .content-holder { .-t(#333); }
}
</style>
