import { apiErrorCode } from '@/constants/base/apiErrorCode';
const errorList = (key, t) => ({
  // First Name, Last Name input box 아래에 표기, 텍스트: Please enter {{FIELDNAME}}, 번역키: Personal.FieldName.Empty
  [apiErrorCode.INVALID_FIRST_NAME]: { errorMsg: apiErrorCode.INVALID_FIRST_NAME },

  // First Name, Last Name input box 아래에 표기, 텍스트: Please enter {{FIELDNAME}}, 번역키: Personal.FieldName.Empty
  [apiErrorCode.INVALID_LAST_NAME]: { errorMsg: apiErrorCode.INVALID_LAST_NAME },

  // Date of Birth 입력 필드 아래에 표기, 텍스트: Please enter a valid date of birth, 번역키: Personal.Invalid.DateOfBirth
  // this.structure.errorMsg = this.$t('validation.rules.dateOfBirth');
  [apiErrorCode.INVALID_DOB]: { errorMsg: apiErrorCode.INVALID_DOB },

  // Date of Birth 입력 필드 아래에 표기, 텍스트: You must be at least 18 years old, 번역키: PersonalInformation.Invalid.Too.Young
  [apiErrorCode.INVALID_AGE]: { errorMsg: apiErrorCode.INVALID_UNDERAGE },

  // Submit 버튼 위에 표기, 텍스트: Please enter {{FIELDNAME}}, 번역키: Personal.FieldName.Empty
  [apiErrorCode.INVALID_STATE]: { stateErrorMsg: key, submitErrorMsg: apiErrorCode.INVALID_STATE, params: { fieldName: t('personalDetails.stateProvince') } },

  // Submit 버튼 위에 표기, 텍스트: Please enter {{FIELDNAME}}, 번역키: Personal.FieldName.Empty
  [apiErrorCode.INVALID_CITY]: { cityErrorMsg: key, submitErrorMsg: apiErrorCode.INVALID_STATE, params: { fieldName: t('personalDetails.city') } },

  // Submit 버튼 위에 표기, 텍스트: {{FIELDNAME}} value should be under {{LENGTH}} characters long., 번역키: Personal.Invalid.FieldName.Length,
  [apiErrorCode.INVALID_CITY_LENGTH]: { cityErrorMsg: key, submitErrorMsg: apiErrorCode.INVALID_CITY_LENGTH, params: { fieldName: t('personalDetails.city'), length: 50 } },

  // Submit 버튼 위에 표기, 텍스트: Only use alphabets, numbers and common punctuation characters, 번역키: Personal.Invalid.City.Character
  [apiErrorCode.INVALID_CITY_CHARACTER]: { cityErrorMsg: key, submitErrorMsg: apiErrorCode.INVALID_CITY_CHARACTER },

  // Submit 버튼 위에 표기, 텍스트: Please enter {{FIELDNAME}}, 번역키: Personal.FieldName.Empty
  [apiErrorCode.INVALID_POSTAL_CODE]: { postalCodeErrorMsg: key, submitErrorMsg: apiErrorCode.INVALID_POSTAL_CODE, params: { fieldName: t('personalDetails.postalCode') } },

  // Submit 버튼 위에 표기, 텍스트: {{FIELDNAME}} value should be under {{LENGTH}} characters long., 번역키: Personal.Invalid.FieldName.Length
  [apiErrorCode.INVALID_POSTAL_CODE_LENGTH]: { postalCodeErrorMsg: key, submitErrorMsg: apiErrorCode.INVALID_POSTAL_CODE, params: { fieldName: t('personalDetails.postalCode'), length: 20, } },

  // Submit 버튼 위에 표기, 텍스트: Only use alphabets, numbers and common punctuation characters, 번역키: Personal.Invalid.PostalCode.Character
  [apiErrorCode.INVALID_POSTAL_CODE_CHARACTER]: { postalCodeErrorMsg: key, submitErrorMsg: apiErrorCode.INVALID_CITY_CHARACTER },

  // Submit 버튼 위에 표기, 텍스트: Please enter {{FIELDNAME}}, 번역키: Personal.FieldName.Empty
  [apiErrorCode.INVALID_ADDRESS]: { addressErrorMsg: key, submitErrorMsg: apiErrorCode.INVALID_STATE, params: { fieldName: t('personalDetails.address') } },

  // Submit 버튼 위에 표기, 텍스트: {{FIELDNAME}} value should be under {{LENGTH}} characters long., 번역키: Personal.Invalid.FieldName.Length
  [apiErrorCode.INVALID_ADDRESS_LENGTH]: { addressErrorMsg: key, submitErrorMsg: apiErrorCode.INVALID_ADDRESS_LENGTH, params: { fieldName: t('personalDetails.address'), length: 120, } },

  // Submit 버튼 위에 표기, 텍스트: Only use alphabets, numbers and common punctuation characters, 번역키: Personal.Invalid.Address.Character
  [apiErrorCode.INVALID_ADDRESS_CHARACTER]: { addressErrorMsg: key, submitErrorMsg: apiErrorCode.INVALID_CITY_CHARACTER },

  // Submit 버튼 위에 표기, 텍스트: Invalid Street Name, 번역키: Personal.Invalid.StreetName
  [apiErrorCode.INVALID_STREET_NAME]: { streetErrorMsg: key, submitErrorMsg: apiErrorCode.INVALID_STATE, params: { fieldName: t('personalDetails.street') } },

  // Submit 버튼 위에 표기, 텍스트: Invalid {{FIELDNAME}}, 번역키: Personal.Invalid.FieldName
  [apiErrorCode.INVALID_HOUSE_NUMBER]: { houseNumberErrorMsg: key, submitErrorMsg: apiErrorCode.INVALID_HOUSE_NUMBER, params: { fieldName: t('houseNumber') } },

  // Multi Account Error 화면으로 이동
  // todo : Re-Check Personal Details Title 변경 하여 Verification Failed 페이지 이동
  [apiErrorCode.USER_INFO_ALREADY_EXIST]: { errorMsg: apiErrorCode.USER_INFO_ALREADY_EXIST },
});

export default (key, t) => {
  const list = errorList(key, t);
  const error = Object.keys(list).find(o => o === key);
  if (error) {
    return list[error] || { submitErrorMsg: t(key) };
  }
  return error || { submitErrorMsg: t(key) };
}