import { render, staticRenderFns } from "./PhSourceOfFunds.vue?vue&type=template&id=1bdbc9bb"
import script from "./PhSourceOfFunds.vue?vue&type=script&lang=js"
export * from "./PhSourceOfFunds.vue?vue&type=script&lang=js"
import style0 from "./PhSourceOfFunds.vue?vue&type=style&index=0&id=1bdbc9bb&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports