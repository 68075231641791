<template>
  <BaseModal np-logout-devices-modal :show-title="false">
    <template #content>
      <div class="modal-body">
        <FontIcon v-if="isResult" :name="icon" :class="['icon',{'success' : isSuccess}]" />
        <div class="content">
          <p v-html="message" />
        </div>
      </div>
    </template>
    <template #footer>
      <div v-if="!isResult" class="modal-footer">
        <GpButton type="line-green" @click="close">{{ $t('cancel') }}</GpButton>
        <GpButton type="submit-green" @click="logout" :progress="progress">{{ $t('logout') }}</GpButton>
      </div>
      <GpButton v-else type="submit-green" @click="close">{{ $t('ok') }}</GpButton>
    </template>
  </BaseModal>
</template>

<script>

import BaseModal from '@/views/components/gg-pass/modal/BaseModal.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { state } from '@shared/utils/storeUtils.mjs';
export default {
  name: 'LogoutDevicesModal',
  components: { GpButton, GpDescription, BaseModal, FontIcon },
  props: {
    options: {
      deviceIdList: [],
    }
  },
  data() {
    return {
      title: null,
      message: null,
      success: true,
      step: 'progress',
      progress: false,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
    isSuccess() {
      return this.success;
    },
    icon() {
      return this.isSuccess ? 'check-over-circle-regular' : 'exclamation-circle-regular';
    },
    isResult() {
      return this.step === 'result';
    },
    isProgress() {
      return this.step === 'progress';
    },
  },
  methods: {
    close() {
      this.$modalCloseAll();
    },
    async logout() {
      this.progress = true;
      this.$services.modal.block();
      const r = await this.$services.user.logoutAllDevices();
      if (r?.error) {
        this.success = false;
        this.message = this.$t(apiErrorCode.INVALID_REQUEST, { csEmail: this.csEmail });
      } else {
        this.success = true;
        this.message = this.$t('myInfo.myDevices.successMessage');
      }

      this.step = 'result';
      this.progress = false;
      this.$services.modal.unblock();
    }
  },
  mounted() {
    this.message = this.$t('myInfo.myDevices.modalDescription');
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-logout-devices-modal] {
  > .panel { .rel(); overflow: visible;
    .scroll-area {.h(calc(100% - 59px)); .flex(); .flex-dc(); overflow-y: visible;
      > .body { flex: 1; }
    }
  }
  .modal-body {.flex(); .flex-dc(); .gap(10); .justify-center(); .items-center();
    .icon {.fs(30); .c(@c-red);
      &.success {.c(@gp-green);}
    }
    .content { .ph(20); .w(100%);
      > p {.pt(0); .pb(20); text-align: center; .fs(14); .c(#000);}
    }
  }
  .modal-footer {.flex(); .gap(10);}
}
</style>
