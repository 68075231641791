<template>
  <div kyc-ready-de v-if="isReady">
    <template v-if="!showTink && !showSofort && !showJumio">
      <div temporarily-error>
        <FontIcon name="exclamation-triangle"/>
        <p>{{ $t('_.tinkTemporarilyErrorDescription') }}</p>
        <ProgressButton button-name="refresh" @click="initThirdParty" />
      </div>
    </template>
    <template v-else>
      <p>{{ $t('_.guide') }}</p>
      <template v-if="showTink">
        <a @click="goTink" :class="{disabled: tStatus === tinkStatus.UrlFailed}">
          <template v-if="tStatus === tinkStatus.UrlFailed">
            <FontIcon name="exclamation-triangle-regular" />
            <div>
              <ColorButton type="blue" @click="getTinkUrl">
                <FontIcon name="rotate-right" />
                {{ $t('_.tinkRefrash') }}
              </ColorButton>
              <p class="desc">{{ $t('_.tinkUrlError') }}</p>
            </div>
          </template>
          <template v-else>
            <FontIcon class="bank-account-card" name="bank-account-card-regular" />
            <div>
              <h4>{{ $t('_.tinkTitle') }}</h4>
              <p class="desc">{{ $t('_.tinkDescription') }}</p>
              <p class="detail-desc">{{ $t('_.tinkDetailDescription') }}</p>
            </div>
          </template>
        </a>
      </template>
      <template v-if="showSofort">
        <a @click="goSofort" :class="{disabled: sStatus === sofortStatus.UrlFailed}">
          <template v-if="sStatus === sofortStatus.UrlFailed">
            <FontIcon name="exclamation-triangle-regular" />
            <div>
              <ColorButton type="blue" @click="getSofortUrl">
                <FontIcon name="rotate-right" />
                {{ $t('_.sofortRefrash') }}
              </ColorButton>
              <p class="desc">{{ $t('_.sofortUrlError') }}</p>
            </div>
          </template>
          <template v-else>
            <FontIcon class="bank-account-card" name="bank-account-card-regular" />
            <div>
              <h4>{{ $t('_.sofortTitle') }}</h4>
              <p class="desc">{{ $t('_.sofortDescription') }}</p>
            </div>
          </template>
        </a>
      </template>
      <template v-if="showJumio">
        <a @click="$emit('step', 'jumio')">
          <FontIcon class="identity-card" name="identity-card-regular" />
          <div>
            <h4>{{ $t('_.jumioTitle') }}</h4>
            <p class="desc">{{ $t('_.jumioDescription') }}</p>
          </div>
        </a>
      </template>
    </template>
  </div>
</template>

<script>
import {state} from "@shared/utils/storeUtils.mjs";
import {sleep} from "@shared/utils/commonUtils.mjs";
import TinkController from '@/plugins/tinkController';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import baseTemplate from '@/mixins/baseTemplate';
import {sofortStatus, tinkStatus} from '@/constants/base/onboarding/kyc';
import ProgressButton from "@/views/components/common/ProgressButton.vue";
import SofortController from "@/plugins/sofortController";
export default {
  name: 'KycReadyDe',
  lexicon: 'kyc.deGiude',
  computed: {
    site: state('env', 'site'),
    tinkStatus() {
      return tinkStatus;
    },
    sofortStatus() {
      return sofortStatus;
    }
  },
  components: {ProgressButton, ColorButton, FontIcon },
  mixins: [baseTemplate],
  data() {
    return {
      tink: null,
      sofort: null,
      tStatus: tinkStatus.None,
      sStatus: sofortStatus.Ready,
      showTink: false,
      showSofort: false,
      showJumio: false,
      isReady: false,
    };
  },
  methods: {
    /**
     * TInk 컨트롤러에서 결과 콜백
     * @param info
     */
    tinkCallback(info) {
      console.log('%ctink callback', 'color:yellow', info);
      this.tStatus = info.status;
      if (this.tStatus === tinkStatus.Ready) this.tink.redirectTink();
    },
    /**
     * Sofort 컨트롤러에서 결과 콜백
     * @param info
     */
    sofortCallback(info) {
      console.log('%csofort callback', 'color:yellow', info);
      this.sStatus = info.status;
      if (this.sStatus === sofortStatus.Processing) this.sofort.redirectSofort();
    },
    /**
     * TinkController 초기화
     * @returns {Promise<void>}
     */
    async initTink() {
      const {$services: services, $router: router, site, lang, tinkCallback} = this;
      this.tink = new TinkController(this, {services, router, site, lang, callback: tinkCallback});

      await sleep(60);
    },
    /**
     * SofortController 초기화
     * @returns {Promise<void>}
     */
    async initSofort() {
      const {$services: services, site, lang, sofortCallback} = this;
      this.sofort = new SofortController(this, {services, site, lang, callback: sofortCallback});

      await sleep(60);
    },
    /**
     * Tink 이동
     */
    goTink() {
      this.$emit('step', 'ready');
      this.tink.updateStatus(tinkStatus.None);
    },
    /**
     * Sofort 이동
     */
    goSofort() {
      this.$emit('step', 'ready');
      this.sofort.updateStatus(sofortStatus.Ready);
    },
    /**
     * Tink Url 재조회
     */
    getTinkUrl() {
      this.tink.tryAgain();
    },
    /**
     * Sofort Url 재조회
     */
    getSofortUrl() {
      this.sofort.tryAgain();
    },
    /**
     * 가능 3rd party 목록 조회 및 화면 처리
     * @returns {Promise<void>}
     */
    async initThirdParty() {
      const r = await this.$services.kyc.getAvailableThirdParty();
      if (this.$onboarding.errorHandler(r, true)) return;

      const availableTypes = r.AvailableThirdPartyVerificationTypes;
      if (availableTypes?.includes('Tink')) this.showTink = true;
      if (availableTypes?.includes('Sofort')) this.showSofort = true;
      if (availableTypes?.includes('Jumio')) this.showJumio = true;

      if (!this.showTink && !this.showSofort && !this.showJumio) return;
      if (!this.showTink && !this.showSofort) this.$emit('step', 'jumio');
      else {
        if (this.showTink) {
          await this.initTink();
          if (!this.showJumio && !this.showSofort) this.goTink();
        }

        if (this.showSofort) {
          await this.initSofort();
          if (!this.showJumio && !this.showTink) this.goSofort();
        }
      }
    }
  },
  async mounted() {
    await this.initThirdParty();
    this.isReady = true;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[kyc-ready-de] {
  > p { .fs(21, 24); .mb(16); .c(#ccc); }
  > a { .-a(@c-w01, 2px); .br(8); .block(); .rel(); .p(28, 24); .flex(); .items-center();
    > [font-icon] { .c(#fff);
      &.bank-account-card { .fs(60); }
      &.identity-card { .fs(40); }
    }
    > div { .pl(24); .ml(24); .rel();
      &:before { .cnt(); .wh(1, 110%); .bgc(@c-w01); .abs(); .lt(0, 50%); .t-yc(); }
      h4 { .fs(21); .tl(); .c(#FFC64D); .mb(8); }
      .desc { .c(#fff); .regular(); }
      .detail-desc { .mt(8); .fs(14, 20); .c(#ccc); .regular(); }
    }
    &.disabled { .bgc(#333333);
      > [font-icon] { .c(#c64040); .fs(56); }
      [color-button] { .mb(8); .fs(16); .c(#edf4fd); .bgc(#2680eb);
        > [font-icon] { .c(#93c0f5); .fs(16); }
      }
    }
  }
  > a + a { .mt(24); }
  > [temporarily-error] {
    [font-icon] { .fs(60); .flex(); .justify-center(); .m(40,0,28); }
    [color-button] {
      &:nth-of-type(1) { .mt(55); }
    }
  }
  @media (@ms-down) {
    > p {.fs(18);}
    > a {.flex();.flex-dc(); .p(20);
      > [font-icon] {display: none; }
      > div {.pl(0); .ml(0);
        &:before {content: none; }
      }
    }
  }
}
</style>
