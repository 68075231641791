<template>
  <ProgressButton promotion-button :custom-color="type" :button-name="buttonName" :disabled="disabled" @click="$emit('click')" :progress="progress" />
</template>

<script>
import ProgressButton from '@/views/components/common/ProgressButton.vue';

export default {
  name: 'PromotionButton',
  components: { ProgressButton },
  props: {
    type: { type: String, default: 'red' },
    disabled: { type: Boolean, default: false },
    buttonName: { type: String, required: true },
    progress: { type: Boolean, default: false },
  },
};
</script>

<style lang="less">
@import "@/less/tpm";
[promotion-button] {
  &[progress-button][color-button] .sync { .flex(); .justify-center(); .items-center(); }
}
</style>
<style scoped lang="less">
@import "@/less/tpm";
[promotion-button] {
  &[progress-button][color-button] { .fs(16); .p(4, 12); .br(2em); .c(@c-white);
    &.red { .bgc(#c64040);
      &:not(:disabled) {
        &:hover, &:active, &:focus { .bgc(#b13232); }
      }
    }
    &.gray { .bgc(@c-gray3);
      &:not(:disabled) {
        &:hover, &:active, &:focus { .bgc(@c-gray4); }
      }
    }
    &:disabled { .o(0.35); cursor: default; }
  }
}
</style>
