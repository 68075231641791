<template>
  <div bsn>
    <ValidationComponent name="_bsn" :component="TextInput" :label="$t('personalDetails.bsn')" rules="required|bsn" v-model="modelInfo.NationalId" :preset="{maxLength: 9, disable: disabledNationalId}"/>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'Bsn',
  components: { ValidationComponent },
  props: {
    modelInfo: Specific,
  },
 data() {
    return {
      TextInput,
    };
 },
  computed: {
    disabledNationalId() {
      return this.modelInfo.blockFields?.includes('NationalId') && !!this.modelInfo.NationalId;
    },
  },
  methods: {
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[bsn] {}
</style>
