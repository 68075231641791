<template>
  <a tnc-link :href="url" target="_blank">
    <span>{{ tncLinkText }}</span>
    <FontIcon name="external-link-alt" />
  </a>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import { tncLink } from '@/constants/base/promotion/promotion';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap.mjs';

export default {
  name: 'PromotionTncLink',
  components: { FontIcon },
  computed:{
    site: state('env', 'site'),
    env: state('env', 'env'),
    promotionName() {
      return this.$route?.name;
    },
    promotionType() {
      const types = {
        FreeBonus: 'freeBonus',
        MatchBonus: 'matchBonus',
        DailyFreebie: 'dailyFreebie',
      }
      return types[this.promotionName];
    },
    tncLinkText() {
      return this.promotionType ? this.$t(`promotion.tncLinkText.${this.promotionType}`) : this.promotionName;
    },
    url() {
      const envList = tncLink[this.env] || tncLink.default;
      const typeList = envList[this.promotionType] || envList.freeBonus;
      const link = typeList[this.site] || typeList[siteIds.GGPCOM];
      return link;
    },
  },
};
</script>

<style scoped lang="less">
@import "@/less/tpm";
[tnc-link] { .flex(); .justify-end(); .items-center(); .gap(4); .fs(14); .c(@c-gray3);
  > span { .underline(); }
  [font-icon] { .fs(12); }
}
</style>
