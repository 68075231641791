<template>
  <div start-on-boarding>
    <h2>{{ $t('onBoarding.title.startOnboarding') }}</h2>
    <div class="holder">
      <a href="#" @click="goBankId">
        <img src="/img/ico_bank_account.svg"/>
        <div>
          <h4>{{ $t('_.bankTitle') }}</h4>
          <p>{{ $t('_.bankDescription') }}</p>
        </div>
      </a>
      <p v-html="$t('_.contactDescription', {csEmail})"></p>
    </div>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import {sleep} from "@shared/utils/commonUtils.mjs";
import BankIdController from '@/plugins/bankIdController';
import { bankIdStatus } from '@/constants/base/onboarding/kyc';
import baseTemplate from '@/mixins/baseTemplate';

export default {
  name: 'StartOnBoarding',
  lexicon: 'onBoarding.startOnboarding',
  mixins: [baseTemplate],
  data() {
    return {
      bankId: null,
      info: null,
      status: null,
      url: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
  },
  methods: {
    callback(info) {
      const path = this.isLocal ? '/onboarding' : '';

      this.info = info;
      this.status = info.status;
      this.url = info.url;

      switch (this.status) {
        case bankIdStatus.None:
          break;
        case bankIdStatus.Ready:
          break;
        case bankIdStatus.Pending:
          break;
        case bankIdStatus.Verified:
          break;
        case bankIdStatus.Default:
          break;
        default:
          break;
      }
    },
    goBankId() {
      this.bankId.goBankId();
    }
  },
  async mounted() {
    const {$services: services, site, lang, callback, modelInfo} = this;
    this.bankId = new BankIdController(this, {services, site, lang, callback, modelInfo});

    await sleep(60);
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[start-on-boarding] {
  h2 { .medium(); .fs(28); .rel(); .ib();
    &:before { .cnt(); .t-r(-45deg); .abs(); .wh(2, 20); .bgc(@c-red); .rb(-80, -26); }
    &:after { .cnt(); .-b(@c-red, 2px); .w(calc(((100vw - 100%) / 2) + 120%)); .abs(); .lb(calc(((100% - 100vw) / 2) + 20%), -24);}
  }
  .holder { .mt(90);
    > a { .-a(@c-w01, 2px); .br(8); .block(); .rel(); .p(28, 24); .flex(); .items-center();
      > img { .h(40); }
      > div { .pl(24); .ml(24); .rel();
        &:before { .cnt(); .wh(1,110%); .bgc(@c-w01); .abs(); .lt(0,50%); .t-yc(); }
        h4 { .fs(20); .c(#FFC64D); .mb(8); }
        p { .fs(14, 20); }
      }
      &:nth-of-type(2) { .mt(24); }
    }
    p { .c(#cccccc); .fs(14); .mt(16);
      > a { .c(#2d74f6); }
    }
  }
}
</style>
