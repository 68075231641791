<template>
  <div free-bonus-component v-if="info">
    <section>
      <h4>{{ $t('promotion.dailyFreeBonus') }}</h4>
      <div class="bonus-info">
        <BonusSlider :info="freeBonuses" :day="currentDay" />
      </div>
    </section>
    <section>
      <h4>{{ $t('promotion.dailyFreeChallenge') }}</h4>
      <div class="bonus-info">
        <div class="challenges" v-if="challengeInfo">
          <h5>Day {{ challengeDay }} Challenge</h5>
          <ul>
            <li>
              <div class="info">
                <div class="label">{{ `Challenge ${challengeDay}` }}</div>
                <p> {{ $t('promotion.freeBonus.playHandCount', { handCount: challengeInfo.handCount }) }}</p>
                <div class="label">Game Type</div>
                <p>{{ challengeInfo.gameTypes }}</p>
              </div>
              <ProgressBar :width="challengeInfo.attendeeHandCount / challengeInfo.attendeeHandCountMax" :current="challengeInfo.attendeeHandCount" :max="challengeInfo.attendeeHandCountMax" />
            </li>
          </ul>
        </div>
        <div class="challenge-reward">
          <h5>Challenge Rewards</h5>
          <BonusSlider :info="challenges" :day="currentDay" />
        </div>
      </div>
    </section>
    <section class="complete-challenge" v-if="!isEmpty(completeAllChallenges) && inProgressCompleteAllChallenge">
      <div class="bonus-info">
        <h5>{{ $t('promotion.completeAllDailyChallenges') }}</h5>
        <div v-for="challenge in completeAllChallenges">
          <p>{{ `Receive a bonus equivalent to ${challenge.prizeValueCurrency} ${challenge.prizeValue} value!!` }}</p>
          <ProgressBar :width="isFinishedCount / challengeCount" :current="isFinishedCount" :max="challengeCount" />
          <ul>
            <li v-for="item in challenge.items">
              <BonusItem :info="item" :active="true" />
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BonusSlider from '@/views/components/pages/promotion/BonusSlider.vue';
import BonusSection from '@/views/components/pages/promotion/BonusSection.vue';
import BonusItem from '@/views/components/pages/promotion/BonusItem.vue';
import ProgressBar from '@/views/components/pages/promotion/ProgressBar.vue';
import Specific from '@shared/types/Specific';
import BonusInfo from '@/views/components/pages/promotion/BonusInfo.vue';
import { isEmpty } from 'lodash';

export default {
  name: 'FreeBonusComponent',
  components: { BonusInfo, BonusSlider, BonusSection, BonusItem, ProgressBar },
  props: {
    info: Specific,
  },
  data() {
    return {
      freeBonuses: null,
      challenges: null,
      completeAllChallenges: null,
    };
  },
  computed: {
    currentDay() {
      return this.info?.currentDay;
    },
    challengeCount() {
      return this.challenges?.length;
    },
    isFinishedCount() {
      if (!this.currentDay && this.currentDay !== 0) {
        return this.challenges?.filter(o => o.prizeAttendee.isFinished === true)?.length;
      }
      else {
        return this.challenges?.filter(challenge => challenge.day < this.currentDay && challenge.prizeAttendee.isFinished)?.length;
      }
    },
    inProgressCompleteAllChallenge() {
      if (this.currentDay < 1) return false;

      const max = this.challenges?.length;
      const completeCount = this.challenges?.reduce((total, e) => total = e.prizeAttendee.isFinished ? total + 1 : total, 0);

      if (!this.currentDay && this.currentDay !== 0) {
        return this.challenges.length === completeCount;
      } else {
        return completeCount >= (Math.min(this.currentDay - 1, max));
      }
    },
    challengeDay() {
      const length = this.challenges?.length;
      const lastIndex = length > 0 ? length - 1 : 0;
      const lastDay = this.challenges?.[lastIndex]?.day;
      return (!this.currentDay || lastDay < this.currentDay) ? lastDay : this.currentDay;
    },
    challengeInfo() {
      const index = this.challengeDay > 0 ? this.challengeDay - 1 : 0;
      const info = this.challenges?.[index];
      if (isEmpty(info)) return;
      return ({
        handCount: info.conditions[0]?.handCount,
        gameTypes: info.conditions[0]?.gameTypes,
        attendeeHandCount: info.prizeAttendee?.handCount,
        attendeeHandCountMax: info.prizeAttendee?.handCountMax,
      });
    }
  },
  methods: {
    setBonusList() {
      this.freeBonuses = [];
      this.challenges = [];
      this.completeAllChallenges = [];

      this.info?.pkg?.prizes?.forEach(o => {
        switch (o?.freeBonusPrizeType) {
          case 'FREE':
            this.freeBonuses.push(o);
            break;
          case 'CHALLENGE':
            this.challenges.push(o);
            break;
          case 'ALL_CHALLENGE_CLEAR':
            this.completeAllChallenges.push(o);
            break;
          default:
            return;
        }
      });
    },
  },
  mounted() {
    this.setBonusList();
  },
};
</script>

<style scoped lang="less">
@import "@/less/tpm";
[free-bonus-component] { .flex(); .flex-dc(); .gap(20);
  h4 { .mb(20); .c(@c-gray2); .regular(); }
  h5 {.fs(20); .semi-bold(); }
  .bonus-info { .bgc(@c-bgBlack); }
  .challenges, .complete-challenge .bonus-info { .p(20); }

  .challenges {
    li { .pt(20); }
    .info { .grid(); .grid-template-columns(max-content 1fr); .gap(8);
      .label { .c(#888); }
    }
    [progress-bar] { .mt(16); }
  }
  .challenge-reward { .-t(@c-gray6); .pt(20);
    h5 { .ph(20); .pb(8); }
  }

  .complete-challenge {
    .bonus-info > div { .flex(); .flex-dc(); .gap(16);
      p { .mt(4); .c(#a48954); .fs(14); }
      ul { .tc(); }
      [bonus-item] { .flex(); .flex-dr(); .gap(16px 24px); .justify-center(); .items-center(); .flex-wrap(); }
    }
  }

  @media(@mm-up) {
    .challenges {
      .info { .column-gap(24px); }
    }
  }
}
</style>
