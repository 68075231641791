<template>
  <div verify-age-wsop v-if="showFailedView">
    <FontIcon name="sharp-triangle-warning" />
    <p v-html="$t('onBoarding.sumsub.failed.description1')" />
    <p v-html="$t('onBoarding.sumsub.failed.description2', {csEmail})" />
    <ProgressButton button-name="close" @click="close" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import baseTemplate from '@/mixins/baseTemplate';
import ProgressTitle from '@/views/components/pages/on-boarding/ProgressTitle.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { redirectCompleteUrl } from '@/utils/baseUtil';
import { autoLogin, close, isClient } from '@/utils/clientUtil';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'VerifyAgeWsop',
  components: { FontIcon, ProgressButton },
  mixins: [baseTemplate],
  data() {
    return {
      showFailedView: false,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
  },
  methods: {
    async close() {
      if (!await redirectCompleteUrl(this)) await close(this);
    },
    /**
     * 컴포넌트 내에서의 api request 처리
     * @returns {Promise<*|null>}
     */
    async apiRequest() {
      const r = await this.getApiResponse();
      if (r.error) {
        if(!(await this.apiErrorHandler(r))) return;

        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, {csEmail: this.csEmail});
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : onboarding.verifyAge`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }
      }
    },
    /**
     *
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse() {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding.verifyAge(); }
      catch (e) { return e; }
    },
    async apiErrorHandler(r) {
      // const { code, desc, key, CustomerErrorParameters, errorTemplate } = r;
      // if (key === apiErrorCode.AISG_FAILED_SELF_EXCLUSION_CHECK) this.replaceRouteName('VerificationFailed', {desc, errorTemplate}, { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined });
      // else if (key === apiErrorCode.USER_INFO_NOT_VERIFIED_AND_PASS) return false;
      // else return true;
      switch (r?.key) {
        case apiErrorCode.OASIS_BAN_EXIST:
        case apiErrorCode.ACCOUNT_SUSPENDED:
        case apiErrorCode.GAMSTOP_SELF_EXCLUDED:
        case apiErrorCode.FAILED_SELF_EXCLUSION_CHECK:
        case apiErrorCode.AISG_FAILED_SELF_EXCLUSION_CHECK:
          this.$onboarding.errorHandler(r, true);
          break;
        case apiErrorCode.INVALID_VERIFICATION_STATUS:
          this.showFailedView = true;
          break;
        case apiErrorCode.INTERNAL_ERROR:
        case apiErrorCode.KYC_UPLOAD_REQUIRED:
        case apiErrorCode.USER_INFO_NOT_VERIFIED:
        case apiErrorCode.USER_INFO_NOT_VERIFIED_AND_RETRY:
          break;
        case apiErrorCode.USER_INFO_NOT_VERIFIED_AND_PASS:
        default:
          return false;
      }

      return true;
    },
  },
  async mounted() {
    if (this.item) this.item.title = 'onBoarding.title.accountVerification';
    await this.apiRequest();
    if (!this.structure.error) this.modelInfo.replace = true;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-age-wsop] { .tc();
  > [font-icon] { .block(); .mb(24); .fs(80); }
  > p { .tl(); .fs(18, 22, normal); .c(#7F7F7F);
    > a[href^='mailto'] { .c(#2680EB); .underline; }
  }
  > p + p { .mt(22); }
}
</style>
