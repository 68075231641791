<template>
  <div gp-country-drop-down>
    <SearchDropSelect :value="model" @input="selected" :placeholder="placeholder" :list="sourceOfFundsList" :size="size" @focus="$emit('focus')" @blur="$emit('blur')" :disabled="disabled" :searchHandler="searchHandler" :hidden-icon="hiddenIcon" />
  </div>
</template>

<script>

import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import { sourceOfFundsList } from '@/constants/base/my-page';
import { sleep } from '@shared/utils/commonUtils.mjs';

export default {
  name: 'PhSourceOfFunds',
  components: { SearchDropSelect },
  props: {
    value: { type: Specific, default: null },
    size: { type: String, default: 'sm' },
    disabled: { type: Boolean, default: false, },
    placeholder: { type: String, default: false, },
    hiddenIcon:{ type: Boolean, default : false},
  },
  data() {
    return {
      list: null,
      model: null,
      sourceOfFundsList: null,
    };
  },
  computed: {
    site: state('env', 'site'),
  },
  methods: {
    selected(v) {
      if (!v) return;
      this.model = v?.value;
      this.$emit('input', v?.value);
    },
    searchHandler(originList, searchText) {
      if (originList.length <= 0) return [];
      const searchList = originList.filter(originData => originData.value.toLowerCase().includes(searchText.toLowerCase()) || originData.label.toLowerCase().includes(searchText.toLowerCase()));
      return searchList;
    },
  },
  async mounted() {
    this.sourceOfFundsList = sourceOfFundsList.map(v => ({label: this.$t(v.label), value: v.value}));
    await sleep(60);
    this.model = this.value;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-country-drop-down] { .h(40); .rel();
  &.disabled {.h(20);}
  &.single { .h(48);
    [search-drop-select] {
      > a { .h(48); .br(6); box-shadow: 0 1px 0 0 rgba(200, 200, 200, 0.1), 0 1px 0 0 rgba(0, 0, 0) inset; .bgc(rgba(255, 255, 255, 0.07)) !important;;
        > label { .lh(50); .h(100%);}
      }
    }
  }
  .img-country { .w(18); .ib(); .mr(8);}
  .link { .abs(); .rb(0, -28); .c(@c-red); .semi-bold();
    a { .-b(@c-red);}
  }
}
</style>