import { siteIds as sideIds, siteIds } from '@/constants/base/siteMap.mjs';
import { routeNames } from '@/constants/model/my-page';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export const rootRoute = 'MyInfo';
export const STEP = {
  DEFAULT: 'DEFAULT',
  INPUT: 'INPUT',
  VERIFY: 'VERIFY',
  CHECK: 'CHECK',
};

export const STATUS = {
  'VIEW': 'VIEW',
  'SUCCESS': 'SUCCESS',
  'ERROR': 'ERROR',
};

export const mfaLevel = {
  OFF: 'Off',
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High'
};

export const mfaMethod = {
  EMAIL: 'Email',
  MOBILE: 'Mobile',
  GOOGLE: 'GoogleAuthentication'
};

export const ggpassLevel = {
  'Off': 'OFF',
  'Low': 'LOW',
  'Medium': 'MEDIUM',
  'High': 'HIGH',
};

export const ggpassMethod = {
  GoogleAuthentication: 'GOOGLE',
  Mobile: 'MOBILE',
  Email: 'EMAIL'
};

export const socialType = {
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  APPLE: 'APPLE',
};

export const transferType = {
  CASH_OUT: 'CASH_OUT',
  ADD_CASH: 'ADD_CASH'
};

export const mfaSecurityType = { Off: 'Off', Notification: 'Notification', Verification: 'Verification' };

export const FUND_MODE = { FUND: 'fund', CHANGE: 'change', DISABLE: 'disable' };

export const KYC_STATUS = { SIGNED_UP: 'SignedUp', EMAIL_VERIFIED: 'EmailVerified', DETAILS_FILLED: 'DetailsFilled', AGE_VERIFIED: 'AgeVerified', POI_VERIFIED: 'PoiVerified', POA_VERIFIED: 'PoaVerified', EDD_VERIFIED: 'EddVerified' };
export const KYC_REQUEST_TYPE = { NONE: 'None', PERSONAL_DETAIL: 'PersonalDetail', POI: 'Poi', SELFIE_POI: 'SelfiePoi', POA: 'Poa', POI_POA: 'PoiPoa', EDD: 'EDD' };
export const kycMorePoiArray = [KYC_STATUS.POI_VERIFIED, KYC_STATUS.POA_VERIFIED, KYC_STATUS.EDD_VERIFIED];
export const kycVerified = [KYC_STATUS.POA_VERIFIED, KYC_STATUS.EDD_VERIFIED];


const useStateDropdownCountries = [];
const useCityDropdownCountries = ['CZ'];
const useLugasSiteIds = [siteIds.GGPDE];
const useOasisSiteIds = [siteIds.GGPDE];

export const kycDocumentStatus = { 0: 'pending', 1: 'confirmed', 2: 'rejected', 3: 'expired' };

// My Document
export const unUseVerifyFlowList = [siteIds.SEVENXL, siteIds.DAVAOPOKER, siteIds.GGPUKE, siteIds.EVPUKE, siteIds.NATURAL8, siteIds.GGPOK];

export const KYC_STEP = {
  None: 'None',
  Done: 'Done',
  PersonalName: 'PersonalName',
  PersonalBirth: 'PersonalBirth',
  PersonalAddress: 'PersonalAddress',
  PersonalOccupation: 'PersonalOccupation',
  PersonalPep: 'PersonalPep',
  VerifyMobileNumber: 'VerifyMobileNumber',
  VerifyEmail: 'VerifyEmail',
  EditAccountInfo: 'EditAccountInfo',
  EditPersonalInfo: 'EditPersonalInfo',
  ShowReviewingKycMessage: 'ShowReviewingKycMessage',
  ShowPersonalInfoInconsistencyError: 'ShowPersonalInfoInconsistencyError',
  ShowMultipleAccountVerificationError: 'ShowMultipleAccountVerificationError',
  ShowAgeVerificationError: 'ShowAgeVerificationError',
  LifeTimeDepositLimit: 'LifeTimeDepositLimit',
  NotificationLoginInfo: 'NotificationLoginInfo',
  VerifyAge: 'VerifyAge',
  ShowExclusionCheckFailureMessage: 'ShowExclusionCheckFailureMessage',
  VerifyFundProtectionAcceptance: 'VerifyFundProtectionAcceptance',
  VerifyRgDepositSetting: 'VerifyRgDepositSetting',
  VerifyRgLoginTimeSetting: 'VerifyRgLoginTimeSetting',
  VerifyRgBalanceSetting: 'VerifyRgBalanceSetting',
  VerifyRgTotalBetSetting: 'VerifyRgTotalBetSetting',
  VerifyRgLossSetting: 'VerifyRgLossSetting',
  VerifyRgLossKrSetting: 'VerifyRgLossKrSetting',
  VerifyRgMonthlyLoginFrequencySetting: 'VerifyRgMonthlyLoginFrequencySetting',
  VerifyRgReLoginTimeSetting: 'VerifyRgReLoginTimeSetting',
  Lugas: 'Lugas',
  EditHighRiskMandatory: 'EditHighRiskMandatory',
  Deposit: 'Deposit',
  Verification: 'Verification',
  VerifyCrucks: 'VerifyCrucks',
  VerifyBankInfo: 'VerifyBankInfo',
  VerifySurePay: 'VerifySurePay',
  Poi: 'Poi',
  Poa: 'Poa',
  Jumio: 'Jumio',
  Signicat: 'Signicat',
  Payletter: 'Payletter',
  Edd: 'Edd',
  TransUnion: 'TransUnion',
  KycDE: 'KycDE',
  ReCheckPersonalDetails: 'ReCheckPersonalDetails',
  StartOnboarding: 'StartOnboarding',
  ConfirmPersonalInfo: 'ConfirmPersonalInfo',
  TempAccountGuide: 'TempAccountGuide',
  PersonalHomeAddress: 'PersonalHomeAddress',
  PersonalMailingAddress: 'PersonalMailingAddress',
  PersonalDataBox: 'PersonalDataBox',
  VerifyPaymentAccount: 'VerifyPaymentAccount',
  PersonalDetailReaffirm: 'PersonalDetailReaffirm',
  Screening: 'Screening',
  PersonalSelfDeclaration: 'PersonalSelfDeclaration',
  PersonalExtraAddress: 'PersonalExtraAddress',
  Sumsub: 'Sumsub'
};

// 각 나라의 통화별 최소 단위
// scale이 2 일 경우 소숫점 2자리 까지 => 0.01 0일 경우 => 1
export const currencyScaleModel = {
  'AMD': 2,
  'ARS': 2,
  'AUD': 2,
  'BRL': 2,
  'CAD': 2,
  'CHF': 2,
  'CLP': 0,
  'CNY': 2,
  'COP': 2,
  'CZK': 2,
  'DKK': 2,
  'EUR': 2,
  'GBP': 2,
  'GEL': 2,
  'HKD': 2,
  'HUF': 2,
  'IDR': 2,
  'INR': 2,
  'JPY': 0,
  'KHR': 2,
  'KRW': 0,
  'KZT': 2,
  'MXN': 2,
  'MYR': 2,
  'NOK': 2,
  'PHP': 2,
  'PLN': 2,
  'RON': 2,
  'RSD': 2,
  'RUB': 2,
  'SEK': 2,
  'SGD': 2,
  'THB': 2,
  'TWD': 2,
  'UAH': 2,
  'USD': 2,
  'VND': 0,
};

// Balance Transfer에서 Debit / Credit 의 ErrorCode가 동일 할때 사용
export const bothErrorCodes = [apiErrorCode.ACCOUNT_UNVERIFIED, apiErrorCode.RESPONSIBLE_GAMING, apiErrorCode.ACCOUNT_SUSPENDED, apiErrorCode.ACCOUNT_LOCK_BALANCE, apiErrorCode.ACCOUNT_CASHIER_BLOCK];

export const employmentList = [
  { label: 'myInfo.employed', value: 'Employed' },
  { label: 'myInfo.selfEmployed', value: 'SelfEmployed' },
  { label: 'myInfo.retired', value: 'Retired' },
  { label: 'myInfo.student', value: 'Student' },
  { label: 'myInfo.unemployed', value: 'Unemployed' },
];
export const sourceOfFundsList = [
  { label: 'businessIncome', value: 'BusinessIncome' },
  { label: 'employment', value: 'Employment' },
  { label: 'inheritance', value: 'Inheritance' },
  { label: 'investmentsSavings', value: 'InvestmentsSavings' },
  { label: 'pensionRetirementIncome', value: 'PensionRetirementIncome' },
]