<template>
  <div verification-request v-if="kycDocumentList">
    <ReviewingDocuments v-if="step === kycStatus.Final" :info="kycDocumentList" :item="item"  :complete="isComplete" @next="next" @complete="complete" />
    <component v-else-if="step === kycStatus.Ready" :is="readyComponent" :kycDocumentGroups="kycDocumentGroups" :message="message" @update="setStep" />
    <component v-else-if="step === kycStatus.EddForm" :is="eddFormComponent" :kycDocumentGroups="kycDocumentGroups" :item="item" :structure="structure" :modelInfo="modelInfo" :message="message" @update="setStep" />
    <component v-else-if="step === kycStatus.Poi" :is="poiComponent" :kycDocumentGroups="kycDocumentGroups" :item="item" @update="updateUploadDocuments" />
    <component v-else-if="step === kycStatus.Poa" :is="poaComponent" :kycDocumentGroups="kycDocumentGroups" :item="item" @update="updateUploadDocuments" />
    <component v-else-if="step === kycStatus.Edd" :is="eddComponent" :kycDocumentGroups="kycDocumentGroups" :item="item" :structure="structure" :modelInfo="modelInfo" @update="updateUploadDocuments" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { redirectCompleteUrl } from '@/utils/baseUtil';
import { autoLogin, close, checkClientPermission, isClient } from '@/utils/clientUtil';
import Specific from '@shared/types/Specific';
import { kycStatus, restrictType } from '@/constants/base/onboarding/kyc';
import KycReady from '@/views/components/pages/on-boarding/template/kyc/verification-request/ready/KycReady.vue';
import KycReadyPh from '@/views/components/pages/on-boarding/template/kyc/verification-request/ready/KycReadyPh.vue';
import KycReadyComOther from '@/views/components/pages/on-boarding/template/kyc/verification-request/ready/KycReadyComOther.vue';
import KycReadyUk from '@/views/components/pages/on-boarding/template/kyc/verification-request/ready/KycReadyUk.vue';
import KycReadyN8in from '@/views/components/pages/on-boarding/template/kyc/verification-request/ready/KycReadyN8in.vue';
import ProofOfIdentity from '@/views/components/pages/on-boarding/template/kyc/verification-request/poi/ProofOfIdentity.vue';
import ProofOfIdentityPh from '@/views/components/pages/on-boarding/template/kyc/verification-request/poi/ProofOfIdentityPh.vue';
import ProofOfIdentityN8in from '@/views/components/pages/on-boarding/template/kyc/verification-request/poi/ProofOfIdentityN8in.vue';
import ProofOfAddress from '@/views/components/pages/on-boarding/template/kyc/verification-request/poa/ProofOfAddress.vue';
import ProofOfAddressPh from '@/views/components/pages/on-boarding/template/kyc/verification-request/poa/ProofOfAddressPh.vue';
import EnhancedDueDiligence from '@/views/components/pages/on-boarding/template/kyc/verification-request/eddForm/EnhancedDueDiligence.vue';
import EnhancedDueDiligencePh from '@/views/components/pages/on-boarding/template/kyc/verification-request/eddForm/EnhancedDueDiligencePh.vue';
import EnhancedDueDiligenceUk from '@/views/components/pages/on-boarding/template/kyc/verification-request/eddForm/EnhancedDueDiligenceUk.vue';
import ProofOfAffordability from '@/views/components/pages/on-boarding/template/kyc/verification-request/edd/ProofOfAffordability.vue';
import ProofOfAffordabilityPh from '@/views/components/pages/on-boarding/template/kyc/verification-request/edd/ProofOfAffordabilityPh.vue';
import ProofOfAffordabilityUk from '@/views/components/pages/on-boarding/template/kyc/verification-request/edd/ProofOfAffordabilityUk.vue';
import ReviewingDocuments from '@/views/components/pages/on-boarding/template/kyc/verification-request/ReviewingDocuments.vue';
import baseTemplate from '@/mixins/baseTemplate';
import { siteIds, sitesByBrand } from '@/constants/base/siteMap';

export default {
  name: 'VerificationRequest',
  components: {
    ReviewingDocuments,
  },
  mixins: [baseTemplate],
  data() {
    return {
      step: kycStatus.Ready,
      poiOnly: false,
      poaOnly: false,
      eddOnly: false,
      isReady: false,
      isComplete: false,
      considerKyc: false,
      kycStatusInfo: /** @type {PlayerKycStatus} */ Specific,
      kycDocumentList: /** @type {PlayerKycDocument} */ Specific,
      kycDocumentGroups: /** @type {Array<KycDocumentGroup>} */ Specific,
      readyComponents: [
        { component: KycReady, sites: null },
        { component: KycReadyPh, sites: [siteIds.GGPPH] },
        { component: KycReadyComOther, sites: sitesByBrand.GGPCOM.filter(s => s !== siteIds.GGPPH) },
        { component: KycReadyUk, sites: [siteIds.GGPUK] },
        { component: KycReadyN8in, sites: [siteIds.N8IN] },
      ],
      poiComponents: [
        { component: ProofOfIdentity, sites: null },
        { component: ProofOfIdentityPh, sites: [siteIds.GGPPH] },
        { component: ProofOfIdentityN8in, sites: [siteIds.N8IN] },
      ],
      poaComponents: [
        { component: ProofOfAddress, sites: null },
        { component: ProofOfAddressPh, sites: [siteIds.GGPPH] },
      ],
      eddFormComponents: [
        { component: EnhancedDueDiligence, sites: null },
        { component: EnhancedDueDiligencePh, sites: [siteIds.GGPPH] },
        { component: EnhancedDueDiligenceUk, sites: [siteIds.GGPUK] },
      ],
      eddComponents: [
        { component: ProofOfAffordability, sites: null },
        { component: ProofOfAffordabilityPh, sites: [siteIds.GGPPH] },
        { component: ProofOfAffordabilityUk, sites: [siteIds.GGPUK] },
      ],
    };
  },
  computed: {
    kycStatus() {
      return kycStatus;
    },
    site: state('env', 'site'),
    additional: state('query', 'additional'),
    userInfo: state('user', 'userInfo'),
    restrict: state('query', 'restrict'),
    title() {
      switch (this.step) {
        case kycStatus.Final:
          return 'onBoarding.title.reviewingDocuments';
        case kycStatus.Poi:
          return 'kyc.proofOfIdentity.label';
        case kycStatus.Poa:
          return 'kyc.proofOfAddress.label';
        default:
          return 'onBoarding.title.verificationRequest';
      }
    },
    message() {
      return this.kycStatusInfo?.KycRequisition.KycRequestMessage;
    },
    readyComponent() {
      const t = this.readyComponents.find(o => o.sites?.includes(this.site))?.component;
      return t || this.readyComponents.find(o => !o.site)?.component;
    },
    poiComponent() {
      const t = this.poiComponents.find(o => o.sites?.includes(this.site))?.component;
      return t || this.poiComponents.find(o => !o.site)?.component;
    },
    poaComponent() {
      const t = this.poaComponents.find(o => o.sites?.includes(this.site))?.component;
      return t || this.poaComponents.find(o => !o.site)?.component;
    },
    eddFormComponent() {
      const t = this.eddFormComponents.find(o => o.sites?.includes(this.site))?.component;
      return t || this.eddFormComponents.find(o => !o.site)?.component;
    },
    eddComponent() {
      const t = this.eddComponents.find(o => o.sites?.includes(this.site))?.component;
      return t || this.eddComponents.find(o => !o.site)?.component;
    },
  },
  methods: {
    next() {
      this.modelInfo.step = !this.poiOnly && !this.poaOnly ? 2 : 1;
    },
    async complete() {
      if (!await redirectCompleteUrl(this)) {
        if (isClient()) {
          const existRestrictLogin = this.kycStatusInfo?.KycRequisition?.Restrics?.includes(restrictType.Login);
          if (existRestrictLogin) close(this);
          else autoLogin(this);
        } else close(this);
      }
    },
    /**
     * 진행 단계 및 완료 여부 설정
     * @param {string} step - 진행 단계
     */
    setStep(step) {
      this.step = step;
      this.modelInfo.title = this.title;
      if (step === kycStatus.Final) this.modelInfo.final = true;
    },
    /**
     * 진행 되야 하는 스텝 중 올릴 문서가 있는지 확인 하여 Type(Poi, Poa, Edd) 별 단독 진행 여부 갱신
     */
    async initUploadStatusInfo() {
      this.kycStatusInfo = await this.$services.kyc.getPlayerKycStatus();

      const hasPoiStatus = this.structure.list.some(o => o.route === kycStatus.Poi);
      const hasPoaStatus = this.structure.list.some(o => o.route === kycStatus.Poa);
      const hasEddStatus = this.structure.list.some(o => o.route === kycStatus.Edd);
      this.poiOnly = hasPoiStatus && !hasPoaStatus && !hasEddStatus;
      this.poaOnly = hasPoaStatus && !hasPoiStatus && !hasEddStatus;
      this.eddOnly = hasEddStatus && !hasPoiStatus && !hasPoaStatus;
    },
    /**
     * 올려야 하는 Kyc Document 항목을 조회하여 현재 올려야 할 항목의 여부에 따라 진행 단계 갱신
     * @returns {Promise<void>}
     */
    async updateUploadDocuments() {
      this.kycDocumentList = (await this.$services.kyc.getPlayerKycDocuments()).Documents;
      this.kycDocumentGroups = (await this.$services.kyc.getPlayerKycUploadStatus({ Restrict: this.restrict, IsConsiderKycStatusWhenKycNotRequested: this.considerKyc })).KycDocumentGroup;

      const mustPoi = this.kycDocumentGroups.find(doc => doc.KycDocumentGroupType === kycStatus.Poi);
      const mustPoa = this.kycDocumentGroups.find(doc => doc.KycDocumentGroupType === kycStatus.Poa);
      const mustEdd = this.kycDocumentGroups.find(doc => doc.KycDocumentGroupType === kycStatus.Edd);

      if (!this.kycDocumentGroups.length) this.setStep(kycStatus.Final);
      else if (this.kycDocumentList.length && this.isReady) {
        // edd의 경우는 poi, poa와 혼재될 수 없음
        if (this.eddOnly) { // Edd 스텝 제어
          if (mustEdd) this.setStep(kycStatus.Edd);
        } else { // Poi, Poa 스텝 제어
          if (this.poiOnly && mustPoi) this.setStep(kycStatus.Poi);
          else if (this.poaOnly && mustPoa) this.setStep(kycStatus.Poa);
          else {
            if (mustPoi) this.setStep(kycStatus.Poi);
            else if (mustPoa) this.setStep(kycStatus.Poa);
          }
        }
      } else if (mustEdd && ![...sitesByBrand.GGPCOM, siteIds.GGPUK].includes(this.site)) { // EDD에서 Ready를 노출해야 할 경우 해당 분기 처리에 추가
        this.setStep(kycStatus.EddForm);
      }

      const lastStatus = mustEdd ? kycStatus.Edd : mustPoa ? kycStatus.Poa : mustPoi ? kycStatus.Poi : null;
      this.isComplete = this.structure.list.findIndex(o => o.route === this.step) === this.structure.list.findIndex(o => o.route === lastStatus);

      this.modelInfo.title = this.title;
      this.isReady = true;
    },
  },
  async mounted() {
    await checkClientPermission(this);
    this.considerKyc = !!this.additional || this.$route.params?.isConsiderKyc || this.userInfo.KycRequestType === 'None';
    await this.initUploadStatusInfo();
    await this.updateUploadDocuments();
  }
};
</script>
<style lang="less">
@import '~@/less/proj.less';
[verification-request] {
  .list-holder { .pb(12);
    > p { .fs(14); .mv(20); }
    [upload-item] {
      &:not(:nth-of-type(1)) { .mt(12); .pt(12); .-t(rgba(255, 255, 255, 0.15)); }
      &:not(:nth-of-type(1)) button { .pt(16); }
    }
  }
}
</style>
