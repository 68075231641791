<template>
  <GpFormTemplate np-my-device>
    <template #header>
      <GpDescription :message="description1" />
      <GpDescription :message="description2" />
    </template>
    <template #content>
      <p v-if="noDevice" class="no-device">{{ $t('_.noDevice') }}</p>
      <template v-else>
        <div class="section-title">
          <label class="device-title">{{ $t('_.deviceList') }}</label>
          <TextButton @click="deviceRemove({deleteAll: true})">{{ $t('_.removeAll') }}</TextButton>
        </div>
        <DisplayMyDeviceList v-if="ready" :list="trustedDevices" @remove="deviceRemove" />
      </template>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import npErrorHandler from '@/mixins/my-info/npErrorHandler';
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import Specific from '@shared/types/Specific';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import DisplayMyDeviceList from '@/views/components/pages/my-page/template/views/DisplayMyDeviceList.vue';
import TextButton from '@/views/components/gg-pass/TextButton.vue';
import { isExcludeGgpass } from '@/utils/baseUtil';
import MyDeviceRemoveConfirm from '@/views/components/pages/my-page/modal/MyDeviceRemoveConfirm.vue';
import moment from 'moment';

export default {
  name: 'NpMyDevice',
  lexicon: 'myInfo.myDevices',
  components: { TextButton, GpDescription, GpTitle, GpFormTemplate, GpButton, DisplayMyDeviceList },
  mixins: [npErrorHandler],
  props: {
    structure: { type: Specific, default: null },
    model: Specific,
  },
  data() {
    return {
      ready: false,
      trustedDevices: /** @type {TrustedDeviceItem[]} */null,
    };
  },
  computed: {
    site: state('env', 'site'),
    brand: state('env', 'brand'),
    onePassInfo: state('user', 'onePassInfo'),
    csEmail: state('env', 'csEmail'),
    description1() {
      return this.$t('myInfo.myDevices.description1');
    },
    description2() {
      return this.$t('myInfo.myDevices.description2', { brand: this.isGgpass ? 'GGPass':this.brand });
    },
    isGgpass() {
      return !isExcludeGgpass(this.site);
    },
    noDevice() {
      return this.trustedDevices?.length <= 0;
    }
  },
  methods: {
    async deviceRemove({ deviceName, clientDeviceIdNew, deleteAll = false }) {
      await this.$modal(MyDeviceRemoveConfirm, { deviceName, clientDeviceIdNew, deleteAll, updateInfo: this.updateInfo });
    },
    async updateInfo() {
      this.ready = false;

      const response = this.isGgpass ? await this.$services.user.getOnepassTrustedDevice() : await this.$services.user.getTrustedDevicesRequest();

      if (this.isGgpass) this.trustedDevices = response.list;
      else {
        this.trustedDevices = response.TrustedDevices.map(device => ({
          clientDevice: device.ClientDevice,
          clientDeviceType: device.ClientDeviceType,
          clientDeviceIdType: '', // [eric] OP 에서만 내려주는 값 같은데 뭔지 확인 필요
          clientDeviceIdNew: device.ClientDeviceIdNew,
          lastLoggedInAt: moment.utc(device.LastLoggedInAt),
          clientIp: device.ClientIp,
          loginAddress: device.LoginAddress,
          appNames: device.AppNames,
          isCurrentDevice: device.IsCurrentDevice,
        }));
      }
      this.trustedDevices.sort(item => item.isCurrentDevice ? -1 : 1);

      this.ready = true;
    },
    async initialize() {
      await this.updateInfo();
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-my-device] {
  .no-device {.p(16); .c(#000); .bgc(#fff); .br(16); .fs(14); .tc();}
  .section-title {.flex(); .space-between(); .items-center(); .mb(8);
    .device-title {.c(black); .fs(16); font-weight: 500;}
    [gp-text-button] {
      button {text-decoration: none; .c(@gp-green);}
    }
  }
}
</style>
