import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';
import { siteIds } from '@/constants/base/siteMap';
import AddressCz from '@/views/components/pages/on-boarding/template/address/AddressCz.vue';
import AddressCommon from '@/views/components/pages/on-boarding/template/address/AddressCommon.vue';
import AddressDe from '@/views/components/pages/on-boarding/template/address/AddressDe.vue';
import AddressPh from '@/views/components/pages/on-boarding/template/address/AddressPh.vue';
import AddressWsop from '@/views/components/pages/on-boarding/template/address/AddressWsop.vue';
import AddressOcnp from '@/views/components/pages/on-boarding/template/address/AddressOcnp.vue';
import AddressOk from '@/views/components/pages/on-boarding/template/address/AddressOk.vue';
import AddressUk from '@/views/components/pages/on-boarding/template/address/AddressUk.vue';
import ExtraAddressCommon from '@/views/components/pages/on-boarding/template/extra-address/ExtraAddressCommon.vue';
import ExtraAddressPh from '@/views/components/pages/on-boarding/template/extra-address/ExtraAddressPh.vue';
import BirthCz from '@/views/components/pages/on-boarding/template/birth/BirthCz.vue';
import Bsn from '@/views/components/pages/on-boarding/template/extra/Bsn.vue';
import Cnp from '@/views/components/pages/on-boarding/template/extra/Cnp.vue';
import Citizenship from '@/views/components/pages/on-boarding/template/extra/Citizenship.vue';
import ConfirmPersonalInfo from '@/views/components/pages/on-boarding/template/ConfirmPersonalInfo.vue';
import FooterPh from '@/views/components/pages/on-boarding/template/extra/footer/FooterPh.vue';
import FooterRo from '@/views/components/pages/on-boarding/template/extra/footer/FooterRo.vue';
import FundProtectionPolicy from '@/views/components/pages/on-boarding/template/FundProtectionPolicy.vue';
import GenderSelector from '@/views/components/extra/GenderSelector.vue';
import KycDe from '@/views/components/pages/on-boarding/template/kyc/GGPDE/KycDe.vue';
import LastNameAtBirth from '@/views/components/pages/on-boarding/template/extra/LastNameAtBirth.vue';
import Lugas from '@/views/components/pages/on-boarding/template/lugas/Lugas.vue';
import MiddleName from '@/views/components/pages/on-boarding/template/extra/MiddleName.vue';
import OptionalName from '@/views/components/pages/on-boarding/template/extra/OptionalName.vue';
import OccupationWsop from '@/views/components/pages/on-boarding/template/occupation/OccupationWsop.vue';
import OccupationUk from '@/views/components/pages/on-boarding/template/occupation/OccupationUk.vue';
import OccupationPh from '@/views/components/pages/on-boarding/template/occupation/OccupationPh.vue';
import PersonalOk from '@/views/components/pages/on-boarding/template/personal/PersonalOk.vue';
import PersonalPh from '@/views/components/pages/on-boarding/template/personal/PersonalPh.vue';
import PersonalNames from '@/views/components/pages/on-boarding/template/PersonalNames.vue';
import PersonalBirth from '@/views/components/pages/on-boarding/template/birth/PersonalBirth.vue';
import PlaceOfBirth from '@/views/components/extra/PlaceOfBirth.vue';
import PersonalPep from '@/views/components/pages/on-boarding/template/PersonalPep.vue';
import ReaffirmWsop from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/wsop/ReaffirmWsop.vue';
import ReaffirmDe from '@/views/components/pages/on-boarding/template/personal-detail-reaffirm/de/ReaffirmDe.vue';
import ReCheckDe from '@/views/components/pages/on-boarding/template/personal-details-recheck/de/ReCheckDe.vue';
import Payletter from '@/views/components/pages/on-boarding/template/kyc/payletter/Payletter.vue';
import PersonalSelfDeclaration from '@/views/components/pages/on-boarding/template/PersonalSelfDeclaration.vue';
import ReviewingDocuments from '@/views/components/pages/on-boarding/template/kyc/verification-request/ReviewingDocuments.vue';
import StartOnBoarding from '@/views/components/pages/on-boarding/template/start/StartOnBoarding.vue';
import StartOnBoardingPh from '@/views/components/pages/on-boarding/template/start/StartOnBoardingPh.vue';
import RegulatoryTransition from '@/views/components/pages/on-boarding/template/RegulatoryTransition.vue';
import Email from '@/views/components/pages/on-boarding/template/Email.vue';
import ContactEmail from '@/views/components/pages/on-boarding/template/ContactEmail.vue';
import MobileNumber from '@/views/components/pages/on-boarding/template/MobileNumber.vue';
import ContactMobileNumber from '@/views/components/pages/on-boarding/template/ContactMobileNumber.vue';
import VerifyForm from '@/views/components/pages/on-boarding/template/VerifyForm.vue';
import Jumio from '@/views/components/pages/on-boarding/template/kyc/jumio/Jumio.vue';
import Sumsub from '@/views/components/pages/on-boarding/template/kyc/sumsub/Sumsub.vue';
import VerificationRequest from '@/views/components/pages/on-boarding/template/kyc/verification-request/VerificationRequest.vue';
import Signicat from '@/views/components/pages/on-boarding/template/kyc/signicat/Signicat.vue';
import ScreeningNl from '@/views/components/pages/on-boarding/template/screening/ScreeningNl.vue';
import VerifyCrucks from '@/views/components/pages/on-boarding/template/VerifyCrucks.vue';
import VerifyAge from '@/views/components/pages/on-boarding/template/verify-age/VerifyAge.vue';
import VerifyAgeWsop from '@/views/components/pages/on-boarding/template/verify-age/VerifyAgeWsop.vue';
import VerifyAgeDe from '@/views/components/pages/on-boarding/template/verify-age/VerifyAgeDe.vue';
import VerifyAgeUk from '@/views/components/pages/on-boarding/template/verify-age/VerifyAgeUk.vue';
import VerifyAgeMga from '@/views/components/pages/on-boarding/template/verify-age/VerifyAgeMga.vue';
import VerifyRgDepositSetting from '@/views/components/pages/on-boarding/template/VerifyRgDepositSetting.vue';
import VerifyRgLossSetting from '@/views/components/pages/on-boarding/template/VerifyRgLossSetting.vue';
import VerifyRgBalanceSetting from '@/views/components/pages/on-boarding/template/VerifyRgBalanceSetting.vue';
import VerifyRgLoginTimeSetting from '@/views/components/pages/on-boarding/template/VerifyRgLoginTimeSetting.vue';
import VerifyRgReLoginTimeSetting from '@/views/components/pages/on-boarding/template/VerifyRgReLoginTimeSetting.vue';
import VerifyRgLossCzSetting from '@/views/components/pages/on-boarding/template/VerifyRgLossCzSetting.vue';
import VerifyRgLossKrSetting from '@/views/components/pages/on-boarding/template/VerifyRgLossKrSetting.vue';
import NationalRegisterNumber from '@/views/components/pages/on-boarding/template/extra/NationalRegisterNumber.vue';
import VerifyRgTotalBetSetting from '@/views/components/pages/on-boarding/template/VerifyRgTotalBetSetting.vue';
import VerifyRgMonthlyLoginFrequencySetting from '@/views/components/pages/on-boarding/template/VerifyRgMonthlyLoginFrequencySetting.vue';
import TransUnion from '@/views/components/pages/on-boarding/template/TransUnion.vue';
import PaymentAccountVerification from '@/views/components/pages/on-boarding/template/payment-account-verification/PaymentAccountVerification.vue';
import VerifyCitizenship from '@/views/components/pages/on-boarding/template/verify-citizenship/VerifyCitizenship.vue';
import TemporaryAccountAcceptedDe from '@/views/components/pages/on-boarding/template/temp-account/TemporaryAccountAcceptedDe.vue';
import TemporaryAccountAcceptedPh from '@/views/components/pages/on-boarding/template/temp-account/TemporaryAccountAcceptedPh.vue';
import TemporaryAccountExpiredDe from '@/views/components/pages/on-boarding/template/temp-account/TemporaryAccountExpiredDe.vue';
import TemporaryAccountExpiredPh from '@/views/components/pages/on-boarding/template/temp-account/TemporaryAccountExpiredPh.vue';
import TemporaryAccountLoginDe from '@/views/components/pages/on-boarding/template/temp-account/TemporaryAccountLoginDe.vue';
import RequestMandatory from "@/views/components/pages/on-boarding/template/RequestMandatory.vue";
import Iban from "@/views/components/pages/on-boarding/template/Iban.vue";

export default () => ({
  step: 0,
  customStep: 0,
  customSteps: null,
  progress: 0,
  progressGroup: [],
  limitTime: 0,
  error: false,
  errorMsg: null,
  errorDesc: null,
  errorParams: null,
  target: 'onBoarding',
  verificationType: 'email',
  model: {
    FirstName: null,
    LastName: null,
    MaidenName: null,
    MiddleName: null,
    Alias: null,
    DateOfBirth: null,
    Gender: null,
    Nationality: null,
    NationalId: null,
    Email: null,
    MobileCountry: null,
    MobileCountryCode: null,
    PhoneCountryCode: null,
    MobileNumber: null,
    CustomRoutes: null,
    ContactEmail: null,
    ContactMobileNumber: null,
    ContactMobileCountryCode: null,
    PlaceOfBirth: null,
    CountryCode: null,
    CountryWritingSystem: null,
    Address: null,
    Building: null,
    BuildingName: null,
    BuildingNumber: null,
    HouseNumber: null,
    State: null,
    StateCode: null,
    City: null,
    PostalCode: null,
    Street: null,
    StreetName: null,
    District: null,
    IsSelfIdentificationDeclaration: null,
    Protection: null,
    VerificationCode: null,
    Day1Limit: null,
    Day7Limit: null,
    Day30Limit: null,
    IsUsedCustom: null,
    CityOfBirth: null,
    StateOfBirth: null,
    CountryOfBirth: null,
    SourceOfFunds: null,
    EmploymentType: null,
    IndustryType: null,
    OccupationType: null,
    EmployerName: null,
    DisposableIncome: null,
    DesiredLimitAmountDaily: null,
    DesiredLimitAmountWeekly: null,
    DesiredLimitAmountMonthly: null,
    RequestDailyLossLimit: null,
    RequestWeeklyLossLimit: null,
    RequestMonthlyLossLimit: null,
    RequestDailyLoginTimeLimit: null,
    RequestWeeklyLoginTimeLimit: null,
    RequestMonthlyLoginTimeLimit: null,
    RequestDailyTotalBetLimit: null,
    RequestWeeklyTotalBetLimit: null,
    RequestMonthlyTotalBetLimit: null,
    RequestReLoginTimeLimit: null,
    RequestReMonthlyLoginFrequencyLimit: null,
    RequestMonthlyLoginFrequency: null,
    RequestBalanceLimit: null,
    DeclarationIsPep: null,
    IsOnBehalfOf3rdParty: null,
    IsAgreeAccurateInfo: null,
    SendVerificationType: null,
    ExtraAddresses: null,
    IbanNumber: null,
    IsSamePermanentAddress: true,
    replace: false,
  },
  list: [
    /** RequestMandatory */
    {
      route: routeNames.requestMandatory,
      template: {
        default: RequestMandatory,
      },
      title: null,
      background: true,
      disabledBack: true,
    },
    /** PersonalDetailReaffirm */
    {
      route: routeNames.personalDetailReaffirm,
      template: {
        default: ReaffirmWsop,
        [siteIds.WSOPON]: ReaffirmWsop,
        [siteIds.GGPDE]: ReaffirmDe,
      },
      title: null,
      background: true,
      disabledBack: true,
    },
    /** VerifyCitizenship */
    {
      route: routeNames.verifyCitizenship,
      template: {
        default: VerifyCitizenship,
      },
      title: 'onBoarding.title.verificationRequest',
      background: true,
      disabledBack: true,
    },
    /** Personal */
    {
      route: routeNames.personal,
      template: {
        default: PersonalOk,
        [siteIds.GGPOK]: PersonalOk,
        [siteIds.GGPPH]: PersonalPh,
      },
      title: 'onBoarding.personal.title',
      extra: {},
      footer: {
        [siteIds.GGPPH]: { template: FooterPh },
      },
      buttonName: {
        default: 'confirm',
        [siteIds.GGPOK]: 'confirm',
        [siteIds.GGPPH]: 'submit',
      },
      buttonTag: {
        default: '',
      },
      buttonClass: '',
    },
    /** PersonalName */
    {
      route: routeNames.personalName,
      title: 'onBoarding.title.personalDetails',
      template: PersonalNames,
      service: {
        default: 'onboarding',
      },
      api: 'editPersonalName',
      extra: {
        [siteIds.GGPCZ]: { template: LastNameAtBirth },
        [siteIds.GGPDE]: { template: LastNameAtBirth },
        [siteIds.WSOPON]: { template: OptionalName },
        [siteIds.OCNP]: { template: MiddleName },
      },
      captcha: true,
      footer: {
        [siteIds.GGPPH]: { template: FooterPh },
      },
      buttonTag: {
        default: 'Personal_Details_Name_Button',
        [siteIds.WSOPON]: 'WSOPca_Step12_Personal_Details_Name_Button',
      },
      buttonClass: 'kyc-personal-details-btn',
    },
    /** PersonalBirth */
    {
      route: routeNames.personalBirth,
      title: 'onBoarding.title.personalDetails',
      template: {
        default: PersonalBirth,
        [siteIds.GGPRO]: Cnp,
        [siteIds.GGPCZ]: BirthCz,
      },
      service: {
        default: 'onboarding',
      },
      api: 'editPersonalBirth',
      captcha: {
        [siteIds.GGPDE]: true,
      },
      extra: {
        [siteIds.GGPCOM]: { template: GenderSelector },
        [siteIds.GGPNG]: { template: GenderSelector },
        [siteIds.GGPUA]: { template: GenderSelector },
        [siteIds.GGPJP]: { template: GenderSelector },
        [siteIds.GGPBR]: { template: GenderSelector },
        [siteIds.GGPPL]: { template: GenderSelector },
        [siteIds.GGPCW]: { template: GenderSelector },
        [siteIds.GGPDK]: { template: GenderSelector },
        [siteIds.GGPFI]: { template: GenderSelector },
        [siteIds.GGPHU]: { template: GenderSelector },
        [siteIds.GGPUK]: { template: GenderSelector },
        [siteIds.GGPEU]: { template: GenderSelector },
        [siteIds.GGPOK]: { template: GenderSelector },
        [siteIds.GGPBE]: [{ template: GenderSelector }, { template: NationalRegisterNumber }],
        [siteIds.WSOPON]: [{ template: ContactMobileNumber }, { template: Citizenship }],
        [siteIds.GGPDE]: [{ template: PlaceOfBirth }, { template: GenderSelector }, { template: Citizenship }],
        [siteIds.GGPNL]: [{ template: PlaceOfBirth }, { template: GenderSelector }, { template: Bsn }, { template: ContactMobileNumber }, { template: Citizenship }],
        [siteIds.GGPPH]: [{ template: GenderSelector }, { template: PlaceOfBirth }, { template: ContactMobileNumber }, { template: Citizenship }],
        [siteIds.N8IN]: [{ template: ContactMobileNumber }],
        [siteIds.OCNP]: { template: Citizenship },
      },
      footer: {
        [siteIds.GGPPH]: { template: FooterPh },
      },
      buttonTag: {
        default: 'Date_of_Birth_Button',
        [siteIds.WSOPON]: 'WSOPca_Step14_Date_of_Birth_Button',
      },
      buttonClass: 'kyc-date-of-birth-btn',
    },
    /** PersonalAddress */
    {
      route: routeNames.personalAddress,
      title: 'onBoarding.title.address',
      template: {
        default: AddressCommon,
        [siteIds.GGPCZ]: AddressCz,
        [siteIds.GGPDE]: AddressDe,
        [siteIds.GGPPH]: AddressPh,
        [siteIds.GGPUK]: AddressUk,
        [siteIds.WSOPON]: AddressWsop,
        [siteIds.OCNP]: AddressOcnp,
        [siteIds.GGPOK]: AddressOk,
      },
      service: {
        default: 'onboarding',
      },
      api: 'editPersonalAddress',
      footer: {
        [siteIds.GGPPH]: { template: FooterPh },
      },
      background: true,
      buttonTag: {
        default: 'Address_Button',
        [siteIds.WSOPON]: 'WSOPca_Step16_Address_Button',
      },
      buttonClass: 'kyc-address-btn',
    },
    /** PersonalExtraAddress */
    {
      route: routeNames.personalExtraAddress,
      title: 'onBoarding.title.permanentAddress',
      template: {
        default: ExtraAddressCommon,
        [siteIds.GGPPH]: ExtraAddressPh,
      },
      service: {
        default: 'onboarding',
      },
      background: true,
      api: 'editPersonalExtraAddress',
      footer: {
        [siteIds.GGPPH]: { template: FooterPh },
      },
      buttonClass: 'kyc-address-btn',
    },
    /** PersonalContactAddress */
    // { route: routeNames.homeAddress, title: 'onBoarding.title.homeAddress', template: HomeAddress, api: 'editPersonalExtraAddress', buttonName: 'next', },
    // { route: routeNames.mailingAddress, title: 'onBoarding.title.mailingAddress', template: MailingAddress, api: 'editPersonalExtraAddress', buttonName: 'next', },
    // { route: routeNames.dataBox, title: 'onBoarding.title.dataBox', template: DataBox, api: 'editPersonalExtraAddress', buttonName: 'next', },
    // { route: routeNames.completeContactAddress, title: 'onBoarding.title.contactAddressUpdate', template: CompleteContactAddress, api: '', buttonName: 'complete', },
    /** PersonalSelfDeclaration */
    {
      route: routeNames.personalSelfDeclaration,
      title: 'onBoarding.title.selfIdentification',
      template: PersonalSelfDeclaration,
      service: {
        default: 'onboarding',
      },
      api: 'updatePersonalSelfDeclaration',
    },
    /** PersonalOccupation */
    {
      route: routeNames.personalOccupation,
      title: {
        default: 'onBoarding.title.occupation',
        [siteIds.GGPPH]: 'onBoarding.title.sourceOfFunds'
      },
      template: {
        default: OccupationWsop,
        [siteIds.WSOPON]: OccupationWsop,
        [siteIds.GGPUK]: OccupationUk,
        [siteIds.GGPPH]: OccupationPh,
      },
      keys: ['OccupationType'],
      service: {
        default: 'onboarding',
      },
      api: 'editPersonalOccupation',
      buttonTag: {
        [siteIds.WSOPON]: 'WSOPca_Step18_Occupation_Button',
      },
      footer: {
        [siteIds.GGPPH]: { template: FooterPh },
      },
      buttonClass: 'kyc-occupation-btn',
    },
    /** ReCheckPersonalDetails */
    {
      route: routeNames.personalDetailsRecheck,
      template: {
        default: ReCheckDe,
        [siteIds.GGPDE]: ReCheckDe,
      },
      title: 'reCheckPersonalDetails',
      background: true,
      disabledBack: true,
    },
    /** TemporaryAccountLogin */
    {
      route: routeNames.temporaryAccountLogin,
      title: {
        [siteIds.GGPDE]: 'onBoarding.title.temporaryAccountLoginDe'
      },
      template: {
        [siteIds.GGPDE]: TemporaryAccountLoginDe,
      },
      background: true,
      disabledBack: true,
    },
    /** TemporaryAccountExpired */
    {
      route: routeNames.temporaryAccountExpired,
      title: {
        [siteIds.GGPDE]: 'onBoarding.title.temporaryAccountExpiredDe',
        [siteIds.GGPPH]: 'onBoarding.title.temporaryAccountExpiredPh'
      },
      template: {
        [siteIds.GGPDE]: TemporaryAccountExpiredDe,
        [siteIds.GGPPH]: TemporaryAccountExpiredPh,
      },
      buttonName: 'completeVerification',
      disabledBack: true,
    },
    /** TemporaryAccountAccepted */
    {
      route: routeNames.temporaryAccountAccepted,
      title: {
        [siteIds.GGPDE]: 'onBoarding.title.temporaryAccountAcceptedDe',
        [siteIds.GGPPH]: 'onBoarding.title.temporaryAccountAcceptedPh'
      },
      template: {
        [siteIds.GGPDE]: TemporaryAccountAcceptedDe,
        [siteIds.GGPPH]: TemporaryAccountAcceptedPh,
      },
      background: true,
      disabledBack: true
    },
    /** Sumsub */
    { route: routeNames.sumsub, title: 'onBoarding.title.identityVerification', template: Sumsub, background: true, disabledBack: true },
    /** Jumio */
    { route: routeNames.jumio, title: 'onBoarding.title.kycVerification', template: Jumio, background: true, disabledBack: true },
    { route: routeNames.showReviewingKycMessage, title: 'onBoarding.title.kycVerification', template: Jumio, background: true, disabledBack: true },
    /** TransUnion */
    { route: routeNames.transUnion, title: null, template: TransUnion, background: true, disabledBack: true },
    /** VerifyFundProtectionAcceptance */
    {
      route: routeNames.verifyFundProtectionAcceptance,
      title: 'onBoarding.title.fundProtectionPolicy',
      template: FundProtectionPolicy,
      service: {
        default: 'onboarding',
      },
      api: 'updateFundProtectionAcceptanceStatus',
      disabledBack: true,
    },
    /** VerifyAge */
    {
      route: routeNames.verifyAge,
      title: null,
      template: {
        default: VerifyAge,
        [siteIds.WSOPON]: VerifyAgeWsop,
        [siteIds.GGPDE]: VerifyAgeDe,
        [siteIds.GGPUK]: VerifyAgeUk,
        [siteIds.GGPEU]: VerifyAgeMga,
        [siteIds.GGPFI]: VerifyAgeMga,
        [siteIds.GGPHU]: VerifyAgeMga,
      },
      service: {
        default: 'onboarding',
      },
      api: 'verifyAge',
      background: true,
      disabledBack: true,
    },
    /** VerifyEmail */
    {
      route: routeNames.verifyEmail,
      title: 'onBoarding.title.accountVerification',
      template: Email,
      service: {
        default: 'verification',
      },
      api: 'sendVerification',
      action: 'EmailVerifySendCode',
      captcha: {
        [siteIds.GGPRO]: true,
        [siteIds.GGPUK]: true,
      },
      background: true,
    },
    /** VerifyMobileNumber */
    {
      route: routeNames.verifyMobileNumber,
      title: 'onBoarding.title.accountVerification',
      template: MobileNumber,
      service: {
        default: 'verification',
      },
      api: 'sendVerification',
      action: 'MobileVerifySendCode',
      captcha: {
        [siteIds.GGPRO]: true,
        [siteIds.GGPUK]: true,
      },
    },
    /** ContactEmail */
    {
      route: routeNames.contactEmail,
      title: 'onBoarding.title.accountVerification',
      template: Email,
      service: {
        default: 'verification',
      },
      api: 'sendVerification',
      action: 'EmailVerifySendCode',
      captcha: {
        [siteIds.GGPRO]: true,
        [siteIds.GGPUK]: true,
      },
      background: true,
    },
    /** ContactMobileNumber */
    {
      route: routeNames.contactMobileNumber,
      title: 'onBoarding.title.accountVerification',
      template: ContactMobileNumber,
      service: {
        default: 'verification',
      },
      api: 'sendVerification',
      action: 'MobileVerifySendCode',
      captcha: {
        [siteIds.GGPRO]: true,
        [siteIds.GGPUK]: true,
      },
    },
    /** VerifyContactEmail */
    {
      route: routeNames.verifyContactEmail,
      title: 'onBoarding.title.accountVerification',
      template: ContactEmail,
      service: {
        default: 'verification',
      },
      api: 'sendVerification',
      action: 'EmailVerifySendCode',
      captcha: {
        [siteIds.GGPRO]: true,
        [siteIds.GGPUK]: true,
      },
      background: true,
    },
    /** VerifyContactMobileNumber */
    {
      route: routeNames.verifyContactMobileNumber,
      title: 'onBoarding.title.accountVerification',
      template: ContactMobileNumber,
      service: {
        default: 'verification',
      },
      api: 'sendVerification',
      action: 'MobileVerifySendCode',
      captcha: {
        [siteIds.GGPRO]: true,
        [siteIds.GGPUK]: true,
      },
    },
    /** VerifyCode */
    {
      route: routeNames.verifyCode,
      title: 'onBoarding.title.accountVerification',
      template: VerifyForm,
      service: {
        default: 'verification',
      },
      api: 'verifyCode',
      footer: {
        [siteIds.GGPRO]: [{ template: FooterRo }],
      },
      background: true,
      disabledBack: true,
    },
    /** Poi */
    { route: routeNames.poi, title: 'onBoarding.title.verificationRequest', template: VerificationRequest, background: true, disabledBack: true },
    /** Poa */
    { route: routeNames.poa, title: 'onBoarding.title.verificationRequest', template: VerificationRequest, background: true, disabledBack: true },
    /** Edd */
    { route: routeNames.edd, title: 'onBoarding.title.verificationRequest', template: VerificationRequest, background: true, disabledBack: true },
    /** StartOnboarding */
    {
      route: routeNames.startOnboarding,
      template: {
        default: StartOnBoarding,
        [siteIds.GGPCZ]: StartOnBoarding,
        [siteIds.GGPPH]: StartOnBoardingPh,
      },
      title: {
        default: null,
        [siteIds.GGPCZ]: null,
        [siteIds.GGPPH]: 'onBoarding.title.mandatoryGuide'
      },
      extra: {},
      footer: {
        [siteIds.GGPPH]: { template: FooterPh },
      },
      buttonTag: {
        default: '',
      },
      buttonClass: '',
      background: true,
    },
    /** RegulatoryTransition */
    { route: routeNames.regulatoryTransition, title: 'onBoarding.title.regulatoryTransition', template: RegulatoryTransition, buttonName: 'dataValidation' },
    /** Payletter */
    { route: routeNames.payletter, title: null, template: Payletter, background: true },
    /** PersonalPep */
    {
      route: routeNames.personalPep,
      title: 'onBoarding.title.selfIdentification',
      template: PersonalPep,
      keys: ['IsAgreeAccurateInfo', 'IsOnBehalfOf3rdParty'],
      service: {
        default: 'onboarding',
      },
      api: 'updatePersonalPep',
      buttonTag: {
        [siteIds.WSOPON]: 'WSOPca_Step20_PEP_Button',
      },
      buttonClass: 'kyc-pep-btn',
    },
    /** PaymentAccountVerification */
    { route: routeNames.paymentAccountVerification, title: 'onBoarding.title.paymentAccountVerification', template: PaymentAccountVerification, background: true, disabledBack: true },
    /** VerifyRgDepositSetting */
    {
      route: routeNames.verifyRgDepositSetting,
      title: 'onBoarding.title.depositLimit',
      template: VerifyRgDepositSetting,
      service: {
        default: 'onboarding',
      },
      api: 'updateDepositLimit',
      disabledBack: true,
      buttonTag: {
        [siteIds.WSOPON]: 'WSOPca_Step23_Deposit_Limit_Button',
      },
      buttonClass: 'responsible-deposit-limit-btn',
    },
    /** VerifyRgLossSetting */
    {
      route: routeNames.verifyRgLossSetting,
      title: 'onBoarding.title.lossLimit',
      template: VerifyRgLossSetting,
      keys: [],
      service: {
        default: 'onboarding',
      },
      api: 'updateLossLimit',
      disabledBack: true,
      buttonTag: {
        [siteIds.WSOPON]: 'WSOPca_Step25_Loss_Limit_Button',
      },
      buttonClass: 'responsible-loss-limit-btn',
    },
    /** VerifyRgLossCzSetting */
    {
      route: routeNames.verifyRgLossCzSetting,
      title: 'onBoarding.title.lossLimit',
      template: VerifyRgLossCzSetting,
      service: {
        default: 'onboarding',
      },
      api: 'updateLossLimit',
      disabledBack: true,
    },
    /** VerifyRgLossKrSetting */
    { route: routeNames.verifyRgLossKrSetting, title: null, template: VerifyRgLossKrSetting, background: true, disabledBack: true },
    /** VerifyRgBalanceSetting */
    {
      route: routeNames.verifyRgBalanceSetting,
      title: { default: 'onBoarding.title.balanceLimit', [siteIds.GGPNL]: 'responsibleGaming.title.maximumBalance' },
      template: VerifyRgBalanceSetting,
      service: {
        default: 'onboarding',
      },
      api: 'updateBalanceLimit',
      disabledBack: true,
    },
    /** VerifyRgLoginTimeSetting */
    {
      route: routeNames.verifyRgLoginTimeSetting,
      // title: 'onBoarding.title.loginTime',
      title: { default: 'onBoarding.title.playTime', [siteIds.GGPNL]: 'onBoarding.title.loginTime' },
      template: VerifyRgLoginTimeSetting,
      service: {
        default: 'onboarding',
      },
      api: 'updateLoginTimeLimit',
      disabledBack: true,
    },
    /** VerifyRgReLoginTimeSetting */
    {
      route: routeNames.verifyRgReLoginTimeSetting,
      title: 'onBoarding.title.reLoginTime',
      template: VerifyRgReLoginTimeSetting,
      service: {
        default: 'onboarding',
      },
      api: 'updateReLoginTimeLimit',
      disabledBack: true,
    },
    /** VerifyRgTotalBetSetting */
    {
      route: routeNames.verifyRgTotalBetSetting,
      title: 'onBoarding.title.totalBetLimit',
      template: VerifyRgTotalBetSetting,
      service: {
        default: 'onboarding',
      },
      api: 'updateTotalBetLimit',
    },
    /** VerifyRgMonthlyLoginFrequencySetting */
    {
      route: routeNames.verifyRgMonthlyLoginFrequencySetting,
      title: 'onBoarding.title.monthlyLoginFrequency',
      template: VerifyRgMonthlyLoginFrequencySetting,
      service: {
        default: 'onboarding',
      },
      api: 'updateRgMonthlyLoginFrequency',
    },
    /** Signicat */
    { route: routeNames.signicat, title: 'onBoarding.title.verificationRequest', template: Signicat, background: true, disabledBack: true },
    /** Screening */
    {
      route: routeNames.screening,
      template: {
        default: ScreeningNl,
        [siteIds.GGPNL]: ScreeningNl,
      },
      title: null,
      background: true,
      disabledBack: true,
    },
    /** SurePay */
    {
      route: routeNames.verifySurePay,
      title: 'onBoarding.title.ibanVerification',
      template: Iban,
      service: {
        default: 'kyc',
      },
      api: 'verifySignicatSurePay',
      background: true,
    },
    /** VerifyCrucks */
    { route: routeNames.verifyCrucks, title: '', template: VerifyCrucks, background: true },
    /** KycDocumentManual */
    { route: routeNames.kycDocumentManual, title: 'onBoarding.title.verificationRequest', template: VerificationRequest, background: true, disabledBack: true },
    /** VerifyFundProtectionAcceptance */
    { route: routeNames.verifyFundProtectionAcceptance, title: '', template: FundProtectionPolicy },
    /** Lugas */
    {
      route: routeNames.lugas,
      title: 'onBoarding.title.crossProviderDepositLimit',
      template: Lugas,
      service: {
        default: 'account',
      },
      api: 'setLugasCentralFileLimit',
      background: true,
    },
    /** ConfirmPersonalInfo */
    { route: routeNames.confirmPersonalInfo, title: 'onBoarding.title.personalDetails', template: ConfirmPersonalInfo },
    /** ShowReviewingKycMessage */
    { route: routeNames.reviewingDocuments, title: 'onBoarding.title.reviewingDocuments', buttonName: 'close', template: ReviewingDocuments },
    /** KycDE */
    { route: routeNames.kycDE, title: '', template: KycDe, background: true },
    /** PoiSelfie */
    // { route: routeNames.poiSelfieReady, title: null, template: PoiSelfieReady, background: true },
    // { route: routeNames.poiSelfie, title: null, template: ProofOfIdentitySelfie, background: true },
    { route: 'Replace' },
  ],
});
