<template>
  <Slider bonus-slider v-if="info" ref="refSlider" :info="info" slides-per-view="auto">
    <template v-slot:slide="{info}">
      <div :class="['slide', {active: getActive(info)}]">
        <p class="day">{{ $t('promotion.day', { day: info.day }) }}</p>
        <BonusItem v-for="item in info.items" :info="item" :active="getActive(info)" />
      </div>
    </template>
  </Slider>
</template>

<script>
import BonusItem from '@/views/components/pages/promotion/BonusItem.vue';
import Slider from '@shared/components/general/Slider.vue';
import Specific from '@shared/types/Specific';
import { sleep } from '@shared/utils/commonUtils.mjs';

export default {
  name: 'BonusSlider',
  components: { Slider, BonusItem },
  props: {
    info: Specific,
    day: Number,
  },
  methods: {
    getActive(info) {
      return info.day <= this.day;
    },
    async goNowSlide() {
      if (!this.day) return;
      await sleep(300);
      this.$refs.refSlider?.go(this.day - 1);
    },
  },
  mounted() {
    this.goNowSlide();
  },
};
</script>

<style lang="less">
@import "@/less/tpm";
[bonus-slider] { .tc(); .max-h(298); .crop(); .scroll();
  .swiper-slide { .w(); .min-w(154); .h();
    + .swiper-slide { .-start(#262626); }

  }
  .slide { .flex(); .flex-dc(); justify-content: stretch; .items-center(); .gap(20); .p(20, 8);
    .day { .c(#888); .semi-bold(); }
    &.active {
      .day { .c(@c-white); }
    }
  }
}
</style>
