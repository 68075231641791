import { sleep } from '@shared/utils/commonUtils.mjs';
import { close } from '@/utils/clientUtil';
import { siteIds } from '@/constants/base/siteMap.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import {jumioStatus, jumioStep, jumioType, kycStatus} from '@/constants/base/onboarding/kyc';
import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';
import { JumioDocuments } from '@/plugins/jumioController/JumioDocuments';

export default class JumioController {
  #scope;
  #services;
  #modelInfo;
  #onboardingController;
  #site;
  #lang;
  #callback;
  #isDe;
  #step;
  #status;
  #requestType;
  #customSteps;
  #url;
  #playerStamp;
  #transactionId;
  #kycRequestMessage;
  #isManuallyRequested;
  #documentList;
  #document;
  #intervalMilliSeconds;
  #currentPollingCount;
  #limitPollingCount;
  #receiveInfo;
  #kycUploadStatus;
  #kycStatus;
  #considerKyc;

  #JumioDocuments;

  constructor(scope, { services, modelInfo, onboardingController, site, lang, callback }) {
    this.#scope = scope;
    this.#services = services;
    this.#modelInfo = modelInfo;
    this.#onboardingController = onboardingController;
    this.#site = site;
    this.#lang = lang;
    this.#callback = callback;
    this.#isDe = this.#site === siteIds.GGPDE;
    this.#intervalMilliSeconds = 5000;
    this.#limitPollingCount = 100;
    this.#JumioDocuments = new JumioDocuments(site);

    this.restart();
  }

  /**
   * Kyc upload status 정보 확인 api (업로드 해야 하는 document list 확인)
   * @param IsConsiderKycStatusWhenKycNotRequested
   * @returns {Promise<PlayerKycStatus>}
   */
  async #getPlayerKycUploadStatus() {
    const restrict = this.#services.store.state.query.restrict;
    const additional = this.#services.store.state.query.additional;
    const isConsiderKyc = this.#scope.$route.params?.isConsiderKyc;
    const userInfo = this.#services.store.state.user.userInfo;

    const considerKyc = !!additional || isConsiderKyc || userInfo.KycRequestType === 'None';

    const r = await this.#services.kyc.getPlayerKycUploadStatus({ Restrict: restrict, IsConsiderKycStatusWhenKycNotRequested: considerKyc});
    r?.error && await this.#errorHandler(r);
    return r;
  }

  /**
   * KYC 레벨과 트리거 정보를 반환
   * @returns {Promise<PlayerKycStatus>}
   */
  async #getPlayerKycStatus() {
    const r = await this.#services.kyc.getPlayerKycStatus();
    r?.error && await this.#errorHandler(r);
    return r;
  }
  /**
   * Jumio step 조회
   * @returns {Promise<JumioDocuments>}
   */
  async #getStep() {
    const path = this.#services.store.state.env.env === 'local' ? '/onboarding' : '/app';
    const redirectUrl = `${location.origin}${path}/receiver-jumio`;
    // const redirectUrl = `https://onboarding-ggpokeruk-test.ggcore.net${path}/receiver-jumio`; // 로컬에서 확인 시 필요
    const lang = this.#services.cookie.getLanguage();
    const r = await this.#services.kyc.getJumioStep({ Language: lang, DocumentType: this.#document?.jumioType, RedirectUri: redirectUrl });
    r?.error && await this.#errorHandler(r);
    if(r.Step === jumioStep.Reviewing) this.#limitPollingCount = 0;
    return r;
  }
  /**
   * Identify 상태 확인
   * @param PlayerStamp
   * @param TransactionId
   * @returns {Promise<KycIdentityStatus>}
   */
  async #getIdentityStatus() {
    const r = await this.#services.kyc.patchKycIdentity({ PlayerStamp: this.#playerStamp, TransactionId: this.#transactionId });
    r?.error && await this.#errorHandler(r);
    return r;
  }
  /**
   * Document 상태 확인
   * @returns {Promise<KycDocumentStatus>}
   */
  async #getDocumentStatus() {
    if(!this.#playerStamp || !this.#transactionId) return {};
    const r = await this.#services.kyc.patchKycDocument({ PlayerStamp: this.#playerStamp, TransactionId: this.#transactionId });
    r?.error && await this.#errorHandler(r);
    return r;
  }
  /**
   * 플레이어의 개인정보를 반환
   * @returns {Promise<void>}
   */
  async #putMyInfo() {
    await this.#onboardingController.injectionMyInfo();
  }
  /**
   * error 발생 시 에러 코드 별 처리 분기
   * @param {object} r - error 정보
   */
  async #errorHandler(r) {
    this.#clearPolling();
    switch (r.key) {
      case apiErrorCode.KYC_UPLOAD_REQUIRED:
        await this.#putMyInfo();
        // structure.model.pepType 바뀌는지 확인 필요
        if (this.#modelInfo.pepType.includes('PEP') || this.#modelInfo.pepType.includes('Sanktionen')) this.#setInfo(jumioStep.PepFailed);
        break;
      case apiErrorCode.OASIS_BAN_EXIST:
        this.#setInfo(jumioStep.OasisFailed);
        break;
      case apiErrorCode.FAILED_SELF_EXCLUSION_CHECK:
        this.#setInfo(jumioStep.SelfExclusionFailed);
        break;
      default:
        this.#setInfo(jumioStep.Failed);
        break;
    }
    this.#callback(this.#getInfo());
  }

  /**
   * 진행중인 jumio 현황 정보 반환
   * @returns {{ lang, step, status, isDe, customSteps }}
   */
  #getInfo() {
    return { lang: this.#lang, step: this.#step, status: this.#status, requestType: this.#requestType, isDe: this.#isDe, url: this.#url, kycRequestMessage: this.#kycRequestMessage, kycStatus: this.#kycStatus, isManuallyRequested: this.#isManuallyRequested, customSteps: this.#customSteps, documentList: this.#documentList, document: this.#document };
  }
  /**
   * jumio step, status, process 정보 저장
   * @param {string|null} step - 설정할 jumio step
   * @param {string|null} status - 설정할 jumio status
   */
  #setInfo(step, status = null) {
    if (step !== this.#step) this.#step = step;
    if (status !== this.#status) this.#status = status;
  }

  async #setPlayerKycUploadStatus() {
    const r = await this.#getPlayerKycUploadStatus();
    if (r?.error) return;
    this.#kycUploadStatus = r;
  }

  async #setPlayerKycStatus() {
    const r = await this.#getPlayerKycStatus();
    if (r?.error) return;
    this.#kycStatus = r;
  }
  /**
   * 예외 발생으로 Reaffirm Step이 존재할 경우 저장
   */
  async #setExpireSteps() {
    const expireOrder = await this.#onboardingController.getExpireOrder();
    this.#customSteps = expireOrder;
  }
  /**
   * Polling 초기화
   */
  #clearPolling() {
    this.#currentPollingCount = 0;
  }
  /**
   * POI 문서 처리 상태 조회
   * 총 100회 재시도 하여 상태 Polling
   * @returns {Promise<void>}
   */
  async #pollingIdentityStatus() {
    if (this.#currentPollingCount <= this.#limitPollingCount) {
      this.#currentPollingCount++;
      await sleep(this.#intervalMilliSeconds);
      await this.#callbackStatus(jumioStep.Identity);
    } else {
      this.#setInfo(jumioStep.Failed, this.#status);
      this.#callback(this.#getInfo());
    }
  }

  /**
   * jumio 인증 step 값에 따른 화면 전환 처리
   */
  async #callbackStep() {
    this.#url = null; // step 갱신 시 url 초기화

    let r;
    r = await this.#getStep();
    if (r?.error) return;
    let step = r.Step;
    let status = this.#status;
    this.#url = r.Url;
    this.#playerStamp = r.PlayerStamp;
    this.#transactionId = r.TransactionId;
    this.#kycRequestMessage = r.KycRequestMessage;
    this.#isManuallyRequested = r.IsManuallyRequested;

    if (this.#modelInfo.RemainingKycExpirationDays && this.#modelInfo.RemainingKycExpirationDays <= 30 && step === jumioStep.Done) step = jumioStep.Identity;

    if (this.#step !== step) status = jumioStatus.None;

    switch (step) {
      case jumioStep.Document:
        if ([siteIds.GGPDE, siteIds.GGPUK].includes(this.#site)) {
          await this.#setPlayerKycUploadStatus();
          await this.#setPlayerKycStatus();

          const kycDocumentGroup = this.#kycUploadStatus?.KycDocumentGroup?.find(doc => doc.KycDocumentGroupType === kycStatus.Poa);
          const documentList = this.#JumioDocuments.getDocumentsByIncludesValues(kycDocumentGroup?.DefaultKycDocuments);
          if (JSON.stringify(documentList) !== JSON.stringify(this.#documentList)) {
            this.#documentList = documentList;
            this.#document = this.#documentList?.[0];
            await this.#callbackStep();
            return;
          }
        }
        break;
      case jumioStep.Identity:
        break;
      case jumioStep.Done:
        status = jumioStatus.Done;
        r = await this.#putMyInfo();
        if (this.#isDe && this.#services.store.state.query.customStep === routeNames.personalDetailReaffirm) await this.#setExpireSteps();
        break;
      default:
        step = jumioStep.Reviewing;
        break;
    }

    this.#setInfo(step, status);
    this.#callback(this.#getInfo());
  }
  /**
   * jumio 인증 status 값에 따른 화면 전환 처리
   * @param step
   * @returns {Promise<void>}
   */
  async #callbackStatus(step) {
    console.log('%cjumio ccontroller #callbackStatus', 'color:yellow', step);
    let r;
    let status = this.#status;
    switch (step) {
      case jumioStep.Identity:
        r = await this.#getIdentityStatus();
        if (r?.error) return;
        this.#requestType = 'POI, POI(Selfie)';
        status = r.Status;
        switch (status) {
          case jumioStatus.Verified:
            this.#callbackStep();
            break;
          case jumioStatus.Pending:
            this.#pollingIdentityStatus();
            break;
          case jumioStatus.DocumentInvalid:
            step = jumioStep.Failed;
            if (this.#site !== siteIds.WSOPON) status = null;
            break;
          case jumioStatus.DocumentExpired:
          default:
            step = jumioStep.Failed;
            break;
        }
        break;
      case jumioStep.Document:
        r = await this.#getDocumentStatus();
        if (r?.error) return;
        this.#requestType = 'POA';
        status = r.Status;
        switch (status) {
          case jumioStatus.Verified:
          case jumioStatus.Done:
            await this.#callbackStep();
            break;
          default:
            step = jumioStep.Failed;
            break;
        }
        break;
      case jumioStep.Done:
        break;
      default:
        status = jumioStep.Failed;
        break;
    }

    this.#url = null;
    this.#setInfo(step, status);
    this.#callback(this.#getInfo());
  }

  /**
   * 초기화
   * @returns {Promise<void>}
   */
  async #initialize() {
    this.#clearPolling();
    this.#step = jumioStep.None;
    this.#status = jumioStatus.None;
    this.#requestType = null;
    this.#url = null;
    this.#playerStamp = null;
    this.#transactionId = null;
    this.#kycRequestMessage = null;
    this.#isManuallyRequested = null;
    this.#documentList = this.#JumioDocuments.getDocumentsByIncludesTypes([jumioType.BS]);
    this.#document = this.#documentList?.[0];
    this.#receiveInfo = null;
    this.#kycStatus = null;
  }
  /**
   * logout 처리를 하고 창을 닫아줌
   * @returns {Promise<void>}
   */
  async #close() {
    const r = await this.#services.sign.logout({ useRedirect: false });
    if (r?.error) return;
    close(this.#scope);
  }
  /**
   * jumio 결과 처리
   * @param {string} transactionId
   * @param {string} result
   * @returns {boolean}
   */
  async #result({ transactionId, result }) {
    console.log('%cjumio controller #result', 'color:yellow', transactionId, result);
    this.#receiveInfo = result;
    const isSuccess = this.#receiveInfo === 'SUCCESS';
    if (!isSuccess) await this.restart();
    else {
      this.#setInfo(this.#step, jumioStatus.Pending);
      this.#callback(this.#getInfo());

      try {
        if (this.#step === jumioStep.Document) await this.#callbackStatus(this.#step);
        else if (this.#step === jumioStep.Identity && this.#transactionId === transactionId) await this.#callbackStatus(this.#step);
      } catch (e) {
        console.log('message EventListener error', e);
        return false;
      }
    }
  }

  /**
   * jumio 단계 초기화
   */
  async restart() {
    await this.#initialize().then(async () => {
      await this.#setPlayerKycStatus();
      await this.#callbackStep();
    });
  }
  /**
   * jumio Document 선택 시 정보 업데이트
   */
  async changeDocument(document) {
    if (this.#document === document) return;

    this.#document = document;
    await this.#callbackStep();
  }
  /**
   * callback jumio 결과 처리
   * @param {string} transactionId
   * @param {string} result
   */
  async result({ transactionId, result }) {
    this.#result({ transactionId, result });
  }
  /**
   * 로그아웃 후 창 닫기
   * @returns {Promise<void>}
   */
  async close() {
    await this.#close();
  }
}
