<template>
  <div input-security-level>
    <RadioList type="vertical" :custom-radio="SecurityLevelRadioItem" :list="levelList" v-model="model.SecurityLevel" @input="setSecurityLevel" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import RadioList from '@/views/components/gg-pass/RadioList.vue';
import SecurityLevelRadioItem from '@/views/components/pages/my-page/template/login-securty/SecurityLevelRadioItem.vue';
import { mfaLevel, mfaMethod, mfaSecurityType } from '@/constants/base/my-page';

export default {
  name: 'InputSecurityLevel',
  lexicon: 'myInfo',
  components: { RadioList },
  props: {
    model: Specific,
  },
  data() {
    return {
      SecurityLevelRadioItem,
    };
  },
  computed: {
    levelList() {
      return [
        { value: mfaLevel.OFF, label: this.$t('_.off'), content: this.$t('_.offDesc'), isBadge: true, methods: [] },
        { value: mfaLevel.LOW, label: this.$t('_.low'), content: this.$t('_.lowDesc') },
        // { value: mfaLevel.MEDIUM, label: this.$t('_.medium'), content: this.$t('_.mediumDesc') },
        { value: mfaLevel.HIGH, label: this.$t('_.high'), content: this.$t('_.highDesc'), },
      ];
    },
    securityType() {
      return {
        [mfaLevel.OFF]: { UsualLoginSecurity: mfaSecurityType.Off, UnusualLoginSecurity: mfaSecurityType.Off },
        [mfaLevel.LOW]: { UsualLoginSecurity: mfaSecurityType.Off, UnusualLoginSecurity: mfaSecurityType.Notification },
        // [mfaLevel.MEDIUM]: { UsualLoginSecurity: mfaSecurityType.Off, UnusualLoginSecurity: mfaSecurityType.Verification },
        [mfaLevel.HIGH]: { UsualLoginSecurity: mfaSecurityType.Verification, UnusualLoginSecurity: mfaSecurityType.Verification }
      };
    },
  },
  methods: {
    setSecurityLevel(level) {
      this.model.SecurityLevel = level;
      this.setSecurityType(level);
      this.$emit('initMethod');
    },
    setSecurityType(level) {
      const { UsualLoginSecurity, UnusualLoginSecurity } = this.securityType[level];
      this.model.UsualLoginSecurity = UsualLoginSecurity;
      this.model.UnusualLoginSecurity = UnusualLoginSecurity;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-security-level] { .flex(); .flex-dc(); .gap(15); .w(100%);}
</style>