<template>
  <div payment-account-verification>
    <div class="bts-holder">
      <button :class="{'active' : !activeId}" @click="change(0)"><FontIcon name="dollar-sign"/>{{ $t('_.bankAccount') }}</button>
      <button :class="{'active' : activeId}" @click="change(1)"><FontIcon name="credit-card"/>{{ $t('_.creditCard') }}</button>
    </div>
    <BankAccount v-if="!activeId" @submit="submit" :model-info="modelInfo"/>
    <CreditDebitCard v-else @submit="submit" :model-info="modelInfo"/>
  </div>
</template>

<script>
import TextInput from '@shared/components/common/input/TextInput.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import baseTemplate from '@/mixins/baseTemplate';
import BankAccount from '@/views/components/pages/on-boarding/template/payment-account-verification/BankAccount.vue';
import CreditDebitCard from '@/views/components/pages/on-boarding/template/payment-account-verification/CreditDebitCard.vue';

export default {
  name: 'PaymentAccountVerification',
  lexicon: 'onBoarding',
  components: { CreditDebitCard, BankAccount, FontIcon },
  mixins: [baseTemplate],
  data() {
    return {
      model: null,
      activeId: 0,
      types: [{ label: 'Bank Account', value: 0}, { label: 'Create/Debit Card', value: 1}],
      TextInput,
    };
  },
  methods: {
    change(id) {
      this.activeId = id;
      this.model = null;
    },
    submit() {},
  },
  mounted() {
    this.modelInfo.title = 'onBoarding.title.paymentAccountVerification';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[payment-account-verification] {
  .bts-holder { .mb(56); .flex(); .gap(16); .space-around();
    >button {.br(8); .-a(@c-w05); .flex-grow(1); .p(24); .c(@c-w05); .fs(18); .flex-column(); .gap(12);
      &.active { .-a(white); .c(white); }
    }
  }
}
</style>
