<template>
  <div payletter>
    <div class="processing-dimmed" :class="{'fade-in': status === payletterStatus.Waiting}" />
    <template v-if="isReady">
      <p class="desc xlg" v-html="$t('_.description')" />
      <div class="verification-holder">
        <a @click="open(0)">
          <FontIcon name="mobile" />
          <label v-html="$t('_.mobile')" />
        </a>
        <a @click="open(1)">
          <FontIcon name="credit-card-regular" />
          <label v-html="$t('_.creditCard')" />
        </a>
      </div>
    </template>
    <template v-else>
      <div class="result-holder">
        <p v-html="$t(resultMsg)" />
        <ProgressButton v-if="isSuccess" button-name="next" @click="confirm" />
        <ProgressButton v-if="isRetry" button-name="back" @click="retry" />
        <ProgressButton v-if="isClose" button-name="close" @click="close" />
      </div>
    </template>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import {sleep} from "@shared/utils/commonUtils.mjs";
import { close } from '@/utils/clientUtil';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import baseTemplate from '@/mixins/baseTemplate';
import { payletterStatus } from '@/constants/base/onboarding/kyc';
import PayletterController from '@/plugins/payletterController';

export default {
  name: 'Payletter',
  lexicon: 'onBoarding.payLetter',
  components: { ProgressButton, FontIcon },
  mixins: [baseTemplate],
  data() {
    return {
      payletter: null,
      status: null,
      viewList: [
        // { template: SumsubStart, status: sumsubStatus.None },
      ],
    };
  },
  computed: {
    site: state('env', 'site'),
    isMobile: state('browser', 'mobile'),
    payletterStatus() {
      return payletterStatus;
    },
    isReady() {
      return [null, payletterStatus.None, payletterStatus.Ready, payletterStatus.Waiting].includes(this.status);
    },
    isSuccess() {
      return this.status === payletterStatus.Success;
    },
    isRetry() {
      return [payletterStatus.PopupBlocked, payletterStatus.Failed].includes(this.status);
    },
    isClose() {
      return [payletterStatus.RejectedUnderAge, payletterStatus.RejectedDupCi, payletterStatus.RejectedOtherCi].includes(this.status);
    },
    resultMsg() {
      switch (this.status) {
        case payletterStatus.Success:
          return '_.success';
        case payletterStatus.PopupBlocked:
          return '_.popupBlocked';
        case payletterStatus.Failed:
          return '_.failed';
        case payletterStatus.RejectedUnderAge:
          return '_.rejectedUnderAge';
        case payletterStatus.RejectedDupCi:
          return '_.rejectedDupCi';
        case payletterStatus.RejectedOtherCi:
          return '_.rejectedOtherCi';
        default:
          return'';
      }
    }
  },
  methods: {
    async open(method) {
      this.payletter.openPopup(method, this.isMobile);
    },
    confirm() {
      this.modelInfo.step = 1;
    },
    retry() {
      this.payletter.initialize();
    },
    close() {
      close(this);
    },
    /**
     * 컨트롤러에서 결과 콜백
     * @param info
     */
    callback(info) {
      console.log('%cpayletter callback', 'color:yellow', info);
      this.status = info.status;
      // if(this.isReady) this.info?.accessToken ? this.appendStandaloneSumsubScript().then(() => this.initSumsub()) : this.status = sumsubStatus.Failed; // unKnownError 로 간주
      // else if (this.isVerified) this.modelInfo.step = 1;
    },
    /**
     * 페이지 이탈 시 초기화
     */
    destroy() {
      this.$unblock();
      if (this.payletter) this.payletter.initialize();
      this.payletter = null;
    },
  },
  async mounted() {
    const {$services: services, site, lang, callback, modelInfo} = this;
    this.payletter = new PayletterController(this, {services, site, lang, callback, modelInfo});

    await sleep(60);
  },
  beforeDestroy() {
    this.destroy();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[payletter] { .tc();
  .verification-holder { .flex(); .gap(12); .justify-center(); .mt(40);
    > a { .w(160); .flex-column(); .br(4); .bgc(@c-w02); .pv(20);
      > [font-icon] { .mb(16); .fs(24); .c(#7ce4b5); }
      > img { .wh(30); .mb(8);}
      > label { .semi-bold();}
    }
  }
  .processing-dimmed { .wh(100%); .hidden(); .o(0); .fix(); .t(0); .l(0); .bgc(rgba(0, 0, 0, 0.5)); .z(9000);
    &.fade-in { .o(0.5s, 1); .visible(); }
  }
}
</style>