<template>
  <div personal-self-declaration name="self-declaration">
    <ValidationProvider v-for="(item, id) in list" rules="requiredCheck" :key="id">
      <CheckboxItem :key="id" v-model="item.checked" theme="dark" @change="updateValue">
        <span class="exp" v-html="item.desc" />
      </CheckboxItem>
    </ValidationProvider>
  </div>
</template>

<script>
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'PersonalSelfDeclaration',
  components: { CheckboxItem },
  lexicon: 'personalDetails.selfDeclaration',
  props: {
    modelInfo: { type: Specific, default: null},
    structure: { type: Specific, default: null},
  },
  data() {
    return {
      list: [
        { desc: this.$t('_.isParticipateInGovernment'), checked: true },
        { desc: this.$t('_.isStudent'), checked: true },
        { desc: this.$t('_.notGamingEmployeeLicense'), checked: true }
      ],
    };
  },
  methods: {
    updateValue() {
      this.modelInfo.IsSelfIdentificationDeclaration = this.list.every(o => o.checked);
    }
  },
  mounted() {
    this.modelInfo.IsSelfIdentificationDeclaration = true;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[personal-self-declaration] {
  [checkbox-item] { .mb(20);
    em { .break-all(); white-space: normal; .fs(16); }
  }
}
</style>
