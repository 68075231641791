<template>
  <div gp-country-drop-down>
    <SearchDropSelect :key="parentValue" :value="model" @input="selected" :placeholder="placeholder" :list="occupationList" :size="size" @focus="$emit('focus')" @blur="$emit('blur')" :disabled="disabled" :searchHandler="searchHandler" :hidden-icon="hiddenIcon" />
    <!--    <p class="link" v-if="blockItem">Please sign up at <a :href="blockItem.link" target="_blank">{{ blockItem.name }}</a></p>-->
  </div>
</template>

<script>

import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { sleep } from '@shared/utils/commonUtils.mjs';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';

export default {
  name: 'PhOccupationDropDown',
  components: { SearchDropSelect },
  props: {
    value: { type: Specific, default: null },
    size: { type: String, default: 'sm' },
    disabled: { type: Boolean, default: false, },
    placeholder: { type: String, default: false, },
    hiddenIcon:{ type: Boolean, default : false},
    parentValue: {type: Specific, default: null }
  },
  data() {
    return {
      list: null,
      model: null,
      studentFilter: ['Student'],
      unemployedFilter: ['Unemployed'],
      retiredFilter: ['Retired'],
      selfEmployedFilter: ['Jeweler', 'Pawnbroker', 'ConvenienceStoreOwner', 'RestaurantOwner', 'RealEstateBroker', 'RetailStoreOwner', 'LiquorStoreOwner', 'CannabisStoreOwner', 'PrivatelyOwnedAutomatedTellerMachinesOwner', 'ProfessionalPokerPlayer', 'CryptocurrencyTrader', 'LuxuryGoodsDealer', 'Influencer', 'CommoditiesTrader', 'GasStationOwner'],
      employedExcludeFilter: ['Unemployed', 'Student', 'Jeweler', 'Pawnbroker', 'ConvenienceStoreOwner', 'RestaurantOwner', 'RealEstateBroker', 'RetailStoreOwner', 'LiquorStoreOwner', 'CannabisStoreOwner', 'PrivatelyOwnedAutomatedTellerMachinesOwner', 'ProfessionalPokerPlayer', 'CryptocurrencyTrader', 'LuxuryGoodsDealer', 'Influencer', 'CommoditiesTrader', 'GasStationOwner'],
    };
  },
  computed: {
    site: state('env', 'site'),
    occupationList() {
      if(!this.list) return;
      switch (this.parentValue) {
        case 'Employed':
          return this.list.filter(type => !this.employedExcludeFilter.includes(type.value));
        case 'SelfEmployed':
          return this.list.filter(type => this.selfEmployedFilter.includes(type.value));
        case 'Retired':
          return this.list.filter(type => this.retiredFilter.includes(type.value));
        case 'Student':
          return this.list.filter(type => this.studentFilter.includes(type.value));
        case 'Unemployed':
          return this.list.filter(type => this.unemployedFilter.includes(type.value));
        default:
          return null;
      }
    }
  },
  watch:{
    'parentValue': function(){
      this.model = null;
      this.$emit('input', null);
    }
  },
  methods: {
    selected(v) {
      if (!v) return;
      this.model = v?.value;
      this.$emit('input', v?.value);
    },
    async getList() {
      this.list = (await this.$services.onboarding.getAllOccupationType()).OccupationTypes.filter(x => x !== 'None').map(o => ({ label: this.$t(`occupation.${o}`), value: o }));
      await sleep(60);
    },
    searchHandler(originList, searchText) {
      if (originList.length <= 0) return [];

      const searchList = originList.filter(originData => originData.value.toLowerCase().includes(searchText.toLowerCase()) || originData.label.toLowerCase().includes(searchText.toLowerCase()) );

      return searchList;
    },
  },
  async mounted() {
    await this.getList();
    this.model = this.value;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-country-drop-down] { .h(40); .rel();
  &.disabled {.h(20);}
  &.single { .h(48);
    [search-drop-select] {
      > a { .h(48); .br(6); box-shadow: 0 1px 0 0 rgba(200, 200, 200, 0.1), 0 1px 0 0 rgba(0, 0, 0) inset; .bgc(rgba(255, 255, 255, 0.07)) !important;;
        > label { .lh(50); .h(100%);}
      }
    }
  }
  .img-country { .w(18); .ib(); .mr(8);}
  .link { .abs(); .rb(0, -28); .c(@c-red); .semi-bold();
    a { .-b(@c-red);}
  }
}
</style>