<template>
  <div gp-ph-employment-status>
    <!--    <DropSelect class="uk-employment-dropdown" :value="value" @input="input" :list="list" auto :translate="false" :placeholder="$t('myInfo.personalDetails.employmentStatus')" />-->
    <SearchDropSelect class="uk-employment-dropdown" :value="currentValue" @input="input" :list="list" auto :translate="false" :placeholder="$t('myInfo.personalDetails.employmentStatus')" :hidden-icon="hiddenIcon" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import DropSelect from '@shared/components/common/input/DropSelect.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import { employmentList } from '@/constants/base/my-page';

export default {
  name: 'PhEmploymentTypeDropDown',
  components: { SearchDropSelect, DropSelect },
  props: {
    value: Specific,
    current: Specific,
    disabled: { type: Boolean, default: null },
    currencySymbol: { type: String, default: null },
    hiddenIcon:{ type: Boolean, default : false},
  },
  data() {
    return {
      currentValue: null,
    };
  },
  watch: {
    'value': 'updateValue'
  },
  computed: {
    preset() { return { list: this.list, auto: true, translate: false, }; },
    list() {return employmentList.map(v => ({label: this.$t(v.label), value: v.value})) },
  },
  methods: {
    updateValue() {
      this.currentValue = this.value;
    },
    input(v) {
      if (!v) return;
      this.currentValue = v?.value;
      this.$emit('input', v?.value);
    }
  },
  mounted() {
    this.currentValue = this.value !== 'None' ? this.value : null;
  }
}
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-country-drop-down] { .h(40); .rel();
  &.disabled {.h(20);}
  &.single { .h(48);
    [search-drop-select] {
      > a { .h(48); .br(6); box-shadow: 0 1px 0 0 rgba(200, 200, 200, 0.1), 0 1px 0 0 rgba(0, 0, 0) inset; .bgc(rgba(255, 255, 255, 0.07)) !important;;
        > label { .lh(50); .h(100%);}
      }
    }
  }
  .img-country { .w(18); .ib(); .mr(8);}
  .link { .abs(); .rb(0, -28); .c(@c-red); .semi-bold();
    a { .-b(@c-red);}
  }
}
</style>