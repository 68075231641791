<template>
  <GpFormTemplate np-personal-info>
    <template v-if="isLoad" #content>
      <div class="contact-info" v-if="isDefaultStep">
        <GpTitle :name="$t('myInfo.contactInfo')" />
        <DisplayContactInfo :model="contactInfo" :step="step" />
      </div>
      <div>
        <div v-if="isDefaultStep" class="info-header">
          <GpTitle :name="$t('myInfo.title.personalInfo')" />
          <TextButton v-if="!structure.isSelfExclusion" @click="confirm">{{ $t('edit') }}</TextButton>
        </div>
        <PersonalInfoEditableWrapper :model="model" @init="initialize" :step="step" :kyc-status="kycStatus">
          <template #editable="{editableModel}">
            <div>
              <div :class="['input-group', {'view' : isDefaultStep}, {'edit' : isInputStep}]">
                <InputPersonalName :model="model" :step="step" :editableModel="editableModel" :error="error" @update="update" :country-writing-code="CountryWritingSystem" />
                <InputPersonalBirth v-model="model.DateOfBirth" :step="step" :editableModel="editableModel" :error="error" @update="update" />
                <InputPersonalInfo :model="model" :step="step" :model-key-list="usePersonalInfoKeyList" :editableModel="editableModel" :error="error" @update="update" :country-writing-code="CountryWritingSystem" />
              </div>
              <CheckboxItem :class="['via-email', {'disable' : isDefaultStep}]" theme="light" :selected="model.IsMarketingAgreement" @change="marketingChange">{{ $t('myInfo.viaEmail') }}</CheckboxItem>
            </div>
          </template>
        </PersonalInfoEditableWrapper>
        <DisplayBankInfo v-if="model.PlayerBankInfo && isBankInfo && isDefaultStep" :model="model.PlayerBankInfo" :is-self-exclusion="structure.isSelfExclusion" :kyc-status="kycStatus" />
        <DisplayWithdrawal v-if="model.Withdrawal && isWithdrawal && isDefaultStep" :model="model.Withdrawal" />
      </div>
    </template>
    <template #footer="{validate, invalid}">
      <GpButton v-if="showButton && isInputStep" type="submit-green" @click.prevent="confirm(validate)" :disabled="(!!invalid && !isDefaultStep) || isApiError">{{ $t('edit') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { sleep } from '@shared/utils/commonUtils.mjs';
import { isLocal, isTest } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import { STEP } from '@/constants/base/my-page';
import InputPersonalInfo from '@/views/components/pages/my-page/template/inputs/InputPersonalInfo.vue';
import InputPersonalName from '@/views/components/pages/my-page/template/inputs/InputPersonalName.vue';
import InputPersonalBirth from '@/views/components/pages/my-page/template/inputs/InputPersonalBirth.vue';
import PersonalInfoEditableWrapper from '@/views/components/pages/my-page/template/personal-info/PersonalInfoDisableWrapper.vue';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import { siteIds } from '@/constants/base/siteMap.mjs';
import TextButton from '@/views/components/gg-pass/TextButton.vue';
import DisplayBankInfo from '@/views/components/pages/my-page/template/views/DisplayBankInfo.vue';
import npErrorHandler from '@/mixins/my-info/npErrorHandler';
import DisplayWithdrawal from '@/views/components/pages/my-page/template/views/DisplayWithdrawal.vue';
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import npStepInfo from '@/mixins/my-info/npStepInfo';
import Specific from '@shared/types/Specific';
import DisplayContactInfo from '@/views/components/pages/my-page/template/views/DisplayContactInfo.vue';
import { personalInfoSiteKeyList } from '@/constants/base/my-page/personalInfo';

export default {
  name: 'NpPersonalInfo',
  lexicon: 'myInfo.personalInfo',
  components: { DisplayWithdrawal, DisplayBankInfo, DisplayContactInfo, TextButton, CheckboxItem, PersonalInfoEditableWrapper, InputPersonalBirth, InputPersonalName, InputPersonalInfo, GpTitle, GpButton, GpFormTemplate },
  mixins: [npErrorHandler, npStepInfo],
  props: {
    model: Specific,
    structure: { type: Specific, default: null },
  },
  data() {
    return {
      errorMsg: null,
      kycStatus: null,
      selected: false,
      showButton: true,
      CountryWritingSystem: 0,
      contactInfo: {
        ContactEmail: '',
        ContactMobileNumber: '',
        ContactMobileCountryCode: null,
        IsContactEmailVerified: false,
        IsContactMobileVerified: false,
      },
      error: {
        FirstName: null,
        LastName: null,
        Gender: null,

        // Day of Birth
        DateOfBirth: null,
        Month: null,
        Day: null,
        Year: null,

        // Info
        Address: null,
        State: null,
        City: null,
        PostalCode: null,
        Nationality: null,
        Street: null,
        StreetNumber: null,
        LastNameAtBirth: null,
        PlaceOfBirth: null,
        HouseNumber: null,
        AdditionalAddress: null,
        BuildingAddress: null,
        EmploymentStatus: null,
        EmploymentType: null,
        SourceOfFunds: null,
        MiddleName: null,
        Alias: null,
        EmployerName: null,
        EmployerAddress: null,
        BusinessPhoneNumber: null,
        Country: null,
        BankInfo: null,
        Occupation: null,
        OccupationType: null,
        MaidenName: null,
        StreetName: null,
        WithDrawalAccount: null,
        TPID: null,
      },
      isLoad: false,
    };
  },

  computed: {
    token: state('user', 'token'),
    TextInputBox() {
      return TextInputBox;
    },
    stepFunctionLoader() {
      return {
        [STEP.DEFAULT]: () => this.changeInputStep(),
        [STEP.INPUT]: () => this.sendPersonalInfo(),
      };
    },
    hasFunction() {
      return this.stepFunctionLoader.hasOwnProperty(this.step);
    },
    usePersonalInfoKeyList() {
      return personalInfoSiteKeyList[this.site] || [];
    },
    isApiError() {
      return Object.keys(this.error).some(key => this.error[key] !== null);
    },
    isGGPDE() {
      return this.site === siteIds.GGPDE;
    },
    isGGPUK() {
      return this.site === siteIds.GGPUK;
    },
    isBankInfo() {
      return [siteIds.GGPCZ, siteIds.GGPNL].includes(this.site);
    },
    isWithdrawal() {
      return [siteIds.N8IN].includes(this.site);
    },
    isLocal() {
      return isLocal();
    },
    isTest() {
      return isTest();
    },
    isStreetOfAddressSites() {
      return [siteIds.GGPDE, siteIds.GGPUK].includes(this.site);
    },
  },
  methods: {
    async initialize() {
      //const result = await this.$myinfo.fetch();
      const result = await this.$myinfo.fetch({payload : null, block : true});

      const { PersonalInfo, ContactInfo, KycStatus, ThirdPartyId, IsEditable, CountryWritingSystem, Country } = result;

      this.model.CountryCode = Country;
      this.CountryWritingSystem = CountryWritingSystem;

      this.model.FirstName = PersonalInfo?.FirstName;
      this.model.LastName = PersonalInfo?.LastName;
      this.model.Gender = PersonalInfo?.Gender === 'Undefined' ? null : PersonalInfo?.Gender;
      this.model.DateOfBirth = PersonalInfo?.DateOfBirth;
      this.model.Month = PersonalInfo?.Month;
      this.model.Day = PersonalInfo?.Day;
      this.model.Year = PersonalInfo?.Year;
      this.model.Address = PersonalInfo?.Address;
      this.model.State = PersonalInfo?.State;
      this.model.City = PersonalInfo?.City;
      this.model.PostalCode = PersonalInfo?.PostalCode;
      this.model.Nationality = PersonalInfo?.Nationality;
      this.model.StreetName = PersonalInfo?.StreetName;
      this.model.Street = PersonalInfo?.Street;
      if (this.isGGPUK) {
        this.model.StreetName = PersonalInfo?.Address;
      }

      this.model.StreetNumber = PersonalInfo?.StreetNumber;
      this.model.LastNameAtBirth = PersonalInfo?.LastNameAtBirth;
      this.model.PlaceOfBirth = PersonalInfo?.PlaceOfBirth;
      this.model.HouseNumber = PersonalInfo?.HouseNumber;

      if (this.isGGPDE) {
        this.model.AdditionalAddress = PersonalInfo?.Address;
      } else {
        this.model.AdditionalAddress = PersonalInfo?.AdditionalAddress;
      }
      this.model.BuildingAddress = PersonalInfo?.BuildingAddress;
      this.model.Building = PersonalInfo?.Building;
      this.model.EmploymentStatus = PersonalInfo?.EmploymentStatus;
      this.model.MiddleName = PersonalInfo?.MiddleName;
      this.model.Alias = PersonalInfo?.Alias;
      this.model.EmployerName = PersonalInfo?.EmployerName;
      this.model.EmployerAddress = PersonalInfo?.EmployerAddress;
      this.model.EmploymentType = PersonalInfo?.EmploymentType === 'None' ? null : PersonalInfo?.EmploymentType;
      this.model.BusinessPhoneNumber = PersonalInfo?.BusinessPhoneNumber;
      this.model.Country = PersonalInfo?.Country;
      this.model.CountryName = result?.CountryName;
      this.model.SourceOfFunds = PersonalInfo?.SourceOfFunds;
      this.model.BankInfo = PersonalInfo?.BankInfo;
      this.model.OccupationType = PersonalInfo?.OccupationType === 'None' ? null : PersonalInfo?.OccupationType;
      this.model.WithDrawalAccount = PersonalInfo?.WithDrawalAccount;
      this.model.MaidenName = PersonalInfo?.MaidenName;

      this.model.TPID = PersonalInfo?.TPID;
      this.model.IsMarketingAgreement = PersonalInfo?.IsMarketingAgreement;

      this.contactInfo = ContactInfo;
      this.kycStatus = KycStatus;

      // isEditable 이 false 일 경우 button hidden
      this.showButton = IsEditable;

      if (this.isGGPDE) {
        this.model.TPID = ThirdPartyId;
      }
      if (this.isBankInfo) {
        this.model.PlayerBankInfo = result?.PlayerBankInfo ? result?.PlayerBankInfo : null;
      }
      if (this.isWithdrawal) await this.withdrawalInitialize();

      this.$myinfo.changeStep(STEP.DEFAULT);
      this.isLoad = true;
    },

    initializeError() {
      Object.keys(this.error).forEach(key => {
        this.error[key] = null;
      });
    },
    marketingChange(v) {
      if (this.isDefaultStep) return;
      this.model.IsMarketingAgreement = !this.model.IsMarketingAgreement;
    },

    async withdrawalInitialize() {
      const withdrawal = await this.$services.account.getPaymentAccountVerifyStatus();
      this.model.Withdrawal = withdrawal;
    },

    changeInputStep() {
      this.$emit('changeStep', this.stepInfo.INPUT);
      // this.changeStep(STEP.INPUT);
    },

    update(key, value) {
      this.model[key] = value;
      this.initializeError();
      // this.error[key] = null;
    },

    async sendPersonalInfo() {
      const payload = { ...this.model };
      if (this.isGGPUK) {
        payload.Address = this.model.StreetName;
      }
      if (this.isGGPDE) {
        payload.Address = this.model.AdditionalAddress;
      }
      const result = await this.$myinfo.request({ ...payload }, null, true);
      if (result.error) {
        this.apiErrorHandler(result);
        return;
      }
      await sleep(300);
      scrollTo(0, 0);

      this.$emit('success', 'myInfo.personalInfoSuccess');
    },

    async confirm(validate) {
      if (!this.hasFunction) return;
      const loadedFunction = this.stepFunctionLoader[this.step];
      if (typeof loadedFunction !== 'function') return;

      await loadedFunction();
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-personal-info] { .pt(10);
  .contact-info {.mb(40);}
  .info-header {.flex(); justify-content: space-between;
    > [gp-text-button] {
      > button {.fs(13); .c(@gp-green); font-weight: 500; text-decoration-line: none;}
    }
  }
  .input-group {.flex(); .flex-dc(); .w(100%); .gap(10); .p(20, 15, 20, 15); .br(16); .mb(15);
    &.edit {.bgc(@gp-bgc-gray);}
    &.view {.bgc(#fff);}
  }
  .via-email { .mt(10); .c(black); .fs(14); line-height: 16px; .pl(3); cursor: pointer;
    &.disable { .c(#A1A1AA); cursor: default;
      > i { .-a(#EBEBEF); .bgc(#D4D4D8); opacity: 1;
        > svg {fill: white;}
      }
    }
  }
}

</style>