export default () => ({
  namespaced: true,
  state: {
    env: null,
    title: null,
    host: null,
    api: null,
    site: null,
    brand: null,
    brandName: null,
    csEmail: null,
    logo: null,
    theme: null,
    currency: null,
    defaultSite: null,
    routeNames: null,
    causes: null,
    mandatoryPersonal: null,
    redirectUrl: null,
    downloadUrl: null,
    isTracking: null,
    blockCountries: [],
    captcha: null,
    gwToken: null,
    widgetUrl: null,
    referer: null,
    queries: null,
    nodeResponse: null,
    paddingBottom: 0,
    ggpass: false,
    npOnly: false,
    gpBrand: null,
    gpSite: null,
    siteInfo: null,
    langList: null,
    isCasino: false,
    baseLang: 'en',
    gap: null,
    customIp: null,
    brandSignup: null,
    skipSignupPersonalInfo: null,
  },
  mutations: {
    setEnv(state, value) {
      state.env = value;
    },
    setHost(state, value) {
      state.host = value;
    },
    setNodeResponse(state, value) {
      state.nodeResponse = value;
    },
    setQueries(state, value) {
      state.queries = value;
    },
    setReferer(state, value) {
      state.referer = value;
    },
    setApi(state, value) {
      state.api = value;
    },
    setSite(state, value) {
      state.site = value;
    },
    setBrand(state, value) {
      state.brand = value;
    },
    setBrandName(state, value) {
      state.brandName = value;
    },
    setLogo(state, value) {
      state.logo = value;
    },
    setTitle(state, value) {
      state.title = value;
    },
    setFavicon(state, value) {
      state.favicon = value;
    },
    setDefaultSite(state, value) {
      state.defaultSite = value;
    },
    setBlockCountries(state, value) {
      state.blockCountries = value || [];
    },
    setBrandSignup(state, value) {
      state.brandSignup = value;
    },
    setSkipSignupPersonalInfo(state, value) {
      state.skipSignupPersonalInfo = value;
    },
    setTheme(state, value) {
      state.theme = value;
    },
    setCaptcha(state, value) {
      state.captcha = value;
    },
    setMandatoryPersonal(state, value) {
      state.mandatoryPersonal = value;
    },
    setCauses(state, value) {
      state.causes = value;
    },
    setRouteNames(state, value) {
      state.routeNames = value;
    },
    setRedirectUrl(state, value) {
      state.redirectUrl = value;
    },
    setDownloadUrl(state, value) {
      state.downloadUrl = value;
    },
    setIsTracking(state, value) {
      state.isTracking = value;
    },
    setGwToken(state, value) {
      state.gwToken = value;
    },
    setPaddingBottom(state, value) {
      state.paddingBottom = value;
    },
    setWidgetUrl(state, value) {
      state.widgetUrl = value;
    },
    setCsEmail(state, value) {
      state.csEmail = value;
    },
    setGgPass(state, value) {
      state.ggpass = value;
    },
    setNpOnly(state, value) {
      state.npOnly = value;
    },
    setGpBrand(state, value) {
      state.gpBrand = value;
    },
    setGpSite(state, value) {
      state.gpSite = value;
    },
    setSiteInfo(state, value) {
      state.siteInfo = value;
    },
    setLangList(state, value) {
      state.langList = value;
    },
    setCasino(state, value) {
      state.isCasino = value;
    },
    deliveryGap(state, value) {
      state.gap = value;
    },
    setBaseLang(state, value) {
      state.baseLang = value;
    },
    setCustomIp(state, value) {
      state.customIp = value;
    },
  },
});
