<template>
  <div start-on-boarding-ph>
    <p v-html="$t('_.mandatoryPersonalGuideDescription1')" />
    <p v-html="$t('_.mandatoryPersonalGuideDescription2')" />
    <ProgressButton button-name="submit" @click="submit" />
  </div>
</template>

<script>
import baseTemplate from '@/mixins/baseTemplate';
import ProgressButton from "@/views/components/common/ProgressButton.vue";

export default {
  name: 'StartOnBoardingPh',
  components: {ProgressButton},
  lexicon: 'onBoarding.startOnboarding',
  mixins: [baseTemplate],
  methods: {
    submit() {
      this.modelInfo.step = 1;
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[start-on-boarding-ph] {
  > p { .tl(); .fs(18, 22, normal); .c(#7F7F7F); }
  > p + p { .mt(22); }
}
</style>
