<template>
  <div tink-failed>
    <div class="progress-title">
      <h1>{{ $t('_.tinkFailedTitle') }}</h1>
      <p class="progress"><span :style="{width:'100%'}" /></p>
    </div>
    <template v-if="status === tinkStatus.Aborted">
      <FontIcon name="exclamation-triangle"/>
      <p class="desc">{{ $t('_.tinkUserAbortDescription1') }}</p>
      <br/>
      <p class="desc">{{ $t('_.tinkUserAbortDescription2') }}</p>
      <ProgressButton button-name="tinkOnlineBanking" @click="$emit('click', 'tryAgain')" />
      <ProgressButton button-name="tinkDocumentUpload" @click="$emit('click', 'replaceJumio')" />
    </template>
    <template v-else-if="status === tinkStatus.Failed && failedStatus === fastIdentStatus.InitialError">
      <FontIcon name="exclamation-triangle"/>
      <p class="desc">{{ $t('_.tinkUnknownErrorDescription') }}</p>
      <ProgressButton button-name="tinkDocumentUpload" @click="$emit('click', 'replaceJumio')" />
      <p class="btn-link">
        <a @click="$emit('click', 'tryAgain')">{{ $t('tinkRetryButton') }}</a>
      </p>
    </template>
    <template v-else-if="status === tinkStatus.Failed && result === fastIdentMatchResult.Mismatch">
      <template v-if="tryCount < 3">
        <FontIcon name="exclamation-triangle"/>
        <p class="desc">{{ $t('_.tinkInvalidDescription1') }}</p>
        <br/>
        <p class="desc">{{ $t('_.tinkInvalidDescription2') }}</p>
        <ProgressButton button-name="tinkOnlineBanking" @click="$emit('click', 'tryAgain')" />
        <ProgressButton button-name="tinkDocumentUpload" @click="$emit('click', 'replaceJumio')" />
      </template>
      <template v-else>
        <FontIcon name="exclamation-triangle"/>
        <p class="desc">{{ $t('_.tinkInvalidThreeTimeDescription1') }}</p>
        <br/>
        <p class="desc">{{ $t('_.tinkInvalidThreeTimeDescription2') }}</p>
        <ProgressButton button-name="tinkDocumentUpload" @click="$emit('click', 'replaceJumio')" />
        <p class="btn-link">
          <a @click="$emit('click', 'tryAgain')">{{ $t('tinkRetryButton') }}</a>
        </p>
      </template>
    </template>
    <template v-else>
      <FontIcon name="exclamation-triangle"/>
      <p class="desc">{{ $t('_.tinkUnknownErrorDescription') }}</p>
      <ProgressButton button-name="tinkDocumentUpload" @click="$emit('click', 'replaceJumio')" />
      <p class="btn-link">
        <a @click="$emit('click', 'tryAgain')">{{ $t('tinkRetryButton') }}</a>
      </p>
    </template>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import {fastIdentMatchResult, fastIdentStatus, tinkStatus} from "@/constants/base/onboarding/kyc";

export default {
  name: 'TinkFailed',
  computed: {
    fastIdentMatchResult() {
      return fastIdentMatchResult;
    },
    fastIdentStatus() {
      return fastIdentStatus;
    },
    tinkStatus() {
      return tinkStatus;
    }
  },
  components: { ProgressButton, FontIcon },
  lexicon: 'kyc.deGiude',
  props: {
    status: String,
    failedStatus: String,
    result: String,
    tryCount: { type: Number, default: 0 },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[tink-failed] {
  .progress-title {
    h1 { .tl(); .medium(); .fs(28); .c(white); .flex(); .items-center(); .wf(); .regular(); line-height: 1.2; }
    .progress { .wh(100%, 2); .rel(); .bgc(rgba(255, 255, 255, 0.07)); .block(); .mt(16);
      span { .block(); .wh(0, 2); .bgc(@c-red); }
    }
  }
  [font-icon] { .fs(60); .flex(); .justify-center(); .m(40,0,28); }
  [color-button] {
    &:nth-of-type(1) { .mt(55); }
    &:nth-of-type(2) { .mt(12); .bgc(#515151);
      @{hover-press} { .bgc(#515151); }
    }
  }
  .btn-link { .c(@c-link); .fs(20); .tc(); .mt(24);
    > a { .-b(@c-link, 2); }
  }
}
</style>
