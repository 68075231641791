import { siteIds } from '@/constants/base/siteMap.mjs';
import { CAUSE_TYPES, excludeStreet, personalDetailSteps, routeNames, STEP_TYPES, verifyCodeSteps, verifyResponsibleGamingSteps } from '@/constants/base/onboarding/onBoardingSiteMap';
import _ from 'lodash';
import { getCurrencySymbol } from '@/constants/model/my-page';
import { kycLevel } from '@/constants/base/onboarding/kyc';

class OnboardingRouteStepAdaptor {
  #scope;
  #store;
  #services;
  #site;
  constructor(scope, {store, services}) {
    this.#scope = scope;
    this.#site = store.state.env.site;
    this.#services = services;
    this.#store = store;
  }

  getRouteItem(model, route) {
    return [model.list.find(o => o.route === route)];
  }
  async resetOrder(model, orderRouteNames, replace) {
    switch(this.#site) {
      case siteIds.GGPUK:
        if(replace) {
          this.#checkUkJumio(orderRouteNames);
        }
        else {
          this.#checkUkVerifyAge(orderRouteNames);
        }
        break;
      case siteIds.GGPDE:
        if(replace) this.#checkKycDe(orderRouteNames);
        break;
      default:
        break;
    }

    const sortList = await this.#appendExternalStep(model, orderRouteNames);
    if (sortList?.error) return sortList;
    return this.#updateProgressItems(model, orderRouteNames, sortList);
  }

  async #appendExternalStep(model, orderRouteNames) {
    const sortList = [];
    const { customStep, additional, restrict } = this.#store.state.query;
    if (customStep) orderRouteNames = [customStep];
    else if (additional) {
      if (!orderRouteNames?.some(order => [routeNames.kycDocumentManual, routeNames.poi, routeNames.poa, routeNames.edd, routeNames.kycDE, routeNames.jumio, routeNames.signicat, routeNames.sumsub].includes(order))) {
        const kycUploadStatus = await this.#scope.$onboarding.apiRequest('kyc', 'getPlayerKycUploadStatus', { Restrict: restrict, IsConsiderKycStatusWhenKycNotRequested: true });
        if (kycUploadStatus?.error) return kycUploadStatus;

        const kycDocumentGroup = kycUploadStatus.KycDocumentGroup;
        if (!kycDocumentGroup?.length) {
          const kycDocuments = await this.#scope.$onboarding.apiRequest('kyc', 'getPlayerKycDocuments');
          if (kycDocuments?.error) return kycDocuments;

          const documents = kycDocuments.Documents;
          if (documents.length) orderRouteNames.push(routeNames.kycDocumentManual);
        } else {
          let additionalSteps = kycDocumentGroup?.map(doc => doc.KycDocumentGroupType);
          if (additionalSteps?.some(step => [routeNames.poi, routeNames.poa, routeNames.edd].includes(step))) {
            if (!additionalSteps.some(step => step === routeNames.edd)) {
              // GGPDE, GGPNL, GGPUK, WSOP일 경우 각 3rd party로 추가
              switch (this.#site) {
                case siteIds.GGPDE:
                  additionalSteps = [routeNames.kycDE];
                  break;
                case siteIds.GGPNL:
                  additionalSteps = [routeNames.signicat];
                  break;
                case siteIds.GGPUK:
                  additionalSteps = [routeNames.jumio];
                  break;
                case siteIds.WSOPON:
                  additionalSteps = [routeNames.sumsub];
                  break;
              }
            }

            console.log('additionalSteps', additionalSteps);
            additionalSteps.forEach(s => {
              const idx = orderRouteNames?.indexOf(s);
              if (idx > -1) orderRouteNames.splice(idx, 1);
              orderRouteNames ? orderRouteNames.push(s) : orderRouteNames = [s];
            });
          }
        }
      }
    }

    console.log('%cappendExternalStep', 'color:yellow', orderRouteNames);
    return this.#appendRuleStep(model, orderRouteNames, sortList);
  }

  #appendRuleStep(model, orderRouteNames, sortList) {
    const removeStreet = excludeStreet.includes(this.#site);
    const resetInfo = [
      {
        rule: routeName => {
          const newUserRule = routeName === routeNames.personalSelfDeclaration && personalDetailSteps.includes(routeName);
          return this.#site === siteIds.GGPPH && newUserRule;
        },
        action: () => sortList.push(model.list.find(o => o.route === routeNames.replace))
      },
      {
        rule: routeName => {
          const excludeSelfDeclarationStepRule = routeName === routeNames.poi && orderRouteNames.findIndex(order => order === routeName) !== 0 && !orderRouteNames.includes(routeNames.personalSelfDeclaration);
          return this.#site === siteIds.GGPPH && excludeSelfDeclarationStepRule;
        },
        action: () => {
          const poiIndex = sortList.findIndex(o => o.route === routeNames.poi);
          sortList.splice(poiIndex, 0, model.list.find(o => o.route === routeNames.replace));
        }
      },
      {
        rule: routeName => {
          const userKycLevel = Object.keys(kycLevel).findIndex(v => v === this.#store.state.user.userInfo.KycStatus);
          const tempPassLevel = Object.keys(kycLevel).findIndex(v => v === kycLevel.DetailsFilled);
          const passed = userKycLevel >= tempPassLevel;
          const existUserRule = passed && orderRouteNames.findIndex(order => order === routeName) === 0 && !personalDetailSteps.includes(routeName);
          return this.#site === siteIds.GGPPH && existUserRule && this.#store.state.user.userInfo.TemporaryVerificationStatus !== 'None';
        },
        action: () => {
          if (this.#store.state.user.userInfo.TemporaryVerificationStatus === 'Accepted') sortList.unshift({ ...model.list.find(o => o.route === routeNames.temporaryAccountAccepted), stepType: STEP_TYPES.ETC });
          else sortList.unshift({ ...model.list.find(o => o.route === routeNames.temporaryAccountExpired), stepType: STEP_TYPES.ETC });
        }
      },
      {
        rule: routeName => this.#site === siteIds.GGPPH && orderRouteNames.findIndex(order => order === routeName) === 0 && this.#store.state.env.causes?.[0] === CAUSE_TYPES.PersonalDetailNotEnough,
        action: () => sortList.unshift({ ...model.list.find(o => o.route === routeNames.startOnboarding), stepType: STEP_TYPES.ETC })
      },
      {
        rule: routeName => {
          const existUserRule = orderRouteNames.findIndex(order => order === routeName) === 0 && !this.#services.cookie.getRedirectUri();
          return this.#site === siteIds.GGPDE && existUserRule && this.#store.state.user.userInfo.TemporaryVerificationStatus !== 'None';
        },
        action: () => {
          if (this.#store.state.user.userInfo.TemporaryVerificationStatus === 'Accepted') sortList.unshift({ ...model.list.find(o => o.route === routeNames.temporaryAccountLogin), stepType: STEP_TYPES.ETC });
          else sortList.unshift({ ...model.list.find(o => o.route === routeNames.temporaryAccountExpired), stepType: STEP_TYPES.ETC });
        }
      },
      {
        rule: routeName => {
          const userKycLevel = Object.keys(kycLevel).findIndex(v => v === this.#store.state.user.userInfo.KycStatus);
          const tempPassLevel = Object.keys(kycLevel).findIndex(v => v === kycLevel.PoaVerified);
          const passed = userKycLevel >= tempPassLevel;

          return this.#site === siteIds.GGPDE && !passed && routeName === routeNames.lugas;
        },
        action: () => sortList.push({ ...model.list.find(o => o.route === routeNames.temporaryAccountAccepted), stepType: STEP_TYPES.ETC })
      },
      {
        rule: routeName => this.#site === siteIds.GGPCZ && routeName === routeNames.personalName,
        action: () => sortList.unshift({ ...model.list.find(o => o.route === routeNames.startOnboarding), stepType: STEP_TYPES.ETC })
      },
      {
        rule: routeName => routeName === routeNames.personalName && this.#site && this.#store.state.user.IsShowLicenseSync,
        action: () => sortList.unshift({ ...model.list.find(o => o.route === routeNames.regulatoryTransition), stepType: STEP_TYPES.ETC })
      },
      {
        rule: routeName => routeName === routeNames.personalAddress && removeStreet,
        action: item => _.remove(item.keys, o => o === 'Building')
      },
      {
        rule: routeName => this.#site === siteIds.GGPDE && routeName === routeNames.kycDE,
        action: () => {
          sortList.push(model.list.find(o => o.route === routeNames.jumio));
          sortList.push(model.list.find(o => o.route === routeNames.personalDetailsRecheck));
        }
      },
      {
        rule: routeName => this.#site === siteIds.GGPNL && routeName === routeNames.signicat,
        action: () => sortList.push(model.list.find(o => o.route === routeNames.kycDocumentManual))
      },
      {
        rule: routeName => this.#site === siteIds.GGPUK && routeName === routeNames.jumio,
        action: () => sortList.push({ ...model.list.find(o => o.route === routeNames.showReviewingKycMessage), stepType: STEP_TYPES.ETC })
      }, // UK의 경우 Jumio Reviewing 단계 시 해당 Step이 내려오므로 추가}
      {
        rule: routeName => routeName === routeNames.verifyAge,
        action: () => sortList.push(model.list.find(o => o.route === routeNames.kycDocumentManual))
      },
      {
        rule: routeName => verifyCodeSteps.includes(routeName),
        action: () => {
          sortList.push(model.list.find(o => o.route === routeNames.verifyCode));
          sortList.push(model.list.find(o => o.route === routeNames.replace));
        }
      },
      {
        rule: routeName => this.#site === siteIds.GGPRO && routeName === routeNames.contactMobileNumber,
        action: item => {
          const findIndex = sortList.findIndex(o => o.route === item?.route);
          if (findIndex > -1) {
            sortList.splice(findIndex, 1); // RO의 경우 인증이 있으나 ContactMobileNumber로 내려오고 있어서 치환 처리 추가

            sortList.push(model.list.find(o => o.route === routeNames.verifyContactMobileNumber));
            sortList.push(model.list.find(o => o.route === routeNames.verifyCode));
            sortList.push(model.list.find(o => o.route === routeNames.replace));
          }
        }
      },
    ];

    if (!orderRouteNames?.length) {
      if (this.#site === siteIds.GGPPH && this.#store.state.user.userInfo.TemporaryVerificationStatus !== 'None') {
        if (this.#store.state.user.userInfo.TemporaryVerificationStatus === 'Accepted') {
          sortList.push({ ...model.list.find(o => o.route === routeNames.temporaryAccountAccepted), stepType: STEP_TYPES.ETC });
          sortList.push(model.list.find(o => o.route === routeNames.poi));
        } else sortList.unshift({ ...model.list.find(o => o.route === routeNames.temporaryAccountExpired), stepType: STEP_TYPES.ETC });
      }
    } else {
      orderRouteNames?.forEach(routeName => {
        const item = model.list.find(o => o.route === routeName);
        if (item) {
          sortList.push(...this.#updateAddSortItems(item.route, item));
          resetInfo.forEach(o => o.rule(item.route) && o.action(item));
        }
      });
    }

    return sortList;
  }

  async #updateProgressItems(model, orderRouteNames, sortList) {
    const ignoreStepComponent = ['StartOnboarding', 'TransUnion', 'Replace', 'ShowReviewingKycMessage'];
    if (![siteIds.GGPDE, siteIds.GGPUK].includes(this.#site)) ignoreStepComponent.push('VerifyAge'); // DE, UK의 경우 UI가 있으므로 Ignore에서 제외

    const group = [];
    for (let i = 0; i < sortList.length; i++) {
      if (sortList[i].stepType) {
        let count = 0;
        if (sortList[i].stepType === STEP_TYPES.PERSONAL_DETAIL) {
          group.push(sortList.filter(i => i.stepType === STEP_TYPES.PERSONAL_DETAIL));
          count = sortList.filter(i => i.stepType === STEP_TYPES.PERSONAL_DETAIL).length;
          i += count > 1 ? (count - 1) : 0;
        } else if (sortList[i].stepType === STEP_TYPES.VERIFY_RESPONSIBLE_GAMING) {
          group.push(sortList.filter(i => i.stepType === STEP_TYPES.VERIFY_RESPONSIBLE_GAMING));
          count = sortList.filter(i => i.stepType === STEP_TYPES.VERIFY_RESPONSIBLE_GAMING).length;
          i += count > 1 ? (count - 1) : 0;
        } else {
          group.push(sortList[i]);
        }
      }
    }
    let r = await this.#setLocalCurrency();
    if (r?.error) return r;

    if(sortList.length && sortList[0].stepType === STEP_TYPES.VERIFY_RESPONSIBLE_GAMING) {
      r = await this.#setCurrencyList();
      if (r?.error) return r;
    }

    model.progress = orderRouteNames ? sortList.filter(item => !ignoreStepComponent.includes(item.route)).findIndex(o => o.route === orderRouteNames[0]) : 0;
    model.progressGroup = group;
    return sortList;
  }

  async #setLocalCurrency() {
    if(this.#store.state.user.currencySymbol) return;
    const r = await this.#scope.$onboarding.apiRequest('account', 'getBalance');
    if (r?.error) return r;

    const localCurrency = r?.LocalCurrency;
    const currencySymbol = getCurrencySymbol(localCurrency, '');

    this.#store.commit('user/setBalance', r);
    this.#store.commit('user/setCurrencySymbol', currencySymbol);
  }

  async #setCurrencyList() {
    if(this.#store.state.user.currencies) return;
    const /** @type { { Currencies: string[] } } */ r = await this.#scope.$onboarding.apiRequest('base', 'getCurrencyList');
    if (r?.error) return r;

    this.#store.commit('config/setCurrencies', r?.Currencies);
  }

  #updateAddSortItems(routeName, item) {
    const items = [];
    if (personalDetailSteps.includes(routeName)) items.push({ ...item, stepType: STEP_TYPES.PERSONAL_DETAIL });
    else if (verifyResponsibleGamingSteps.includes(routeName)) items.push({ ...item, stepType: STEP_TYPES.VERIFY_RESPONSIBLE_GAMING });
    else items.push({ ...item, stepType: STEP_TYPES.ETC });
    return items;
  }

  #checkUkJumio(orderRouteNames) {
     if(orderRouteNames?.length > 1 && orderRouteNames[1] === routeNames.jumio) {
       orderRouteNames.splice(0, 1);
     }
  }
  #checkUkVerifyAge(orderRouteNames) {
    if(orderRouteNames?.length > 1 && orderRouteNames[1] === routeNames.showReviewingKycMessage) {
      orderRouteNames.splice(0, 1);
    }
  }

  #checkKycDe(orderRouteNames) {
    if(orderRouteNames?.length > 1 && orderRouteNames[0] === routeNames.verifyAge && orderRouteNames[1] === routeNames.kycDE) {
      orderRouteNames.splice(0, 1);
    }
  }
}

export default OnboardingRouteStepAdaptor;
