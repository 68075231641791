<template>
  <div signicat>
    <div class="view-holder" v-show="!retry">
      <KycIframe v-if="isEidvVerification && url && !isError" :url="url"/>
      <component v-else :is="component" :step="step" :status="status" :errorTemplate="errorTemplate" :isMobileApp="isMobileApp" @click="confirm" />
    </div>
    <ProgressButton v-if="btnName && !retry" :button-name="btnName" @click="confirm" />
  </div>
</template>

<script>
import SignicatController from '@/plugins/signicatController';
import { checkClientPermission, close } from '@/utils/clientUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { signicatStatus, signicatStep } from '@/constants/base/onboarding/kyc';
import SignicatInformation from '@/views/components/pages/on-boarding/template/kyc/signicat/SignicatInformation.vue';
import SignicatIdinGuide from '@/views/components/pages/on-boarding/template/kyc/signicat/SignicatIdinGuide.vue';
import SignicatEidvProcessing from '@/views/components/pages/on-boarding/template/kyc/signicat/SignicatEidvProcessing.vue';
import baseTemplate from '@/mixins/baseTemplate';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import VerificationFailed from '@/views/pages/on-boarding/VerificationFailed.vue';
import { apiErrorType } from '@/constants/base/apiErrorCode';
import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';
import KycIframe from '@/views/components/pages/on-boarding/template/kyc/KycIframe.vue';

export default {
  name: 'Signicat',
  components: { KycIframe, ProgressButton, FontIcon, SignicatInformation },
  mixins: [baseTemplate],
  data() {
    return {
      signicat: null,
      info: null,
      step: null,
      status: null,
      url: null,
      retry: false,
      isEnd: false,
      isMobileApp: false,
      viewList: [
        { component: SignicatInformation, step: [signicatStep.IdinVerification, signicatStep.EidvVerification], status: null },
        { component: SignicatIdinGuide, step: signicatStep.IdinVerification, status: [signicatStatus.Processing, signicatStatus.Pending], mobile: true },
        { component: SignicatEidvProcessing, step: signicatStep.EidvVerification, status: signicatStatus.Processing },
        { component: VerificationFailed, step: [signicatStep.IdinVerification, signicatStep.EidvVerification], status: signicatStatus.Duplicated, errorTemplate: { [apiErrorType.VERIFICATION]: { content: 'errorPages.signicatFailed.content' } } },
        { component: VerificationFailed, step: [signicatStep.IdinVerification, signicatStep.EidvVerification], status: signicatStatus.Failed, errorTemplate: { [apiErrorType.VERIFICATION]: { content: 'errorPages.signicatFailed.content' } } },
      ],
    };
  },
  computed: {
    env: state('env', 'env'),
    site: state('env', 'site'),
    view() {
      return this.viewList.find(o => (Array.isArray(o.step) ? o.step.includes(this.step) : o.step === this.step) && (Array.isArray(o.status) ? o.status.includes(this.status) : o.status === this.status) && (o.mobile ? o.mobile === this.isMobileApp : true));
    },
    component() {
      return this.view?.component;
    },
    errorTemplate() {
      return this.view?.errorTemplate;
    },
    btnName() {
      switch (this.status) {
        case signicatStatus.Duplicated:
          return 'close';
        case signicatStatus.Failed:
          return 'tryAgain';
        default:
          return '';
      }
    },
    isIdinVerification() {
      return this.step === signicatStep.IdinVerification;
    },
    isEidvVerification() {
      return this.step === signicatStep.EidvVerification;
    },
    isError() {
      return [signicatStatus.Duplicated, signicatStatus.Failed].includes(this.status);
    },
    isLocal() {
      return this.env === 'local';
    },
  },
  methods: {
    async callback(info) {
      console.log('%csignicat callback', 'color:yellow', info);
      if(this.isEnd) return;
      if(this.status === signicatStatus.Duplicated) return;

      this.info = info;
      this.step = info.step;
      this.status = info.status;
      this.item.title = 'onBoarding.title.verificationRequest';

      if (this.url !== info.url) this.url = info.url;

      if (!info.status) {
        switch (info.step) {
          case signicatStep.Done:
            await this.$services.onboarding.setCheckKycCallback();

            this.isEnd = true;
            this.modelInfo.replace = true;
            break;
          case signicatStep.Screening: // 이젠 안내려오고 withOut-token에서 스텝으로 내려옴
          case signicatStep.SurePay: // 이젠 안내려오고 withOut-token에서 스텝으로 내려옴
          case signicatStep.Cruks: // 이젠 안내려오고 withOut-token에서 스텝으로 내려옴
            if (this.isEnd) this.modelInfo.replace = true;
            break;
          case signicatStep.KycDocumentManual:
            this.replaceRouteName(routeNames.kycDocumentManual, { isConsiderKyc: true }, this.$route.query);
            break;
          default:
            break;
        }
      } else {
        switch (this.status) {
          case signicatStatus.Accepted:
            if (!this.isEnd && this.isEidvVerification) {
              this.isEnd = true;
              this.modelInfo.replace = true;
            } else this.signicat.updateStep();
            break;
          case signicatStatus.Processing:
          case signicatStatus.Pending:
            if (this.retry) {
              await sleep(100);
              this.retry = false;
            }
            if (this.isIdinVerification) {
              this.$block();
              if (this.isMobileApp) {
                await sleep(3000);
                this.$unblock();
                await this.signicat.openIdinSignicat();
              } else {
                await this.signicat.redirectIdinSignicat();
              }
            }
            break;
          case signicatStatus.Duplicated:
          case signicatStatus.Failed:
            this.item.title = 'errorPages.verificationFailed.title';
            if(!this.isEnd && this.isEidvVerification) {
              this.isEnd = true;
            }
            break;
          default:
            this.replaceRouteName(routeNames.kycDocumentManual, { isConsiderKyc: true }, this.$route.query);
            break;
        }
      }
    },
    async confirm() {
      if (this.status === signicatStatus.Duplicated) close(this);
      else if (this.status === signicatStatus.Failed) {
        this.retry = true;
        this.$block();
        this.replaceRouteName(this.$route.name, null, { ...this.$route.query, step: undefined, status: undefined, dossierId: undefined, processId: undefined });
        await this.signicat.tryAgain();
        this.$unblock();
      } else if ([signicatStep.IdinVerification, signicatStep.EidvVerification].includes(this.step)) {
        if (this.status === null) this.signicat.updateStatus(this.step);
        else if ([signicatStatus.Processing, signicatStatus.Pending].includes(this.status)) this.signicat.updateStatus(this.step);
      }
    },
    signicatMessageListener(e) {
      if (e?.data && e?.data.key === 'RECEIVER_SIGNICAT' && e.data.step && (e.data.status && this.status !== e.data.status)) {
        if (e.data.status?.toLowerCase() === signicatStatus.Canceled) {
          this.status = signicatStatus.Failed;
          this.replaceRouteName('Signicat', null, { ...this.$route.query, step: e.data.step, status: e.data.status });
        } else if (e.data.status?.toLowerCase() === signicatStatus.Processing.toLowerCase()) {
          this.signicat.updateStatus(e.data.step); // TODO : [ean] 중첩으로 들어왔을 시 그에 대한 중첩 호출이 안되도록 방어로직 처리 필요
        }
      }
    },
    /**
     * 페이지 이탈 시 초기화
     */
    destroy() {
      this.$unblock();
      if (this.signicat) this.signicat.clear();
      this.signicat = null;
    },
  },
  async mounted() {
    await checkClientPermission(this);
    const { $services: services, site, lang, callback, modelInfo } = this;
    this.signicat = new SignicatController(this, { services, site, lang, callback, modelInfo });

    await sleep(60);
    this.isMobileApp = window.isGGPokerMobileApp;

    window.addEventListener('message', this.signicatMessageListener);
  },
  beforeDestroy() {
    this.destroy();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[signicat] {
  iframe { .wh(100%, 600); .bgc(white);}
  .btn-confirm { .mt(60);}
}

</style>
