<template>
  <div tnc-check-list name="Step5_Consent_Items_View" class="signup-consent-items-view" v-if="ready">
    <template v-if="npOnly">
      <h2 v-html="$t('signUp.country.ggpassTitle')" />
      <CountryDropSelect @input="updateCountry" />
    </template>
    <template v-if="showBonusCode">
      <BonusCodeInput v-model="modelInfo.BonusCode" />
    </template>
    <template v-if="displayTnc && list">
      <CheckboxItem theme="small dark" v-if="list.length > 1" v-model="allAgree" @change="updateAll"><span>{{ $t('signUp.tnc.acceptAll') }}</span></CheckboxItem>
      <CheckboxItem v-for="(item,id) in list" :key="id" theme="small dark" v-model="item.model" @change="update">
        <span v-html="$t(item.msg, item.options)" />
        <button class="btn-popover" v-if="item.tooltip" v-popover.bottom.right="{component: item.tooltip, maxWidth: 340, useClick: true}">
          <FontIcon name="info-circle" />
        </button>
      </CheckboxItem>
      <component v-if="extraComponent" :is="extraComponent" :button-name="'signUp.tnc.acceptAllAndContinue'" class="extra" @click="acceptAllAndContinue" size="large" />
    </template>
    <ValidationErrors :error-msg="errorMsg" v-if="errorMsg" class="block-guide"/>
  </div>
</template>

<script>
import _ from 'lodash';
import { state } from '@shared/utils/storeUtils.mjs';
import { checkPermissionList } from '@shared/utils/permissionUtils.mjs';
import { getObjectIndexByProperty } from '@/utils/valueUtil';
import { isExistLatamCountry } from '@/utils/baseUtil';
import { tncInfo } from '@/utils/webSiteUtil';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { setGTM } from '@/plugins/tracking';
import { siteIds } from '@/constants/base/siteMap';
import { baseList } from '@/constants/base/siteMap';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import WsopCheckFloating from '@/views/components/pages/sign-up/asset/WsopCheckFloating.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import CountryDropSelect from '@/views/components/common/CountryDropSelect.vue';
import RoMessageModal from '@/views/components/extra/RoMessageModal.vue';
import BonusCodeInput from '@/views/components/pages/sign-up/BonusCodeInput.vue';
import baseTemplate from '@/mixins/baseTemplate';

let cnt = 0;
export default {
  name: 'TncCheckList',
  components: { CountryDropSelect, CheckboxItem, FontIcon, BonusCodeInput },
  mixins: [baseTemplate],
  data() {
    return {
      checkAll: null,
      list: null,
      tncInfo: null,
      allAgree: false,
      selectedSite: null,
      ready: false,
      showBonusCode: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    npOnly: state('env', 'npOnly'),
    refKey: state('query', 'refKey'),
    gwToken: state('user', 'gwToken'),
    displayTnc: state('config', 'displayTnc'),
    siteId() { return this.selectedSite ? this.selectedSite : this.site; },
    tooltip() { return this.siteId === siteIds.WSOPON ? WsopCheckFloating : null; },
    isN8IN() { return this.siteId === siteIds.N8IN; },
    isGGPPH() { return this.siteId === siteIds.GGPPH; },
    isOCNP() { return this.site === siteIds.OCNP; },
    step() { return this.structure.step; },
    extraComponent() { return this.structure.list[this.step].extra[this.siteId] && this.structure.list[this.step].extra[this.siteId].template; },
  },
  watch: {},
  methods: {
    updateAll(v) {
      this.allAgree = v;
      this.modelInfo.Agree = v;
      this.modelInfo.AllAgree = v;
      this.list.forEach(o => {
        o.model = this.modelInfo.Agree;
        this.modelInfo[o.key] = this.modelInfo.Agree;
      });
    },
    update() {
      /** 마지막 check List 체크시 버튼 활성화 */
      this.modelInfo.Agree = this.list[this.list.length - 1].model;
      this.allAgree = this.list.every(o => o.model);
      this.list.forEach(o => this.modelInfo[o.key] = o.model);
      this.modelInfo.AllAgree = this.allAgree;
    },
    excludeStatus(marketingExcludeSite) {
      if (cnt > 0) return;
      this.list[0].model = marketingExcludeSite.includes(this.siteId);
      this.list[1].model = siteIds.N8IN === this.site;
      this.update();
      cnt++;
    },
    acceptAllAndContinue() {
      this.updateAll(true);
      this.$emit('next');
    },
    async updateCountry(v) {
      if (!v || v === this.modelInfo.Country || !await this.checkAvailableLocation(v)) return;

      this.modelInfo.Country = v;
      const findIndexCountries = (site, model) => (site.countries || '').split(',').findIndex(c => String(c) === model) >= 0;
      const includesDomain = site => {
        const { VUE_APP_ENV, VUE_APP_BRAND } = process.env;
        const host = location.host;
        const isLocal = VUE_APP_ENV === 'local';
        const isCasino = host.includes('casino');

        if (isLocal) return VUE_APP_BRAND.toLowerCase() === (isCasino ? site.brandAlias.casino : site.brandAlias.default || site.brandAlias).toLowerCase();
        return (isCasino ? site.domain.casino : _.isString(site.domain) ? site.domain : (site.domain.default || ''))?.split(',').find(i => host.includes(i));
      };
      const countrySite = baseList.find(site => includesDomain(site) && findIndexCountries(site, v));
      if(countrySite && this.selectedSite !== countrySite.site) setGTM(countrySite.site, countrySite, this.$router, v);
      this.selectedSite = countrySite?.site;
      this.modelInfo.SiteId = this.selectedSite;
      this.$store.commit('env/setSite', this.selectedSite);
      this.updateList();

      await sleep(300);
      this.modelInfo.Country = v;
    },
    updateList() {
      this.tncInfo = tncInfo(this.siteId);
      const urls = this.tncInfo.urls;
      const marketingExcludeSite = [siteIds.GGPOK, siteIds.N8IN, siteIds.OCNP];
      const tncLabel = this.isN8IN ? 'signUp.tnc.n8InTnc' : this.isGGPPH ? 'signUp.tnc.ggpphTnc' : this.isOCNP ? 'signUp.tnc.ocnpTnc' : 'signUp.tnc.defaultTnc';
      this.list = [
        { msg: 'signUp.tnc.bonusOffer', options: { brand: this.tncInfo.brandName }, key: 'IsMarketingAgreement', model: false, excluded: marketingExcludeSite, tooltip: this.tooltip },
        { msg: tncLabel, options: { age: this.tncInfo.age, tncLink: urls.tncPath, privacyLink: urls.privacyPath, securityLink: urls.securityPath, bonusLink: urls.bonusPath, horusLink: urls.horusPath }, key: 'IsAgreeTnC', model: false },
      ];

      this.excludeStatus(marketingExcludeSite);
      this.list = [...checkPermissionList(this.list, this.siteId)];
      if (this.modelInfo.AllAgree) this.updateAll(true);
      else if (this.modelInfo.Agree) this.list[this.list.length - 1].model = true;
    },
    async checkAvailableLocation(country) {
      const r = /** @type {{IsAllowed: boolean} || {error: boolean, comment: string}} */ await this.$services.sign.checkAvailableLocation({ countryCode: country });
      if (r.IsAllowed) return true;
      if(r.desc === 'GGPRO under maintenance') {
        this.$modal(RoMessageModal);
        return false;
      } else if (r.error) {
        this.$modal(RoMessageModal);
        this.structure.errorMsg = this.$t(r.comment);
        return false;
      }
      await this.replaceRouteName('RestrictLocation', null, null);
      return false;
    },
  },
  async mounted() {
    if(this.npOnly) {
      const r = await this.$services.sign.getValidateReferenceKey(this.refKey);
      if (r?.error) {
        if (r?.key === apiErrorCode.GGPASS_EMAIL_EMPTY) await this.replaceRouteName('LoginRestriction', null, null);
        else await this.replaceRouteName('AuthError', {ci: getObjectIndexByProperty(apiErrorCode, apiErrorCode.ACCESS_DENIED), cci: getObjectIndexByProperty(apiErrorCode, apiErrorCode.INVALID_REFERENCE_KEY)});
        return;
      }

      this.showBonusCode = r?.IsBonusCodeRequired;
    }

    await sleep(100);
    this.ready = true;
    this.modelInfo.VerificationCode = null;
    if(this.displayTnc) this.updateList();
  }
};
</script>

<style lang="less">
@import '@/less/proj.less';
[tnc-check-list] {
  > h2 { .fs(22, 30); .c(#CCCCCC); .regular(); .mb(16); }
  [bonus-code-input] { .mt(8); }
  [checkbox-item] {
    &:nth-of-type(1) { .mt(30); .mb(28); }
    em { white-space: normal; overflow: visible;}
    .mb(16);
    a { .c(@c-red); .-b(@c-red); white-space: normal; .inline(); .vam(); }
  }
  .extra { .mt(56); }
  .btn-popover { .vam(); .ml(8);
    [font-icon] { .c(@c-w05); .-a(); }
  }
  @media (@tp-up) {
  }
}
</style>
