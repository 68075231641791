<template>
  <div my-device-item>
    <p class="current-device" v-if="isCurrentDevice">{{ $t('_.currentDevice') }}</p>
    <div class="remove-holder">
      <p>
        <FontIcon :name="iconName" />
      </p>
      <GpButton type="line-gray" @click="$emit('remove', {deviceName, clientDeviceIdNew: deviceID})">{{ $t('_.remove') }}</GpButton>
    </div>
    <p class="device-name">{{ deviceName }}</p>
    <p class="info-text" v-if="loginAddress">{{ loginAddress }}</p>
    <p class="info-text" v-if="lastLoggedInAt">{{ lastLoggedInAt }}</p>
    <p class="brand-info">{{ brandInfo }}</p>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import Specific from '@shared/types/Specific';
import moment from 'moment';

export default {
  name: 'MyDeviceItem',
  lexicon: 'myInfo.myDevices',
  components: { GpButton, FontIcon },
  props: {
    info: /** @type {TrustedDeviceItem} */Specific,
  },
  computed: {
    isCurrentDevice() {
      return this.info?.isCurrentDevice;
    },
    deviceType() {
      return this.info?.clientDeviceType?.toUpperCase();
    },
    deviceID() {
      return this.info?.clientDeviceIdNew;
    },
    deviceName() {
      return this.info?.clientDevice;
    },
    loginAddress() {
      return this.info?.loginAddress;
    },
    lastLoggedInAt() {
      if (!this.info?.lastLoggedInAt) return;

      const now = moment().utc();
      const last = moment(this.info?.lastLoggedInAt);
      const diffHours = moment.duration(now.diff(last)).asHours();

      if (diffHours < 1) {
        const diffMinutes = now.diff(last, 'minutes');
        return +diffMinutes === 0 ? this.$t('_.now') : this.$t('_.minutesAgo', { time: diffMinutes });
      }
      else if (diffHours < 24) return this.$t('_.hoursAgo', { time: now.diff(last, 'hours') });
      else if (now.year() === last.year()) return last.format('MM/DD');
      else return last.format('YYYY/MM/DD');
    },
    brandInfo() {
      return this.info?.appNames.replace(/\//g, ', ');
    },
    iconName() {
      if (this.deviceType === 'MOBILE') return 'mobile-regular';
      else if (this.deviceType === 'PC') return 'display';
      else return 'device-group';
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[my-device-item] {.p(20); .bgc(white); .br(16); .c(black); .gap(8);
  .remove-holder {.flex(); .space-between(); .items-center();
    > p {.wh(40); .br(50%); .bgc(#F7F7F8); .tc();
      [font-icon] {.lh(40); .fs(18); .c(#71717A);}
    }
    [my-info-button] {.w(80); .min-h(28) !important; .h(28); .br(100); .fs(12); .regular(); .flex(); .justify-center(); .items-center();
      > span {.pv(0) !important; .ph(0); .lh(16);}
    }
  }
  .current-device {.flex(); .items-center(); .c(#7082FA); .fs(12); .gap(4); .mb(12);
    &::before {.cnt(); .ib(); .wh(6); .br(50%); .bgc(#7082FA);}
  }
  .device-name {.mt(8); .mb(4); .fs(14); .regular();}
  .info-text {.fs(12); .regular(); .c(#737373);}
  .brand-info {.mt(4); .fs(12); .regular(); .ellipsis();}
}
</style>
