import { siteIds } from '@/constants/base/siteMap.mjs';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import PhEmploymentTypeDropdown from '@/views/components/pages/my-page/template/extra/PhEmploymentTypeDropdown.vue';
import PhOccupationDropDown from '@/views/components/pages/my-page/template/extra/PhOccupationDropDown.vue';
import WsopOccupationDropDown from '@/views/components/pages/my-page/template/extra/WsopOccupationDropDown.vue';
import UkEmploymentTypeDropdown from '@/views/components/pages/my-page/template/extra/UkEmploymentTypeDropdown.vue';
import CountryDropDown from '@/views/components/pages/my-page/template/extra/CountryDropDown.vue';
import NationalityDropDown from '@/views/components/pages/my-page/template/extra/NationalityDropDown.vue';
import BusinessPhoneNumberInput from '@/views/components/pages/my-page/template/extra/BusinessPhoneNumberInput.vue';
import PhSourceOfFunds from '@/views/components/pages/my-page/template/extra/PhSourceOfFunds.vue';
import StateComponent from '@/views/components/pages/my-page/template/extra/StateComponent.vue';

export const personalInfoSiteKeyList = {
  [siteIds.GGPUKE]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.EVPUKE]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.SEVENXL]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.NATURAL8]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPOK]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPBE]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPBR]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPCW]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPDK]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPEU]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPFI]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPHU]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPJP]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPNG]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPPL]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPRO]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPUA]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.DAVAOPOKER]: ['Address', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPNL]: ['Address', 'City', 'PostalCode', 'Country', 'Nationality'],
  [siteIds.N8IN]: ['Address', 'State', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPCOM]: ['Address', 'State', 'City', 'PostalCode', 'Country'],
  [siteIds.GGPCZ]: ['Street', 'StreetNumber', 'State', 'City', 'PostalCode', 'Country', 'Nationality'],
  [siteIds.GGPDE]: ['PlaceOfBirth', 'Street', 'HouseNumber', 'AdditionalAddress', 'State', 'City', 'PostalCode', 'Country', 'Nationality'],
  [siteIds.GGPPH]: ['Address', 'State', 'City', 'PostalCode', "SourceOfFunds", "EmploymentType", "OccupationType", 'Country', 'Nationality'],
  [siteIds.GGPUK]: ['Building', 'Street', 'City', 'PostalCode', 'EmploymentType', 'Country'],
  [siteIds.WSOPON]: ['MiddleName', 'Alias', 'State', 'Address', 'City', 'PostalCode', 'OccupationType', 'EmployerName', 'EmployerAddress', 'BusinessPhoneNumber', 'Country', 'Nationality'],
  [siteIds.OCNP]: ['Address', 'City', 'Street', 'PostalCode', 'Country'],

  // TEST ALL Property ->
  // [siteIds.GGPCOM]: ['Address', 'State', 'City', 'PostalCode', 'Country', 'BuildingAddress', 'Street', 'City', 'PostalCode', 'EmploymentType', 'MiddleName', 'Alias', 'State','City', 'PostalCode', 'Occupation', 'EmployerName', 'EmployerAddress', 'BusinessPhoneNumber', 'Country', 'Nationality', 'PlaceOfBirth', 'Street', 'HouseNumber', 'AdditionalAddress', 'State', 'City', 'PostalCode', 'Country', 'Nationality'],
};


export const personalInfoProperties = (siteId) => ({
  Address: { key: 'Address', name: '_address', label: 'address', type: 'text', component:TextInputBox },
  PostalCode: { key: 'PostalCode', name: '_postalCode', label: 'postalCode', type: 'text', component:TextInputBox, preset: postalCodePreset(siteId) },
  Street: { key: 'StreetName', name: '_street', label: 'street', type: 'text',  component:TextInputBox },

  StreetNumber: { key: 'StreetNumber', name: '_streetNumber', label: 'streetNumber', type: 'text', component:TextInputBox, },
  MaidenName: { key: 'MaidenName', name: '_lastNameAtBirth', label: 'lastNameAtBirth', type: 'text', component:TextInputBox, },

  PlaceOfBirth: { key: 'PlaceOfBirth', name: '_placeOfBirth', label: 'placeOfBirth', type: 'text', component:TextInputBox, },
  HouseNumber: { key: 'HouseNumber', name: '_houseNumber', label: 'houseNumber', type: 'text', component:TextInputBox, },
  AdditionalAddress: { key: 'AdditionalAddress', name: '_additionalAddress', label: 'additionalAddress', type: 'text', component:TextInputBox, },
  BuildingAddress: { key: 'BuildingAddress', name: '_buildingAddress', label: 'buildingAddress', type: 'text', component:TextInputBox, },
  Building: { key: 'Building', name: '_buildingAddress', label: 'buildingAddress', type: 'text', component:TextInputBox, },

  MiddleName: { key: 'MiddleName', name: '_middleName', label: 'middleName', type: 'text', component:TextInputBox, },
  Alias: { key: 'Alias', name: '_aliasPreferredName', label: 'aliasPreferredName', type: 'text', component:TextInputBox, },

  EmployerName: { key: 'EmployerName', name: '_employerName', label: 'employerName', type: 'text', component:TextInputBox, },
  EmployerAddress: { key: 'EmployerAddress', name: '_employerAddress', label: 'employerAddress', type: 'text', component:TextInputBox, },
  EmploymentType: { key: 'EmploymentType', name: '_employmentStatus', label: 'employmentStatus', type: 'custom', component: employmentTypeComponent(siteId) },

  BusinessPhoneNumber: { key: 'BusinessPhoneNumber', name: '_businessPhoneNumber', label: 'businessPhoneNumber', type: 'custom', component: BusinessPhoneNumberInput, preset: { maxLength: 14, telephone: true } },
  State: { key: 'State', name: '_stateProvince', label: 'stateProvince', type: 'custom', component: StateComponent, },
  City: { key: 'City', name: '_city', label: 'city', type: 'custom', component: TextInputBox, },
  Country: {
    key: 'Country', name: '_country', label: 'country', type: 'custom', noWrapper: true, component: CountryDropDown,
  },
  Nationality: {
    key: 'Nationality', name: '_nationality', label: 'nationality', type: 'custom', noWrapper: true, component: NationalityDropDown,
  },
  OccupationType: {
    key: 'OccupationType', name: '_occupation', label: siteId === siteIds.GGPPH ?'occupationEmployed' : 'occupation', type: 'custom', component: occupationTypeComponent(siteId),
  },
  SourceOfFunds: { key: 'SourceOfFunds', name: '_sourceOfFunds', label: 'sourceOfFunds', type: 'custom', component: PhSourceOfFunds, },
});

const postalCodePreset = (siteId)=>{
  if([siteIds.GGPPH, siteIds.GGPCZ].includes(siteId)) return { digit: true, inputMode:'numeric', maxLength:4 };
  else return {};
}

const employmentTypeComponent = siteId => {
  if(siteId === siteIds.GGPPH) return PhEmploymentTypeDropdown;
  else return UkEmploymentTypeDropdown;
}

const occupationTypeComponent = siteId => {
  if(siteId === siteIds.GGPPH) return PhOccupationDropDown;
  else return WsopOccupationDropDown;
};