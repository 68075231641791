<template>
  <div address-ph v-if="modelInfo && addressRules">
    <div class="fields-holder">
      <ValidationComponent :label="$t('_.address')" name="_address" :component="TrimTextInput" :rules="addressRules" :preset="addressPreset" v-model="modelInfo.Address" :error-msg="addressErrorMsg" />
      <ValidationComponent :label="$t('_.street')" name="_street" :component="TrimTextInput" :rules="streetNameRules" :preset="streetNamePreset" v-model="modelInfo.StreetName" :error-msg="streetErrorMsg" />
      <ValidationComponent :label="$t('_.barangay')" name="_barangay" :component="TrimTextInput" :rules="districtRules" :preset="districtPreset" v-model="modelInfo.District" :error-msg="districtErrorMsg" />
      <ValidationComponent :label="$t('_.stateProvince')" name="_stateProvince" :component="TrimTextInput" :rules="stateRules" :preset="statePreset" v-model="modelInfo.State" :error-msg="stateErrorMsg" />
      <ValidationComponent :label="$t('_.city')" name="_city" :component="TrimTextInput" :rules="cityRules" :preset="cityPreset" v-model="modelInfo.City" :error-msg="cityErrorMsg" />
      <ValidationComponent :label="$t('_.postalCode')" name="_postalCode" :component="TrimTextInput" :rules="postalCodeRules" :preset="postalCodePreset" v-model="modelInfo.PostalCode" :error-msg="cityErrorMsg" />
    </div>
    <ProgressButton :button-name="buttonName" @click="confirm" :disabled="!!structure.limitTime || structure.error || invalid" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import baseTemplate from '@/mixins/baseTemplate';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import Specific from '@shared/types/Specific';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default {
  name: 'AddressPh',
  lexicon: 'personalDetails',
  mixins: [baseTemplate],
  components: { ProgressButton, ValidationComponent },
  props: {
    invalid: { type: Specific, default: null },
  },
  data() {
    return {
      addressPreset: Specific,
      streetNamePreset: Specific,
      districtPreset: Specific,
      statePreset: Specific,
      cityPreset: Specific,
      postalCodePreset: Specific,
      addressRules: Specific,
      streetNameRules: Specific,
      districtRules: Specific,
      stateRules: Specific,
      cityRules: Specific,
      postalCodeRules: Specific,
      districtErrorMsg: null,
      streetErrorMsg: null,
      houseNumberErrorMsg: null,
      addressErrorMsg: null,
      stateErrorMsg: null,
      buildingErrorMsg: null,
      cityErrorMsg: null,
      postalCodeErrorMsg: null,
      TrimTextInput,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
    step() {
      return this.structure.step;
    },
    lastStep() {
      return this.step >= this.structure.list.length - 1;
    },
    currentItem() {
      return this.structure?.list[this.step];
    },
    buttonName() {
      return this.currentItem.buttonName || (this.lastStep ? 'submit' : 'next');
    },
    address() {
      return this.modelInfo.Address;
    }
  },
  watch: {
    address() {
      if (this.modelInfo?.ExtraAddresses[0]?.Address?.length && !this.modelInfo.Address?.length) this.modelInfo.ExtraAddresses[0].Address = '';
    }
  },
  methods: {
    /**
     * 결과 노출 시 초기 설정
     * @returns {Promise<void>}
     */
    initialize() {
      this.initRules();
      this.initPreset();
      this.initErrorMsg();
      // if(this.modelInfo?.ExtraAddresses[0]?.Address && !this.modelInfo.Address) this.modelInfo.Address = this.modelInfo?.ExtraAddresses[0]?.Address;
    },
    /**
     * ModelInfo 유무에 따른 Rules 설정
     */
    initRules() {
      this.addressRules = `address:0,120,${this.modelInfo.CountryWritingSystem}`;
      this.streetNameRules = `requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`;
      this.districtRules = `requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`;
      this.stateRules = `requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`;
      this.cityRules = `requiredAddress:0,50,${this.modelInfo.CountryWritingSystem}`;
      this.postalCodeRules = `requiredNumber:4,4`;
    },
    /**
     * ModelInfo 유무에 따른 Preset 설정
     */
    initPreset() {
      this.addressPreset = { maxLength: 120 };
      this.streetNamePreset = { maxLength: 50 };
      this.districtPreset = { maxLength: 50 };
      this.statePreset = { maxLength: 50 };
      this.cityPreset = { maxLength: 50 };
      this.postalCodePreset = { digit: true, maxLength: 4 };
    },
    /**
     * 에러 메세지 초기화
     */
    initErrorMsg() {
      this.districtErrorMsg = null;
      this.streetErrorMsg = null;
      this.houseNumberErrorMsg = null;
      this.addressErrorMsg = null;
      this.stateErrorMsg = null;
      this.buildingErrorMsg = null;
      this.cityErrorMsg = null;
      this.postalCodeErrorMsg = null;
    },
    /**
     * 입력 정보 저장
     * @returns {Promise<void>}
     */
    async confirm() {
      await this.apiRequest(this.currentItem?.api, this.structure.model);
      if (!this.invalid && !this.structure.error) this.modelInfo.step = 1;
    },

    /**
     * 컴포넌트 내에서의 api request 처리
     * @param {Specific} params - 호출 파라미터
     * @param {string} api - api 함수 명
     * @param {object} params - params
     * @param {object} config - config
     * @returns {Promise<*|null>}
     */
    async apiRequest(api, params, config = null) {
      const r = await this.getApiResponse(api, params, config);
      if (r?.error) {
        if (!(this.$onboarding.addressErrorHandler(r))) return;

        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, { csEmail: this.csEmail });
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : onboarding.${api}`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }

        return;
      }

      return r;
    },
    /**
     * api 결과 반환 처리
     * @param {string} api - api 호출 함수 명
     * @param {object} params - 호출 함수 전달 정보
     * @param {object} config - 설정
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse(api, params, config = null) {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services.onboarding[api]({ ...params }, { ...config }); } catch (e) { return e; }
    },
  },
  mounted() {
    if (this.item) this.item.title = 'onBoarding.title.presentAddress';
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[address-ph] { .rel();
  > div {
    > * {
      &:not(:nth-of-type(1)) { .mt(8); }
    }
  }
}
</style>
