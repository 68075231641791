<template>
  <MfaModalTemplate backup-qr-code>
    <template #title>{{ $t('_.googleAuthenticationStep3') }}</template>
    <template #content>
      <div class="img-group">
        <div class="img-area">
          <FontIcon class="cloud-exchange" name="cloud-exchange" />
        </div>
      </div>
      <CopyButton :copy-text="googleInfo.ManualEntryKey" />
    </template>
    <template #description>
      {{ $t('_.googleStep3Desc') }}
    </template>
    <!--    <template #footer>-->
    <!--      <GpButton type="line-green" @click="prev">{{ $t('_.prev') }}</GpButton>-->
    <!--      <GpButton type="submit-green" @click="next">{{ $t('_.next') }}</GpButton>-->
    <!--    </template>-->
  </MfaModalTemplate>
</template>

<script>
import MfaModalTemplate from '@/views/components/pages/my-page/modal/components/MfaModalTemplate.vue';
import Specific from '@shared/types/Specific';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import CopyButton from '@/views/components/common/CopyButton.vue';
export default {
  name: 'BackupQRCode',
  lexicon: 'myInfo',
  components: { CopyButton, GpButton, MfaModalTemplate, FontIcon },
  props: {
    options: Specific,
    googleInfo: Specific,
  },
  methods: {
    prev() {
      this.$emit('prev');
    },
    next() {
      this.$emit('next');
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[backup-qr-code] { .flex(); .justify-center(); .flex-dc(); .gap(10);
  .img-group { .flex(); .flex-dr(); .justify-center(); .gap(20);
    .img-area { .wh(160); .bgc(#fff); .flex(); .flex-dc(); .justify-center(); .items-center(); .br(8);
      > p {
        &:nth-of-type(2) {.c(black)}
      }
    }
  }
  .cloud-exchange {.fs(55);}
  .head-message {.fs(14); .c(black);}
  .footer-message {.fs(12); line-height: 16px; text-align: center;}

  @media(@ml-up){
    .img-group{
      .img-area{.wh(186);}
    }
  }
}
</style>